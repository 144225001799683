export const isValidFirstName = (value) => {
    let firstNameInput = document.getElementById('firstName');

    if (!(value && value.trim())) {
        firstNameInput?.classList.remove('goodBorder');
        return '*الرجاء إدخال الاسم الأول';
    }

    if (value.length < 1 || value.length > 50) {
        firstNameInput?.classList.remove('goodBorder');
        return '*يجب أن يكون الاسم الأول بين 1 و 50 حرفًا';
    }

    let nameRegex = /^[\u0621-\u064A\u0660-\u0669A-Za-z\-'\s]+$/;

    if (!nameRegex.test(value)) {
        firstNameInput?.classList.remove('goodBorder');
        return "*مسموح بكتابة أحرف عربية أو إنجليزية فقط";
    }

    firstNameInput?.classList.add('goodBorder');
    return true;
}
