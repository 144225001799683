<template>
  <div class="mt-4 pb-32">
    <div v-for="(content, index) in data" :key="index">
      <div v-if="type === 'course'">
        <div v-if="shouldRenderHeading(index)" class="unit-title dynamic-align font-bold  mt-[40px] mb-6 text-black-custom text-sm md:text-19px">
        {{ content.name }}
      </div>
      <CourseContent v-else-if="shouldRenderCourse(index)" :course="content" :dir="dir"/>
      </div> 
      
      <div v-if="type === 'lesson'"> 
        <LessonContent :lesson="content" :dir="dir" :ContentType="ContentType" :isEnrolled="isEnrolled"/>
      </div>


    </div>
  </div>
</template>

<script>
import CourseContent from "@/components/Course/CourseContent.vue";
import LessonContent from "@/components/Lesson/LessonContent.vue";

export default {
  name: "ContentRenderer",
  components: {
    CourseContent,
    LessonContent
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    dir: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    ContentType: {
      type: String,
      required: false,
    },
    isEnrolled: {
      type : Boolean,
      required: false
    }
  },
  methods: {
    getCurrentItemType(index) {
      return this.data[index].content_type;
    },
    getNextItem(index) {
      if (index + 1 < this.data.length) {
        return this.data[index + 1];
      }
      return null;
    },
    shouldRenderHeading(index) {
      const nextItem = this.getNextItem(index);
      const currentItemType = this.getCurrentItemType(index);
      const isCurrentItemCourseHeading = currentItemType === 'CourseHeading';

      return (
          isCurrentItemCourseHeading &&
        nextItem &&
        nextItem.content_type === "Lesson"
        || isCurrentItemCourseHeading
      );
    },
    shouldRenderCourse(index) {
      return this.getCurrentItemType(index) === "Lesson" 
    },
  },
};
</script>

<style scoped>

</style>