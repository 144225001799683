import { loginCookie } from '@/helpers/loginCookie';
import VueCookies from 'vue-cookies';

const state = {
    token: loginCookie.getAuthToken(VueCookies) || null,
    isCompletedProfile: loginCookie.getIsCompletedProfile(VueCookies) || false
}

const getters = {
    isAuth: state => !!state.token,
    getIsCompletedProfile: state => state.isCompletedProfile
}

const actions = {
    async loginUser({ commit }, token) {
        commit('SET_TOKEN', token);
        loginCookie.setAuthToken(VueCookies, token);
    },
    async registerUser({ commit }, token) {
        commit('SET_TOKEN', token);
        loginCookie.setAuthToken(VueCookies, token);
    },

    async logoutUser({ commit }) {
        loginCookie.removeAuthToken(VueCookies);
        loginCookie.removeIsCompletedProfile(VueCookies);
        commit('LOGOUT_USER');
        commit('SET_IS_COMPLETED_PROFILE', undefined);
    }, 
    async updateIsCompletedProfile({ commit }, isCompletedProfile) {
        const isCompletedProfileString = String(isCompletedProfile);
        loginCookie.setIsCompletedProfile(VueCookies, isCompletedProfileString);
        commit('SET_IS_COMPLETED_PROFILE', isCompletedProfileString);
    },

    async removeIsCompletedProfile({ commit }) {
        loginCookie.removeIsCompletedProfile(VueCookies);
        commit('SET_IS_COMPLETED_PROFILE', false);
    }

}

const mutations = {
    SET_TOKEN(state, token) {
        state.token = token;
    },
    LOGOUT_USER(state) {
        state.token = null;
    },
    SET_IS_COMPLETED_PROFILE(state, isCompletedProfile) {
        state.isCompletedProfile = isCompletedProfile;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}