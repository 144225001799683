import { createApp, h, provide } from "vue";
import App from "./App.vue";
import router from "./router";
import VueCookies from "vue-cookies";
import * as VeeValidate from "vee-validate";
import VueCryptojs from "vue-cryptojs";
import { DefaultApolloClient } from "@vue/apollo-composable";
import globalMethods from "@/plugins/globalMethods";
import apolloClient from "./plugins/apollo";
import store from "./store";
import { VueSvgIconPlugin } from "@yzfe/vue-svgicon";
import LoadingSpinner from '@/components/General/LoadingSpinner.vue';

import "./assets/tailwind.css";
import "@/assets/svg-es-custom.css";


const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});
app.component('LoadingSpinner', LoadingSpinner);
app
  .use(router)
  .use(globalMethods)
  .use(store)
  .use(VueCryptojs)
  .use(VeeValidate)
  .use(VueCookies, { expires: "30d" })
  .use(VueSvgIconPlugin, {
    tagName: "svgicon",
  })
  .mount("#app");
