import { useQuery } from '@vue/apollo-composable';
import {GET_ALL_COURSE_CLASSES, GET_RELIGION} from '@/graphql/queries/courseClasses/courseClasses';
import { GET_SECOND_LANGUAGES  } from '@/graphql/queries/courseClasses/courseClasses';
import {computed} from "vue";


export const getSecondLanguages = (async () => {
    
    const { result } = useQuery(GET_SECOND_LANGUAGES)
    try {
        return result;
    } catch (error) {
        return error
    }

})

export const getReligion = (async () => {
    
    const { result } = useQuery(GET_RELIGION)

    try {
        return result;
    } catch (error) {
        return error
    }

})

export const getAllCourseCLasses =  () => {

    const { result, loading, error } = useQuery(GET_ALL_COURSE_CLASSES , null ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const courseClasses = computed(() => result.value?.courseClasses ?? []);

    const secondaryLanguageClasses = computed(() =>
        courseClasses.value.filter((courseClass) => courseClass.is_secondary_language)
    );

    const religionClasses = computed(() =>
        courseClasses.value.filter((courseClass) => courseClass.is_religion_class)
    );


    return {
        courseClasses,
        secondaryLanguageClasses,
        religionClasses,
        loading,
        error
    }

};
