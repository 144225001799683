<template>
  <div class="w-full">
    <div class="flex flex-column justify-center mb-4">
      <div :class="containerFieldClass">
        <Field
            as="select"
            :id="id"
            :name="name"
            :class="selectClass"
            :rules="rules"
            :modelValue="modelValue"
            @update:modelValue="$emit('update:modelValue', $event)"
            @change="$emit('optionSelected')"
            :validateOnBlur="validateOnBlur"
            :validateOnChange="validateOnChange"
            :validateOnInput="validateOnInput"
            :validateOnModelUpdate="validateOnModelUpdate"
        >
          <option v-if="allowEmpty"  value="0">{{ placeholder }}</option>
          <option v-else value="0" disabled>{{ placeholder }}</option>
          <option v-for="option in options" :key="option.id" :value="option.id">
            {{ option.name }}
          </option>
        </Field>
      </div>
      <ErrorMessage :class="errorClass" :name="name" />
    </div>
  </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';

export default {
  name: 'SelectField',
  components: { Field, ErrorMessage },
  props: {
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    rules: {
      type: [String, Object, Function],
      default: ''
    },
    placeholder: {
      type: String,
      required: true
    },
    selectClass: {
      type: String,
      default: 'selector p-2 pr-[17px] md:pr-[29px] md:indent-4 indent-2 select-box decorated mb-[25px] w-[284px] md:w-[375px] h-[54px] m-auto rounded-[10px]'
    },
    errorClass: {
      type: String,
      default: 'text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1'
    },
    containerFieldClass: {
      type: String,
      default: 'w-[284px] relative md:w-[500px] h-[54px] m-auto rounded-[10px] flex justify-center items-center'
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    validateOnBlur: {
      type: Boolean,
      default: true
    },
    validateOnChange: {
      type: Boolean,
      default: true
    },
    validateOnInput: {
      type: Boolean,
      default: false
    },
    validateOnModelUpdate: {
      type: Boolean,
      default: true
    },
  },
};
</script>