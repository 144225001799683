<template>
  <div class="relative w-full min-h-screen">
  <div class="flex flex-col items-center absolute w-full h-full">
    <LoadingSpinner :is-loading="isLoading"/>
    <BackButton id="backButton" class="absolute top-[11.1%] left-[87.5%] md:absolute md:top-[16.28%] md:left-[72.71%] z-[1000]"/>
    <p class="text-[17px] md:text-[30px] font-bold absolute top-[16.875%] md:top-[29.95%] left-auto">ادخل رقم تليفون جديد</p>
      
    <Form class="w-full flex flex-col justify-start items-center flex-wrap h-[396px] absolute top-[26.5%] md:top-[38.41%] left-auto" dir="rtl" @submit="onSubmit" action="">              
        <PhoneNumberInput
                v-model="phoneNumber"
                placeholder="رقم تليفون جديد"
                :rules="isValidPhone"
                :containerFieldClass="'relative w-[324px] md:max-w-[375px] m-auto rounded-[10px] flex justify-center items-center'"
                :styleClass="'p-2 pr-[17px] md:pr-[29px] inputBorderStyle w-[100%] h-[54px] rounded-[10px]'"
                :errorClass="'text-[#C50000] w-[284px] md:w-[375px] text-right m-auto'"
                >
                <template v-slot:icon>
                  <svgicon :data="smartPhoneIcon" width="24" height="24" original class="absolute left-[14px] md:left-[14px]"/>
                </template>
        </PhoneNumberInput>
      
        <span class="text-gray-700  ">هنبعتلك كود تأكيد على الرقم</span>
        <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
          <span v-for="(error, index) in errors" :key="index">
            {{ error }}
          </span>
        </div>
        <BlueButton type="submit" :customClass="'w-[284px] absolute top-[calc(100%-56px)] left-auto h-[54px] rounded-[10px] bg-blue-custom text-white font-bold '">تأكيد الكود</BlueButton>
    </form>
</div>
</div>
</template>

<script>
import BackButton from '@/components/FormComponents/BackButton.vue';
import {Form} from 'vee-validate';
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import PhoneNumberInput from "@/components/FormComponents/PhoneNumberInput.vue";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import {requestOTP} from '@/graphql/mutations/requestOTP/handlingCalls';
import {otpCookie} from "@/helpers/OTPCookie";
import routes from "@/router/routes";
import {isValidPhone} from "@/validations/isValidPhone";
import cookieEncryption from "@/helpers/cookieEncryption";
import smartPhoneIcon from '@svgicon/smartphone.svg';
import {phoneNumberArabicToEnglish} from "@/helpers/phoneNumberFunctions";

export default {
  name: 'ChangePhoneInitial',
  components: {
    LoadingSpinner,
    PhoneNumberInput,
    BlueButton,
    Form,
    BackButton,
  },
  data() {
    return {
      phoneNumber: '',
      errors: [],
      isLoading: false,
      smartPhoneIcon: smartPhoneIcon,
    }
  },
  methods: {
    isValidPhone,
    phoneNumberArabicToEnglish,
    async onSubmit() {
      this.errors = []
      const otpResponse =  await this.$withLoading(() => requestOTP(phoneNumberArabicToEnglish(this.phoneNumber)));

      if (otpResponse.success === false) {
        this.errors = otpResponse.displayValidationMessages;

      } else {
        otpCookie.setOTP(this.$cookies , phoneNumberArabicToEnglish(this.phoneNumber) , {
          'isValidSentOTP' : false,
          'sentOTP': otpResponse.data.otp
        });

        const userData = {
          studentPhone: phoneNumberArabicToEnglish(this.phoneNumber),
        };

        cookieEncryption.setCookie(this.$cookies , "elkhetaChangePhoneNumber", userData, 1);
        routes.navigateTo(routes.EDIT_MY_PHONE_NUMBER_VERIFICATION.key);
      }
    },
  },

}

</script>
