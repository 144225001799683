<template>
  <div :class="fontClass" class="bg-[#f4f6f8]">
    <LoadingSpinner :is-loading="isLoadingPaperSummary" />
    <PaperSummaryContent v-if="!paperSummaryError" :paperSummary="paperSummary" :paperSummaryError="paperSummaryError" />
    <Page404Content v-else-if="paperSummaryError && isContentNotFoundError"/>
    <NotEnrolledData v-else-if="paperSummaryError && isContentNotEnrolledError" :goTo="handleNotEnrolledNavigation"/>
  </div>
</template>

<script>

import {getPaperSummaryContent} from "@/graphql/queries/paperSummary/handlingCalls";
import routeParamsMixin from '@/mixins/routeParams';
import windowSizeMixin from '@/mixins/windowSize';
import contentDataMixin from '@/mixins/contentData';
import {handleErrors} from '@/helpers/graphqlQueryErrorResponseHandler';
import PaperSummaryContent from "@/components/PaperSummary/PaperSummaryContent.vue";
import Page404Content from "@/components/Page404/Page404Content.vue";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import NotEnrolledData from "@/components/Lesson/NotEnrolledData.vue";
import { getFontClass } from '@/helpers/getFontClassHelper';


export default {
  mixins: [routeParamsMixin,contentDataMixin ,  windowSizeMixin],
  name: "PaperSummaryPage",
  components: {
    NotEnrolledData,
    LoadingSpinner,
    Page404Content,
    PaperSummaryContent

  },

  props: {
    contentDataProp: {
      type: String,
      default: 'paperSummary'
    },
    contentErrorDataProp: {
      type: String,
      default: 'paperSummaryError'
    },
  },
  data() {
    return {
      paperSummary: {},
      paperSummaryError: null,
      isLoadingPaperSummary: true,
    }
  },
  computed: {
    fontClass() {
      return getFontClass(this.direction);
    },
  },
  methods: {
    getFontClass,
    fetchPaperSummaryContent() {
      const {paperSummaryContent, loading, error} = getPaperSummaryContent(this.paperSummarySlug);

      this.$watch(
          () => error.value,
          (newVal) => {
            this.paperSummaryError = handleErrors(newVal?.graphQLErrors || []);
          },
          {immediate: true}
      );

      this.$watch(
          () => paperSummaryContent.value,
          (newVal) => {
            this.paperSummary = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.isLoadingPaperSummary = newVal;
          },
          {immediate: true}
      );
    },

    onLoad() {
      this.fetchPaperSummaryContent();
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.getRouteParamsData(to.params));
    this.onLoad();
    next();
  },

}
</script>
