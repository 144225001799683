<template>

  <div
      class="flex flex-col bg-white-custom md:py-4 rounded-lg md:rounded-xl mt-6 item-center w-10/12 md:w-7/12 mx-auto md:text-17px">
    <div class="flex flex-row justify-between text-center w-full py-2 gap-10 px-10">
      <div class="flex flex-row gap-2 items-center flex-grow">
        <svgicon :data="Pen" width="21" height="21" original/>
        <div class="text-blue-custom">{{ resultGradeText }}</div>
      </div>
      <div class="items-center justify-center text-base leading-7 text-black-custom md:text-19px">
        {{ grade }}%
      </div>
    </div>
    <hr>
    <div class="flex flex-row justify-between text-center w-full py-2 rounded-xl gap-10 px-10">
      <div class="flex flex-row gap-2 items-center flex-grow">
        <ClockIcon :width="22" :height="22" :isBlue="true"/>
        <div class="text-blue-custom">{{ resultTimeText }}</div>
      </div>
      <div class="flex items-center justify-center text-base leading-7 text-black-custom  md:text-19px">
        {{ time }}
      </div>
    </div>
  </div>

</template>

<script>
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import Layer from "@/assets/svg/blue/Layer.svg";
import Pen from "@/assets/svg/blue/pen.svg";

export default {

  name: 'ResultInfo',
  props: {
    grade: {
      type: [String , Number],
      required: true
    },
    time: {
      type: [String , Number],
      required: true
    },
    resultGradeText: {
      type: String ,
      required: true
    },
    resultTimeText: {
      type: String ,
      required: true
    },
  },
  data() {
    return {
      Layer,
      Pen
    }
  },
  components: {
    ClockIcon

  },
};
</script>