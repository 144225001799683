<template>
  <p class="start-and-log" id="startAndLog">
    ســـــــــــــــــــــجل <br /> و ابـــــــــــــــــــــــــدأ
  </p>
</template>

<script>
export default {
  name: 'StartAndLog'
};
</script>

<style scoped>
.start-and-log {
  @apply text-[50px] text-black-custom font-bold top-[17.75%] md:top-[20.87%] absolute m-auto;
}

.blurred {
  @apply blur-[5px] transition duration-300 ease-in-out
}
</style>
