export default {
    data() {
        return this.getRouteParamsData();
    },
    methods: {
        getRouteParamsData(params = this.$route.params) {
            const dataProps = {};

            for (const key in params) {
                if (Object.prototype.hasOwnProperty.call(params, key)) {
                    dataProps[key] = params[key];
                }
            }

            return dataProps;
        },
    },
};