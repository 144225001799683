<template>
  <div class="relative">
    <div @click="goBack()" v-if="fawryData || vodafoneCashData"
         class="absolute right-[9%] top-[10%] md:top-[6%] w-[20px] h-[20px] md:w-[37px] md:h-[37px] cursor-pointer flex justify-center items-center  rounded-[10px]">
      <svgicon :data="backButtonIcon" class="bg-opacity-40" width="30" height="30" original/>
    </div>

    <LoadingSpinner :is-loading="cartLoading" />

    <div class="flex flex-col w-[100%] min-h-screen mt-[0%] md:mt-[-0.5%]">
      <AddToCartFawry v-if="fawryData" :cartData="cart" :fawryData="fawryData"/>
      <AddToCartVodafoneCash v-if="vodafoneCashData" :cartData="cart" :vodafoneCashData="vodafoneCashData"/>
    </div>

  </div>

</template>

<script>
import AddToCartFawry from '@/components/Subscriptions/AddToCartFawry.vue';
import AddToCartVodafoneCash from '@/components/Subscriptions/AddToCartVodafoneCash.vue';
import backButtonIcon from '@svgicon/back-button.svg';
import {getCart} from "@/graphql/queries/cart/handlingCalls";


export default {
  name: "AddToCartPage",
  props: {
    cartData: {
      type: Object
    }
  },
  components: {
    AddToCartFawry,
    AddToCartVodafoneCash,
  },
  data() {
    return {
      cart: {},
      vodafoneCashData: null,
      fawryData: null,
      cartLoading: false,
      backButtonIcon: backButtonIcon,
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchCart() {
      const {cart: cartResponse, fawryData: fawryResponse, vodafoneCashData: vodafoneCashResponse, loading} = getCart();

      this.$watch(
          () => cartResponse.value,
          (newVal) => {
            this.cart = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => fawryResponse.value,
          (newVal) => {

            this.fawryData = newVal;
          },
          {immediate: true}
      );


      this.$watch(
          () => vodafoneCashResponse.value,
          (newVal) => {

            this.vodafoneCashData = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.cartLoading = newVal;
          },
          {immediate: true}
      );
    },
    onLoad() {
      this.fetchCart();

    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.onLoad();
    next();
  },
}

</script>