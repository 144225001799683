<template>
    <svgicon
      :data="currentIcon"
      :width="width"
      :height="height"
      original
    />
  </template>
  
  <script>
  import BlueIcon from "@/assets/svg/blue/Time Square.svg";
  import GrayIcon from "@/assets/svg/clock-gray.svg";
  import BlackIcon from "@/assets/svg/black-clock.svg";
  
  export default {
    props: {
      isBlue: {
        type: Boolean,
        default: false,
      },
      isBlack: {
        type: Boolean,
        default: false,
      },
      width: {
        type: Number,
        default: 15
      },
      height: {
        type: Number,
        default: 15
      },
    },
    computed: {
      currentIcon() {
        if (this.isBlue) {
          return BlueIcon;
        } else if (this.isBlack) {
          return BlackIcon;
        } else {
          return GrayIcon;
        }
      }
    }
  }
  </script>
  