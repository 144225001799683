export const  isValidEducationType = (value) => {
    let educationTypeInput = document.getElementById('educationType');

    if (!(value && value.trim()) || value === '0' || value === 0) {
        educationTypeInput?.classList.remove('goodBorder');

        return '*الرجاء اختيار نوع التعليم.';
    }

    if (educationTypeInput){
        educationTypeInput.classList.add('goodBorder');
    }
    return true;
}