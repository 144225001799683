import { useMutation } from '@vue/apollo-composable';
import { handleResponse } from "@/helpers/graphqlResponseHandler";
import { DELETE_USER } from "@/graphql/mutations/deleteUser/deleteUser";


export const deleteUser = (async () => {

    const { mutate } = useMutation(DELETE_USER, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            source: 'website',
        },
    });

    try {
        const response = await mutate();
        console.log('response', response);
        
        return handleResponse(response, 'deleteUserAccount');

    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }

})