export const isValidLastName = (value) => {
    let lastNameInput = document.getElementById('lastName');

    if (!(value && value.trim())) {
        lastNameInput?.classList.remove('goodBorder');

        return true;
    }

    if (value.length > 50) {
        lastNameInput?.classList.remove('goodBorder');
        return '*يجب أن يكون اسم العائلة بين 1 و 50 حرفًا';
    }

    let nameRegex = /^[\u0621-\u064A\u0660-\u0669A-Za-z\-'\s]+$/;

    if (!nameRegex.test(value)) {
        lastNameInput?.classList.remove('goodBorder');
        return "*مسموح بكتابة أحرف عربية أو إنجليزية فقط";
    }

    if (lastNameInput){
        lastNameInput.classList.add('goodBorder');
    }
    return true;
}