<template>
  <router-link :to="{ name: 'Login' }" class="login-link">
    <span class="login-text">عندي حساب بالفعل <a class="login-anchor">تسجيل الدخول</a></span>
  </router-link>
</template>

<script>
export default {
  name: 'LoginLink'
};
</script>

<style scoped>
.login-link {
  @apply text-gray-500;
}
.login-text {
  @apply text-gray-500 text-[14px];
}
.login-anchor {
  @apply text-black underline text-[14px];
}
</style>
