import { useMutation } from '@vue/apollo-composable';
import {handleResponse} from "@/helpers/graphqlResponseHandler";
import {UPDATE_USER_PASSWORD} from "@/graphql/mutations/updateUserPassword/updateUserPassword";


export const updateUserPassword = (async (userData) => {

    const { mutate } = useMutation(UPDATE_USER_PASSWORD, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            password: userData.password,
            password_confirmation: userData.password_confirmation,
        },
    });




    try {
        const response = await mutate();


        return handleResponse(response , 'updateUserPassword');



    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }

})