<template>

  <div class="min-h-screen md:min-h-screen flex flex-col justify-center items-center md:ml-0 w-full h-full">

    <div class="d-flex flex-col items-center content-center justify-center mt-4 flex-wrap w-full h-full" dir="rtl">

      <div class="w-[324px] md:w-[672px] hidden md:absolute top-[13.5%] left-auto md:top-[13.8%] md:mb-[161px] md:flex md:justify-center">
        <ProgressBar progress="100"/>
      </div>

      <div class="m-4 text-center flex justify-center absolute top-[19.125%] md:top-[32.25%] left-auto">
        <svgicon :data="registerDoneIcon" width="180" height="180" original />
      </div>
      

      <p class="congrats w-full md:w-auto md:m-4 absolute top-[50%] md:top-[58.03%] left-auto">مبروك تسجيلك <br> في المنصة الأولى <br> للتعليم في مصر  </p>
        
      <div class="w-1/2 h-full flex flex-col items-center content-center justify-center relative flex-wrap m-2" dir="rtl">

        <router-link :to="{ name: 'studyContent' }" class="w-full flex items-center justify-center">
          <button class="mt-2 btn w-[284px] h-[56px] rounded-[10px] flex justify-center items-center absolute top-[81.875%] md:top-[83.4%] left-auto" id="submitBtn" type="submit">الذهاب إلى صفحة المنهج</button>
        </router-link>

    </div>
  </div>
</div>
</template>

<script>


import ProgressBar from "@/components/FormComponents/ProgressBar.vue";
import registerDoneIcon from '@svgicon/register-done.svg';

export default {
  name: 'signUpDone',
  components: {ProgressBar  },
  data() {
        return {
          phoneNumber: '',
          password: '',
          password_confirmation: '',
          registerDoneIcon: registerDoneIcon

        }
      },
    methods:{
    }
}

</script>

<style scoped>
.progress{
  margin-bottom: 100px;
  position: relative;
  top: 20px
}
.progress-bar{
  background-color: #FFD700;
}
.congrats{
  color: #1C9DEB;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 30px;
}
.btn{
  background-color: #1C9DEB;
  color: white;
  font-weight: bold;
  margin-bottom:  25px;
  border-radius: 10px;
}
</style>