function transformValidationErrors(validationErrors) {
    const transformedErrors = [];
    const validationMessages = [];

    for (const field in validationErrors) {
        if (Object.prototype.hasOwnProperty.call(validationErrors, field)) {
            const transformedField = field.replace(/^input\./, '');
            const messages = validationErrors[field];

            transformedErrors.push({
                field: transformedField,
                messages: messages,
            });

            validationMessages.push(...messages);
        }
    }

    return {
        transformedErrors,
        validationMessages
    };
}

function handleErrors(errors) {
    if (!errors || errors.length === 0) {
        return null;
    }

    let errorObject;

    errors.forEach(error => {
        if (error.extensions && error.extensions.validation) {
            const validation = transformValidationErrors(error.extensions.validation);
            errorObject = {
                message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
                messageCode: error.message_code || "",
                messageData: error.message_data || "",
                validationErrors: validation.transformedErrors,
                validationMessages: validation.validationMessages,
                displayValidationMessages: validation.validationMessages?.length && validation.validationMessages?.length > 0 ? validation.validationMessages : [error.message]
            };
        } else {
            errorObject = {
                message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
                messageCode: error.message_code || "",
                messageData: error.message_data || "",
            };
        }
    });

    return errorObject;
}

export { handleErrors, transformValidationErrors };