
const state = {
    reference_number: '',
    submitted_phone_number: '',
    valid_until: '',
}

const getters = {
    getReferenceNumber: state => state.reference_number,
    getSubmittedPhoneNumber: state => state.submitted_phone_number,
    getValidUntil: state => state.valid_until,
}

const actions = {
    async updateReferenceNumber({ commit }, reference_number) {
        commit('SET_REFERENCE_NUMBER', reference_number);
    },
    async updateSubmittedPhoneNumber({ commit }, submitted_phone_number) {
        commit('SET_SUBMITTED_PHONE_NUMBER', submitted_phone_number);
    },
    async updateValidUntil({ commit }, valid_until) {
        commit('SET_VALID_UNTIL', valid_until);
    }
}

const mutations = {
    SET_REFERENCE_NUMBER(state, reference_number) {
        state.reference_number = reference_number;
    },
    SET_SUBMITTED_PHONE_NUMBER(state, submitted_phone_number) {
        state.submitted_phone_number = submitted_phone_number;
    },
    SET_VALID_UNTIL(state, valid_until) {
        state.valid_until = valid_until;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};

