import { gql } from '@apollo/client/core';

export const EXERCISE_SUBMIT = gql`
    mutation ExerciseSubmit(
        $id: ID!
        $answers: [AnswerInput]
    ) {
        submitExercise(input: {
            id: $id
            answers: $answers
        }) {
            grade
            time
        }
    }
`;