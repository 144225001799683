<template>
    <div class="flex justify-between items-center">
      <ButtonCustom
        :width="previousButtonWidth"
        :height="previousButtonHeight"
        bgColor="bg-mobile-footer-gray"
        className="col-span-1 rounded-2xl bg-lights-gray-custom text-gray-font p-2"
        @click="handleBackClicked"
      >
        <DirectionalBackIcon v-if="isValidPrevious" color="black" :isBack="true" :dir="dir"/>
        <i v-else class="fa-solid fa-xmark text-black"></i>
      </ButtonCustom>
      <ButtonCustom
        :width="nextButtonWidth"
        :height="nextButtonHeight"
        :class="isValidNext ? 'bg-blue-custom text-white' : 'bg-light-gray-custom text-gray-font'"
        className="p-2 rounded-2xl "
        @click="handleNextClicked"
      >
        <div class="grid grid-cols-3 items-center w-full">
          <div class="col-span-1"></div>
          <span class="font-bold-700 col-span-1">
            {{ nextText }}
          </span> 
          <div class="col-span-1 mx-1">
            <svgicon v-if="isValidNext" :data="arrow" width="20" height="20"  original />
          </div>
        </div>
      </ButtonCustom>
    </div>
  </template>
  
  <script>
  import ButtonCustom from "@/components/General/ButtonCustom.vue";
  import DirectionalBackIcon from "@/components/Icons/DirectionalArrowIcon.vue";
  import arrow from "@/assets/svg/right-blue-arrow.svg";

  export default {
    name: "NavigationButtonsWithIcons",
    components: {
      ButtonCustom,
      DirectionalBackIcon,
    },
    data() {
      return {
        arrow,
      };
    },
    props: {
      nextText: {
        type: String,
        default: 'Next Step',
      },
      isValidPrevious: {
        type: Boolean,
        default: false,
      },
      isValidNext: {
        type: Boolean,
        default: true,
      },
      previousButtonWidth: {
        type: String,
        default: '35px',
      },
      previousButtonHeight: {
        type: String,
        default: '35px',
      },
      nextButtonWidth: {
        type: String,
        default: '277px',
      },
      nextButtonHeight: {
        type: String,
        default: '35px',
      },
      dir: {
        type: String,
        default: 'LTR',
      }
    },
    methods: {
      handleBackClicked() {
        this.$emit('back-clicked');
      },
      handleNextClicked() {
        this.$emit('next-clicked');
      },
    },
  };
  </script>