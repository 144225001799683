import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";
import {VIDEO_CONTENT} from "@/graphql/queries/video/video";

export const getVideoContent =  (slug) => {


    const { result, loading, error } = useQuery(VIDEO_CONTENT
        ,
        {
            slug : slug
        },
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        }
    )

    const videoContent = computed(() => result.value?.videoContent ?? []);

    return {
        videoContent,
        loading,
        error
    }
}