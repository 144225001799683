<template>
  <div :dir="dir">
    <div class="flex gap-2 mb-8 justify-start parent align-center">
      <div class="flex justify-between items-start sm:items-center align-center" :class="gap">
        <svgicon
          :data="BackIcon"
          :width="isMobile ? 19.5 : 37"
          :height="isMobile ? 19.5 : 37"
          original
          @click="goBack"
          class="cursor-pointer backButton"
          :style="{ transform: isRTL() ? 'rotate(180deg)' : '' }"
        />
        <div class="dynamic-align" :class="color" :style="{ fontSize: isMobile ? mobileFontSize : desktopFontSize }">
          {{ mainSection }} <span v-if="subSection">|</span> {{ subSection }}
        </div>
      </div>
      <CheckedIcon 
        :inHeader="true" 
        :whiteColor="!isFinished" 
        v-if="isFinished" 
        :width="isMobile ? 19.5 : 23" 
        :height="isMobile ? 19.5 : 20"
      />
    </div>
  </div>
</template>

<script>
import CheckedIcon from "@/components/Icons/CheckedIcon.vue";
import BackIcon from "@/assets/svg/back.svg";
import windowSizeMixin from "@/mixins/windowSize";

export default {
  mixins: [windowSizeMixin],
  name: "BackBreadCrumb",
  components: {
    CheckedIcon,
  },
  data() {
    return {
      BackIcon,
    };
  },
  props: {
    mainSection: {
      type: String,
      default: "Default Main Section",
    },
    subSection: {
      type: String,
    },
    isFinished: {
      type: Boolean,
      default: false,
    },
    backPage: {
      type: [Function , String],
    },
    color: {
      type: String,
      default: "text-gray-custom",
    },
    gap: {
      type: String,
      default: "gap-2",
    },
    dir: {
      type: String,
      default: "RTL",
    },
    mobileFontSize: {
      type: String,
      default: '12px',
    },
    desktopFontSize: {
      type: String,
      default: '17px',
    },
  },
  methods: {
    goBack() {
      if (typeof this.backPage === 'function') {
        this.backPage();
      } else {
        window.history.length > 1
          ? this.$router.go(-1)
          : this.$router.push("/");
      }
    },
    isRTL() {
      return this.dir === "RTL";
    },
  },
};
</script>

<style scoped>
.parent {
  align-items: center;
}
@media (max-width: 768px) {
  .parent {
    align-items: start;
  }
  .parent div {
    align-items: start;
  }
  .backButton {
    width: 19.5px;
    height: 19.5px;
  }
}
</style>
