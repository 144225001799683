import { gql } from '@apollo/client/core';

export const STUDY_CONTENT_TAB = gql`
    query GetStudyContentTab($id: Int!){
        studyContentTab(input: {
            id: $id
        }) {
            id
            name
            courses {
                name
                slug
                progress
                teacher {
                    id
                    name
                }
                photo {
                    full_link
                }
                lessonPhoto {
                    full_link
                }
            }
            icon {
                full_link
            }
        }

    }
`;
