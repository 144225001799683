export const isPasswordDuplicate = (value, password) => {
    const confirmPasswordInput = document.getElementById('confirmPassword');
    const trimmedValue = value && value.trim();

    const updateInputClass = (input, addClass, removeClass) => {
        if (input) {
            input.classList.remove(removeClass);
            input.classList.add(addClass);
        }
    };

    if (!trimmedValue) {
        updateInputClass(confirmPasswordInput, 'badBorder', 'goodBorder');
        return 'الرجاء إدخال تأكيد الباسوورد*';
    }

    if (trimmedValue.length < 6) {
        updateInputClass(confirmPasswordInput, 'badBorder', 'goodBorder');
        return 'تأكيد الباسوورد لازم يكون من 6 خانات على الأقل*';
    }

    if (trimmedValue !== password) {
        updateInputClass(confirmPasswordInput, 'badBorder', 'goodBorder');
        return 'الباسوورد غير متطابق*';
    }

    updateInputClass(confirmPasswordInput, 'goodBorder', 'badBorder');
    return true;
};
