<template>
  <div class=" min-h-screen flex flex-column justify-center items-center ">
    <login/>
  </div>
</template>

<script>
  import login from "../../components/LogIn/Login.vue";
  
  export default {
    name: "LoginPage",
    components: {
      login
    },
  }
  
</script>
