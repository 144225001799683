<template>
  <div class="w-full flex flex-col justify-start items-center bg-[#f4f6f8] mt-[20%]" dir="rtl">
    <LoadingSpinner :is-loading="isLoadingProfileData || isLoadingContactUs || isLoading" />

    <div class="w-[80%] md:w-[60%] md:max-w-[680px] p-3 flex flex-col justify-center items-center md:items-start">
      <p class="text-gray-custom text-[16px] font-medium">{{ firstName }} {{ lastName }}</p>
    </div>

    <router-link :to="{ name: 'EditProfile' }"
      class="w-[80%] md:w-[60%] md:max-w-[680px] flex justify-center  top-[21.5%] md:top-[28.39%] ">
      <div
        class="w-full md:max-w-[662px] h-[54px] p-4 flex justify-between items-center rounded-[10px] m-2 bg-white border-2 cursor-pointer">
        <div class="flex w-[80%] md:w-[60%] justify-start items-center">
          <svgicon :data="editInfoIcon" width="20" height="20" original />
          <div class="text-blue-custom mr-[30px]">تعديل البيانات</div>
        </div>
        <div class="text-blue-custom">
          <ChevronLeft />
        </div>
      </div>
    </router-link>


    <div @click="handleContactUsClick"
      class=" w-[80%] md:w-[60%] md:max-w-[680px] flex justify-center  top-[30.125%] md:top-[35.59%]">
      <div
        class="w-full md:max-w-[662px] h-[54px] p-4 flex justify-between items-center rounded-[10px] m-2 bg-white border-2 cursor-pointer">
        <div class="flex w-[80%] md:w-[60%] justify-start items-center to-blue-400">
          <svgicon :data="whatsAppIcon" width="32" height="32" original />
          <div class="text-blue-custom mr-[30px] md:mr-[20px]">تواصل معنا</div>
        </div>
      </div>
    </div>


    <router-link :to="{ name: 'AboutUs' }"
      class=" w-[80%] md:w-[60%] md:max-w-[680px] flex justify-center  top-[38.75%] md:top-[42.79%]">
      <div
        class="w-full md:max-w-[662px] h-[54px] p-4 flex justify-between items-center rounded-[10px] m-2 bg-white border-2 cursor-pointer">
        <div class="flex w-[90%] md:w-[60%] justify-start items-center">
          <svgicon :data="infoAboutIcon" width="30" height="30" original />
          <div class="text-blue-custom mr-[30px] md:mr-[25px]">معلومات عن الخطة</div>
        </div>
        <div class="text-blue-custom">
          <ChevronLeft />
        </div>
      </div>

    </router-link>



    <div class="md:hidden w-[80%] md:w-[680px] flex justify-center  top-[47.375%] " @click="handleSignOutOnClick">
      <div
        class="w-full h-[54px] p-4 flex justify-between items-center rounded-[10px] m-2 bg-white border-2 cursor-pointer">
        <div class="flex w-[80%] justify-start items-center">
          <svgicon :data="logOutIcon" width="30" height="30" original />
          <div class="text-blue-custom mr-[30px]">تسجيل الخروج</div>
        </div>
        <div class="text-blue-custom">
          <ChevronLeft />
        </div>
      </div>
    </div>

  </div>


</template>

<script>
import ChevronLeft from '@/components/Icons/ChevronLeftIcon.vue'
import editInfoIcon from '@svgicon/edit-info.svg';
import whatsAppIcon from '@svgicon/whats-app.svg';
import infoAboutIcon from '@svgicon/info-about.svg';
import logOutIcon from '@svgicon/logout.svg';
import {getMyProfileData} from "@/graphql/queries/myProfile/handlingCalls";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import contactUs from "@/mixins/contactUs";
import LogOut from "@/mixins/logOut";

export default {
  mixins: [contactUs , LogOut],
  inheritAttrs: false,
  name: "MainProfilePage",
  components: {LoadingSpinner, ChevronLeft},
  data() {
    return {
      editInfoIcon: editInfoIcon,
      whatsAppIcon: whatsAppIcon,
      infoAboutIcon: infoAboutIcon,
      logOutIcon: logOutIcon,
      myProfileData: [],
      firstName: '',
      lastName: '',
      isLoadingProfileData : false,
    }
  },
  methods: {
    fetchEditProfileData () {


      const { profileData  , loading  } = getMyProfileData();

      this.$watch(
          () => profileData.value,
          (newVal) => {
            this.myProfileData = newVal;
            this.updateNameFields();

          },
          { immediate: true }
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.isLoadingProfileData = newVal;
          },
          { immediate: true }
      );

    },
    updateNameFields() {
      if (this.myProfileData) {
        const { name, personalData } = this.myProfileData;
        this.firstName = name || '';
        this.lastName = personalData ? personalData.last_name || '' : '';
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchEditProfileData();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchEditProfileData();
    next();
  },
}
</script>
  
