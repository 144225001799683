<template>
  <div class="w-[284px] md:w-[375px] m-auto mt-4 flex justify-center items-center">
    <svgicon :data="errorIcon" width="17" height="17" original/>
    <ErrorCouponText :errorCoupon="errorCoupon"/>
  </div>
</template>

<script>
import ErrorCouponText from '@/components/Subscriptions/AddToCartFawry/ErrorCouponText.vue';
import errorIcon from '@svgicon/error-x-red.svg';

export default {
  name: 'FawryErrorCouponIcon',
  props: {
    errorCoupon: {
      type: String
    }
  },
  data (){
    return {
      errorIcon: errorIcon
    }
  },
  components: {
    ErrorCouponText
  }
}
</script>