import { gql } from '@apollo/client/core';

export const GET_EDUCATION_TYPES = gql`
    query {
        educationTypes {
            id
            name
            educationStages {
                id
                name
                educationClasses {
                    id
                    name
                    educationSections {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export const GET_EDUCATION_TYPES_WITHOUT_CLASSES_SECTIONS = gql`
    query {
        educationTypes {
            id
            name
            educationStages {
                id
                name
            }
        }
    }
`;
