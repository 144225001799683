<template>
  <div :dir="direction"
    class="bg-[#f4f6f8]  px-4 pt-mobile-top flex flex-column min-h-screen items-center mt-[40px] relative">
    <div class="flex-1 mx-auto w-full md:w-[58%]">
      <div>
        <BreadCrumb :mainSection="courses?.name" :subSection="courses?.teacher?.name" :backPage="goBack"
          :is-finished="false" :dir="direction" />
        <SectionFilter @changeTap="activeTab = $event" :filters="localizedFilters" :showAllButton="true"
          :activeTab="activeTab" :dir="direction" />
      </div>
      <Content v-if="activeCourses.length > 0" :dir="direction" :data="activeCourses" type="course"
        :contentType="'course'" />
        
    </div>
    <div class="fixed-footer" v-if="showPromotionButton && !isMobile">
      <FooterBlur bgColor="bg-background-custom" class="p-4 shadow">
        <div class="max-w-2xl mx-auto">
          <div class="flex items-center justify-center">
              <ButtonCustom width="250px" bgColor="bg-blue-custom" @buttonCustomClicked="handlePromotionButtonClicked"
                className="py-2 px-10 rounded-full text-white mx-auto font-bold-700">
                {{ promotionButton?.name ?? 'SUBSCRIBE NOW' }}
              </ButtonCustom>
          </div>
        </div>
      </FooterBlur>
    </div>
    <div v-else-if="showPromotionButton && isMobile"
      class="fixed bottom-[90px] left-1/2 transform -translate-x-1/2 z-50">
        <ButtonCustom height="30px" width="250px" bgColor="bg-blue-custom" @buttonCustomClicked="handlePromotionButtonClicked"
          class="py-1 px-4 rounded-full text-white mx-auto font-bold-700">
          {{ promotionButton?.name ?? 'SUBSCRIBE NOW' }}
        </ButtonCustom>
    </div>


  </div>
</template>

<script>
import BreadCrumb from "@/components/General/BreadCrumb.vue";
import SectionFilter from "@/components/General/SectionFilter.vue";
import Content from "@/components/General/Content.vue";
import { getLocalizedFilters } from "@/data/CourseFilterData";
import routeParamsMixin from "@/mixins/routeParams";
import windowSizeMixin from "@/mixins/windowSize";
import FooterBlur from "@/components/General/FooterSection.vue";
import ButtonCustom from "@/components/General/ButtonCustom.vue";

export default {
  mixins: [routeParamsMixin, windowSizeMixin],
  name: "StudyCourses",
  components: {
    BreadCrumb,
    SectionFilter,
    Content,
    FooterBlur,
    ButtonCustom
  },
  props: {
    courses: {
      type: Object,
      required: true,
    },
    coursesError: {
      type: [Object, Array, String, null],
      required: true,
    },
  },
  data() {
    return {
      activeTabValue: null,
    };
  },
  computed: {
    direction() {
      return this.courses?.course_direction || "RTL";
    },
    localizedFilters() {
      return getLocalizedFilters(this.direction);
    },
    promotionButton() {
      return this.courses?.promotion_button || {};
    },
    showPromotionButton() {
      return this.promotionButton?.name && this.promotionButton?.url
    },
    activeTab: {
      get() {
        return (
          this.activeTabValue || this.courses?.courseTabs?.default_active_term
        );
      },
      set(newVal) {
        this.activeTabValue = newVal;
      },
    },
    activeCourses() {
      switch (this.activeTab) {
        case "FIRST_TERM":
          return this.courses?.courseTabs?.FIRST_TERM || [];
        case "SECOND_TERM":
          return this.courses?.courseTabs?.SECOND_TERM || [];
        case "ALL":
          return this.courses?.courseTabs?.ALL || [];
        default:
          return [];
      }
    },
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "studyContent",
      });
    },
    handlePromotionButtonClicked(){
      if (this.promotionButton?.url){
        window.open(this.promotionButton?.url ,"_self");
      }

    }
  },
};
</script>

<style scoped>
.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 43%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 100%;
  z-index: 10;
  background: white;
  width: calc(100% - 256px);
}

.shadow {
  box-shadow: 10px -10px 10px 0px rgba(0, 0, 0, 0.1);
}
</style>