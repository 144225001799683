<template>
  <span class="text-[17px] text-gray-custom  ">تم إرسال كود الدفع إلى رقم</span>
  <span class="mt-2 text-[17px] text-gray-custom"> {{ submittedPhoneNumber }} </span>
</template>
    
<script>
  export default {
    name: 'submittedPhoneNumberFawryCheckout',
    props:{
      submittedPhoneNumber: {
        type: String,
      }
    },
  }
</script>