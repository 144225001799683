import { useMutation } from '@vue/apollo-composable';
import {LOGIN_USER} from "@/graphql/mutations/login/login";
import {handleResponse} from "@/helpers/graphqlResponseHandler";


export const login = (async (userData) => {

    const { mutate } = useMutation(LOGIN_USER ,
        {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
            variables: {
                phone_number: userData.phone_number,
                password: userData.password,
            },
        }
    )

    try {
        const response = await mutate()
        return handleResponse(response , 'loginUser');

    } catch (error) {
        console.error('Error adding data:', error);
    }
})