<template>
  <div class=" min-h-screen flex flex-column justify-center items-center ">
    <ForgotPassword/>
  </div>
</template>

<script>
  import ForgotPassword from "../../components/ForgotPassword/ForgotPasswordInitial.vue";
  
  export default {
    name: "ForgotPasswordPage",
    components: {
      ForgotPassword
    }
  }
  
</script>