export default {
    computed: {
        formattedFeatures() {
            return this.features.replace(
                /src="([^"]*)"/g,
                (match, imageSrc) => {
                    if (imageSrc.startsWith('http://') || imageSrc.startsWith('https://')) {
                        return `src="${imageSrc}"`;
                    } else {
                        try {
                            return `src="${require('@/assets/images/' + imageSrc)}"`;
                        } catch (error) {
                            // Image file not found, return the original match
                            return match;
                        }
                    }
                }
            );
        },
    },
};