<template>
  <span class="font-bold-500 text-gray-custom flex flex-row">
    <span>
      {{ counter }}
    </span>
    /
    <span>
      {{ all }}
    </span>
  </span>
</template>

<script>
export default {
  name: "HeaderCounter",
  props: {
    all: {
      type: [String , Number],
      default: 1,
    },
    counter: {
      type: [String , Number],
      default: 1,
    },
  },
};
</script>
