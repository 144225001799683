<template>
  <LoadingSpinner :is-loading="isLoading" />
  <div class="w-[324px] border-2 md:w-[438px] border-blue-custom bg-white rounded-[10px] flex flex-col items-center mx-1  mt-2" dir="rtl">

    <div class="w-[90%] flex flex-col justify-center items-center">
      <div class="w-full flex justify-between mt-[4px] md:mt-0">
        <CardTitle :product="product"/>
        <OrderId :orderId="orderId"/>
      </div>
      <CurrentSubsExpiration :expiration="expiresIn" class=" mb-[6px] md:mb-0"/>
      <RenewSubsButton v-if="isRenewable" @click="addToCart(productId)"/>
      <div v-if="productDiscountType && productDiscountType" class="flex justify-center items-center mb-[13px]">
        <svgicon :data="moneyBagIcon" width="30" height="30" original/>
        <CurrentSubsExpirationMessage
            :productDiscountType="productDiscountType"
            :productDiscountAmount="productDiscountAmount"
            :expiresInDiscount="expiresInDiscount"
        />
      </div>
    </div>

    <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
        <span v-for="(error, index) in errors" :key="index">
          {{ error }}
        </span>
    </div>
  </div>
</template>

<script>
import {addToCart} from '@/graphql/mutations/cart/handlingCalls';
import CardTitle from '@/components/Subscriptions/CurrentSubscribedCard/CardTitle.vue';
import OrderId from '@/components/Subscriptions/CurrentSubscribedCard/CardTitleOrderId.vue';
import CurrentSubsExpiration from '@/components/Subscriptions/CurrentSubscribedCard/CurrentSubsExpiration.vue';
import CurrentSubsExpirationMessage from '@/components/Subscriptions/CurrentSubscribedCard/CurrentSubsExpirationMessage.vue';
import moneyBagIcon from '@svgicon/money-bag.svg';
import RenewSubsButton from '@/components/Subscriptions/CurrentSubscribedCard/RenewSubsButton.vue';
import routes from "@/router/routes";

export default {
  name: 'CurrentSubscribedCard',
  props: {
    enrollment: {
      type: Object,
    }
  },
  data() {
    return {
      product: this.enrollment?.product || {},
      productId: this.enrollment?.product?.id,
      orderId: this.enrollment?.order?.id || '',
      expiresIn: this.enrollment?.expires_in || '',
      isRenewable : this.enrollment?.product?.is_renewable || false,
      productDiscountType : this.enrollment?.product?.product_discount_type || '',
      productDiscountAmount : this.enrollment?.product?.product_discount_amount || '',
      expiresInDiscount : this.enrollment?.expires_in_discount || '',
      moneyBagIcon: moneyBagIcon,
      errors: [],
      isLoading: false

    }
  },
  components: {
    CardTitle,
    OrderId,
    CurrentSubsExpiration,
    CurrentSubsExpirationMessage,
    RenewSubsButton
  },
  methods: {
    async addToCart(productId) {
      this.errors = [];

      try {
        const addToCartResponse = await this.$withLoading(() => addToCart(true, productId));

        if (addToCartResponse.success === false) {
          this.errors = addToCartResponse.displayValidationMessages;
        } else {
          routes.navigateTo(routes.CART.key)
        }
      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    },
  },
}
</script>