<template>
    <div class=" sm:px-0 pb-32">
        <div v-for="(item, index) in courses" :key="index">
            <div @click="navigateToCourse(item.slug)">
                <div
                    class="flex flex-col justify-center bg-white rounded-content-radius mt-3 blue-custom-border cursor-pointer border-light-blue-custom mx-auto pr-[6.66%]">
                    <div class="flex justify-between ml-4 pt-4 relative">
                        <div class="min-w-[100px] max-w-[18%] h-auto">
                            <img v-if="item.photo" loading="lazy" :src="item.photo?.full_link"
                                class="aspect-[6/4] mx-auto " :alt="item.photo?.name" />
                            <img v-else loading="lazy" :src="man" :alt="item.photo?.name"
                                class="aspect-[6/4] mx-auto" />
                        </div>
                        <div class="w-[16px] h-full"></div>
                        <div class="flex flex-col w-[81.81%] relative left-0">
                            <div class="flex justify-between items-center">
                                <div class="text-12px md:text-19px font-bold  text-gray-custom toTruncate w-[50%] text-left"
                                    v-if="isArabicRegex.test(item.teacher.name)" dir="rtl">
                                    {{ item.teacher.name }}
                                </div>
                                <div class="text-12px md:text-19px font-bold  text-gray-custom toTruncate w-[50%] text-left"
                                    v-if="!(isArabicRegex.test(item.teacher.name))" dir="ltr">
                                    {{ item.teacher.name }}
                                </div>
                                <div class="flex align-center justify-end w-[37.5%]">
                                    <span
                                        class="text-sky-500 whitespace-nowrap font-bold-500 text-sm md:text-19px toTruncate"
                                        v-if="!(isArabicRegex.test(item.name))" dir="ltr">
                                        {{ item.name }}
                                    </span>
                                    <span
                                        class="text-sky-500 whitespace-nowrap font-bold-500 text-sm md:text-19px toTruncate"
                                        v-if="isArabicRegex.test(item.name)" dir="rtl">
                                        {{ item.name }}
                                    </span>
                                    <img v-show="item.lessonPhoto" loading="lazy" :src="item.lessonPhoto?.full_link"
                                        class="w-5 h-5 lg:w-8 lg:h-8 aspect-square ml-2"
                                        :alt="item.lessonPhoto?.name" />
                                </div>
                            </div>
                            <ProgressBar :progress="item.progress" dir="RTL" class="my-[15px]"
                                :progress100="item.progress === 100" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProgressBar from "@/components/FormComponents/ProgressBar.vue";
import routes from "@/router/routes.js";
import man from "@/assets/images/man.png";

export default {
    name: "StudyContent",
    components: {
        ProgressBar,
    },
    props: {
        courses: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            man,
            isArabicRegex: /[\u0600-\u06FF\u0750-\u077F]/,
        };
    },
    methods: {
        navigateToCourse(courseSlug) {
            routes.navigateToCourse(courseSlug);
        },

    },
    computed: {
    },
};
</script>

<style scoped>
.text-zinc-500 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.toTruncate {
    unicode-bidi: bidi-override;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
