import { gql } from '@apollo/client/core';

export const ADD_TO_CART = gql`
    mutation AddToCart(
        $product_id: Int!
        $is_renewable: Boolean!
    ) {
        addToCart(input: {
            product_id: $product_id
            is_renewable: $is_renewable
        }){
            product_id
        }
        
    }
`;