import { useMutation } from '@vue/apollo-composable';
import {handleResponse} from "@/helpers/graphqlResponseHandler";
import {ADD_TO_CART} from "@/graphql/mutations/cart/addToCart";
import {APPLY_COUPON} from "@/graphql/mutations/cart/applyCoupon";
import {REMOVE_COUPON} from "@/graphql/mutations/cart/removeCoupon";
import {CHECKOUT} from "@/graphql/mutations/cart/checkout";

export const addToCart =  (async (isRenewable, productId) => {

    const { mutate } = useMutation( ADD_TO_CART,
        {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
            variables: {
                product_id: parseInt(productId),
                is_renewable: Boolean(isRenewable),
            },
        }
    )

    try {
        const response = await mutate();
        return handleResponse(response , 'addToCart');

    } catch (error) {
        console.error('Error adding data:', error);
    }
})

export const applyCoupon =  (async ( couponCode) => {

    const { mutate } = useMutation( APPLY_COUPON,
        {
            errorPolicy: 'all',
            variables: {
                coupon_code: couponCode,
            },
        }
    )

    try {
        const response = await mutate();
        return handleResponse(response , 'applyCoupon');

    } catch (error) {
        console.error('Error adding data:', error);
    }
})

export const removeCoupon =  (async () => {

    const { mutate } = useMutation( REMOVE_COUPON,
        {
            errorPolicy: 'all',
        }
    )

    try {
        const response = await mutate();
        return handleResponse(response , 'removeCoupon');

    } catch (error) {
        console.error('Error adding data:', error);
    }
})

export const checkout =  (async ( paymentMethodId , phoneNumber = '' , email = '' ) => {

    const { mutate } = useMutation( CHECKOUT,
        {
            errorPolicy: 'all',
            variables: {
                payment_method_id: parseInt(paymentMethodId),
                user_phone_number: phoneNumber,
                user_email: email
            },
        }
    )

    try {
        const response = await mutate();
        return handleResponse(response , 'checkout');

    } catch (error) {
        console.error('Error adding data:', error);
    }
})


