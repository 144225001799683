<template>
  <div class="w-[330px] md:w-[438px] h-[50px] bg-yellow-300 rounded-[10px] md:rounded-[0px] md:rounded-t-[10px]
              flex justify-center items-center font-bold text-[16px] md:text-[19px]">
    {{ name }} 
  </div>
</template>
    
<script>
  export default {
    name: 'CardHeader',
    props:{
      name: {
        type: String,
      }
    },
  }
</script>