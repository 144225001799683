<template>
  <div :class="fontClass">
    <LoadingSpinner :is-loading="isLessonsLoading" />
    <LessonInnerContent v-if="!lessonsError" :lessons="lessons" :lessonsError="lessonsError" />
    <Page404Content v-else />
  </div>
</template>

<script>

import {
  getLessonContent
} from "@/graphql/queries/studyContent/handlingCalls";
import {handleErrors} from "@/helpers/graphqlQueryErrorResponseHandler";
import routeParamsMixin from "@/mixins/routeParams";
import LessonInnerContent from "@/components/Lesson/LessonInnerContent.vue";
import Page404Content from "@/components/Page404/Page404Content.vue";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import { getFontClass } from '@/helpers/getFontClassHelper';


export default {
  mixins: [routeParamsMixin],

  name: "LessonContent",
  components: {
    LoadingSpinner, Page404Content, LessonInnerContent

  },
  data() {
    return {
      lessons: [],
      isLessonsLoading: true,
      lessonsError: null,
      direction: null
    };
  },

  computed: {
    fontClass() {
      return getFontClass(this.direction);
    },
  },
  methods: {
    fetchLessons() {
      const {
        lessonContent,
        loading,
        error
      } = getLessonContent(this.lessonSlug);

      this.$watch(
          () => lessonContent.value,
          (newVal) => {
            this.lessons = newVal;
            this.direction = newVal?.direction;
          }, {
            immediate: true,
          }
      );
      this.$watch(
          () => error.value,
          (newVal) => {
            this.lessonsError = handleErrors(newVal?.graphQLErrors || []);
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.isLessonsLoading = newVal;
          }, {
            immediate: true,
          }
      );
    },
    onLoad() {
      this.fetchLessons();
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.getRouteParamsData(to.params));
    this.onLoad();
    next();
  },
};
</script>


