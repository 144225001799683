<template>
  <div class="relative w-full min-h-screen" dir="rtl">
      <div v-for="askTeacher in askTeachers" :key="askTeacher.url" class="relative top-[13.5%] md:top-[18.47%] flex flex-col items-center">
        <div @click="handleContactUsClick(askTeacher.url , askTeacher.is_student_enrolled)" class="hover:cursor-pointer w-[80%] min-w-[284px] md:w-[80%] md:max-w-[672px] h-[54px] bg-[#7AD06D] rounded-[10px] mb-3 flex justify-between items-center">
          <span class="font-bold text-white relative right-[6.69%] md:right-[7.4%]">{{ askTeacher.label }}</span>
          <svgicon :data="whatsAppIcon"  width="30" height="30" original class="relative left-[6.69%] md:left-[7.4%] "/>
        </div>
      </div>
  </div>

  <Blur ref="NotEnrolled">
    <div class="blur-content font-tajawal  ">
      <div class="relative responsive-container">
        <div
            class="image-container p-4 bg-gray-50 rounded-full border shadow-lg"
        >
          <img loading="lazy" :src="Wallet" class="aspect-square w-auto" />
        </div>
        <NotEnrolledData
            :dir="'RTL'"
            :goTo="goToSubscribe"
            title="عفوا انت غير مشترك"
            subtitle="معرفة تفاصيل الاشتراك"
        />
        <button
            @click="closeBlur"
            class="w-full block text-center mt-4 text-13px md:text-base font-bold-500 text-black-custom md:text-gray-custom"
        >
          إغلاق
        </button>
      </div>
    </div>
  </Blur>

</template>

<script>
import whatsAppIcon from '@svgicon/whats-app-light.svg';
import NotEnrolledData from "@/components/Lesson/NotEnrolledData.vue";
import Blur from "@/components/General/Blur.vue";
import Wallet from "@/assets/icons/wallet.svg";

export default {
  name: 'AskTeacher',
  components: {Blur, NotEnrolledData},
  data() {
    return {
      whatsAppIcon: whatsAppIcon,
      Wallet: Wallet
    }
  },
  props: {
    askTeachers :{
      type: Object,
      required: true
    }
  },
  methods:{
    handleContactUsClick(url , isEnrolled){

      if (!isEnrolled){
        this.$refs.NotEnrolled.showBlur();
      }else{
        window.open(url, "_blank");
      }
    },
    goToSubscribe() {
      this.$router.push({ name: "Subscription" });
    },
    closeBlur() {
      this.$refs.NotEnrolled.hideBlur();
    },
  }
}
</script>
