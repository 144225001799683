<template>

  <div class="bg-[#f4f6f8] min-h-screen flex flex-col justify-center items-center absolute md:w-[calc(100%-260px)]" dir="rtl">
    <LoadingSpinner :is-loading="isLoading"/>

    <p class="text-[30px] font-bold mb-4">ادخل الباسوورد الجديد</p>

    <Form class="w-full h-[400px]" @submit="onSubmit" action="">

      <div class=" w-full">
        <div class="form-check flex flex-column justify-center mb-4">
          <PasswordInput
              v-model="password"
          />
        </div>
      </div>

      <div class="w-full">
        <div class="form-check flex flex-column justify-center mb-4">
          <ConfirmPasswordInput
              v-model="password_confirmation"
              :password="password"
          />
        </div>
      </div>

      <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
        <span v-for="(error, index) in errors" :key="index">
          {{ error }}
        </span>
      </div>

      <BlueButton type="submit" class="mt-[165px]">تغيير الباسوورد</BlueButton>
    </form>

  </div> 
</template>

<script>

import { Form } from 'vee-validate';
import { updateUserPassword } from '@/graphql/mutations/updateUserPassword/handlingCalls';
import PasswordInput from "@/components/FormComponents/PasswordInput.vue";
import ConfirmPasswordInput from "@/components/FormComponents/ConfirmPasswordInput.vue";
import routes from "@/router/routes";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import {mapActions} from "vuex";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";

export default {

  name: 'ChangeNewPassword',
  components:{
    LoadingSpinner,
    BlueButton,
    ConfirmPasswordInput,
    PasswordInput,
    Form,
  },
  data() {
        return {
          password: '',
          password_confirmation: '',
          errors: [],
          isLoading: false
        }
      },
    methods:{
      ...mapActions({
        logoutUser: 'Auth/logoutUser',
      }),
      async onSubmit(){

        this.errors = [];

        const passObject = {
          password: this.password,
          password_confirmation:  this.password_confirmation
        }


        const changePassResponse = await this.$withLoading(() =>updateUserPassword(passObject));

        if (changePassResponse.success === false){
          this.errors = changePassResponse.displayValidationMessages;
        }
        else {
          await this.logoutUser();
          routes.navigateTo(routes.LOGIN.key);
        }
      },
  },
}

</script>

