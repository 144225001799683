import { useMutation } from '@vue/apollo-composable';
import {RESET_PASSWORD} from "@/graphql/mutations/resetPassword/resetPassword";
import {handleResponse} from "@/helpers/graphqlResponseHandler";


export const resetPassword = (async (userData) => {

    const { mutate } = useMutation( RESET_PASSWORD,
        {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
            variables: {
                token: userData.token,
                password: userData.password,
                password_confirmation: userData.password_confirmation,
            },
        },

        )

    try {
        const response = await mutate()
        return handleResponse(response , 'resetPassword');

    } catch (error) {
        console.error('Error adding data:', error);
    }

})