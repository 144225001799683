<template>
  <svgicon
      :data="TinyArrow"
      :width="24"
      :height="24"
      original
      :fill="false"
      :class="{ 'rotate-rtl': dir === 'RTL' }"
  />
</template>

<script>
import TinyArrow from "@/assets/svg/tiny_arrow.svg";

export default {
  props: {
    dir: {
      type: String,
    }
  },
  data() {
    return {
      TinyArrow
    };
  }
}
</script>

<style scoped>
.rotate-rtl {
  transform: rotate(180deg);
}
</style>
