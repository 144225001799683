<template>
  <div class="relative h-full flex items-center z-[1000]" :class="{ 'w-full': isSideBarHidden  , 'block' :isSideBarHidden, 'hidden' : !isSideBarHidden }">
    <div class="w-full flex flex-col md:flex-row-reverse justify-center items-center">
      <div class="absolute md:relative top-[11.625%]">
        <img v-if="isMobile" :src="Page404" class="phone-img" width="349" height="349" />
        <img v-else :src="Page404" class="desktop-img" width="559" height="559" />
      </div>
      <div class=" w-full md:w-auto absolute h-[195px] md:h-auto top-[61%] md:relative md:mt-0 flex flex-col items-center">
        <div class=" md:mx-[41.5px] absolute md:relative top-0 md:top-[65%] lg:top-[71%] left-auto ">
          <h4 class="text-[32px] font-roboto">الصفحة غير موجودة</h4>
          <p class="text-[#4B4B4B] mt-[21px] w-[277px]">الصفحة اللي بتحاول تدخلها مش موجوده او تم نقلها</p>
        </div>
        <router-link :to="{ name: 'Home' }" class="w-full flex items-center justify-center">
          <button class="mt-8 btn w-[284px] h-[56px] rounded-[10px] flex justify-center items-center absolute md:relative top-[55%] md:top-[0%] left-auto" id="submitBtn" type="submit">الذهاب للصفحة الرئيسية</button>
        </router-link>
      </div>
    </div>
  </div>
  
  <div class="relative h-full flex items-center" :class="{ 'with-sidebar': !isSideBarHidden  , 'block' : !isSideBarHidden, 'hidden' : isSideBarHidden  }">
    <div class="w-full flex flex-col justify-center items-center">
      <div class="absolute top-[11.625%]">
        <img :src="Page404" class="phone-img" width="349" height="349" />
      </div>
      <div class="mx-[41.5px] h-[107] absolute top-[61%] md:top-[65%] lg:top-[71%]">
        <h4 class="text-[32px] font-roboto">الصفحة غير موجودة</h4>
        <p class="text-[#4B4B4B] mt-[21px]">الصفحة اللي بتحاول تدخلها مش موجوده او تم نقلها</p>
      </div>
      <router-link :to="{ name: 'Home' }" class="w-full flex items-center justify-center">
        <button class="mt-2 btn w-[284px] h-[56px] rounded-[10px] flex justify-center items-center absolute top-[78.375%] md:top-[83.4%] left-auto" id="submitBtn" type="submit">الذهاب للصفحة الرئيسية</button>
      </router-link>
    </div>
  </div>
  
</template>

<script>
  import Page404 from "@/assets/images/Page404.png";
  import store from "@/store";
  import windowSize from "@/mixins/windowSize";

export default {
  mixins: [windowSize],

  name: 'Page-404-Content',
  props:{
    isSideBarHidden:{
      type: Boolean,
      default: false
    }
  },
  data() {
      return {
        Page404,
      };
    },
    computed: {
    hideSideBar() {
      return Boolean(this.$route.meta.hideSideBar);
    },
    isAuth() {
      return store.getters['Auth/isAuth'];
    },
  },
}
</script>

<style scoped>
.btn{
  background-color: #1C9DEB;
  color: white;
  font-weight: bold;
  margin-bottom:  25px;
  border-radius: 10px;
}

.with-sidebar {
  width: calc(100% - 256px);
}

@media (max-width: 768px) {
  .with-sidebar {
    width: 100%;
  }
}
</style>