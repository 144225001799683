<template>
    <div class="grid grid-cols-12 mt-[4%] items-center">
      <div class="flex flex-col items-center col-span-12 md:col-span-6">
        <div class="mx-auto flex justify-center items-center">
          <div class="flex items-center font-bold-500 gap-1">
            <h6 class="mr-10 md:mr-40 text-21px lg:text-3xl">
              محتوى <span class="text-blue-custom font-bold-700 lg:text-35px"> كامل </span> بين ايديك
            </h6>
            <img :src="book" :width="86" :height="96" />
          </div>
        </div>
  
        <div class="text-blue-custom text-base lg:text-25px font-bold-500 md:font-bold-700 flex flex-col dynamic-align px-1">
          <div class="flex mx-auto items-center">
            <img class="w-16" :src="video" />
            <span class="ml-4 w-72 md:w-full"> فيديوهات شرح شاملة كل جزء في المنهج </span>
          </div>
          <div class="flex mx-auto grid-cols-8 items-center mt-4 md:mt-4">
            <img class="w-16" :src="paper" />
            <span class="ml-4 w-72 md:w-full"> تمارين و واجبات تفاعلية بعد كل حصة </span>
          </div>
          <div class="flex mx-auto grid-cols-8 items-center mt-4 md:mt-4">
            <img class="w-16" :src="idea" />
            <span class="ml-4 w-72 md:w-full"> مراجعات شاملة و مكثفة قبل الامتحانات </span>
          </div>
          <div class="flex mx-auto grid-cols-8 items-center mt-4 md:mt-4">
            <svgicon :data="books" width="60" height="60" original :fill="true"/>
            <span class="ml-4 w-72 md:w-full"> ملخصات جاهزة للطباعة لكل حصة </span>
          </div>
        </div>
      </div>
    </div>
  
    <div class="grid grid-cols-12">
      <div class="flex flex-col items-center col-span-12 md:col-span-8 xl:mt-[10%]">
        <div class="mx-auto flex justify-center items-center">
          <div class="flex items-center font-bold-500 text-xl lg:text-3xl">
            <h6 class="mr-10 md:mr-4">
              <span class="text-blue-custom text-21px lg:text-35px font-bold-700"> مش </span>
              هتكون
              <span class="text-blue-custom text-21px lg:text-35px font-bold-700">لوحدك</span>
              أبدا
            </h6>
            <img :src="heart" width="100" height="100" />
          </div>
        </div>
  
        <div class="text-blue-custom text-base lg:text-25px font-bold-500 md:font-bold-700 flex flex-col dynamic-align px-1">
          <div class="flex mx-auto items-center">
            <img class="w-16" :src="DoubleHeart" width="71" height="54" />
            <span class="w-72"> مشرف خاص لمتابعة مستواك الدراسي</span>
          </div>
          <div class="flex mx-auto items-center mt-4 md:mt-4">
            <svgicon class="w-16" :data="support" width="60" height="60" original :fill="false"/>
            <span class="w-72 md:w-full ml-4"> تواصل مباشر مع المدرسين للإجابة عن أسئلتك</span>
          </div>
          <div class="flex mx-auto items-center mt-4 md:mt-4">
            <img class="w-16" :src="Calendar" width="71" height="50" />
            <span class="w-72 md:w-full ml-4"> جداول مذاكرة يومية منظمة لكل المواد</span>
          </div>
          <div class="flex mx-auto items-center mt-4 md:mt-4">
            <img class="w-16" :src="report" width="49" height="50" />
            <span class="w-72 md:w-full ml-4"> تقارير شهرية لولي الأمر لمتابعة مستواك</span>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import books from "@/assets/svg/landing/books.svg";
  import support from "@/assets/svg/landing/support.svg";

  export default {
    data() {
      return {
        books:books,
        support: support
      }
    },
    props: {
      boy: String,
      book: String,
      video: String,
      paper: String,
      idea: String,
      heart: String,
      DoubleHeart: String,
      Calendar: String,
      report: String,
      phone: String
    },
    
  }
  </script>