<template>
  <div class="flex flex-column min-h-screen items-center">
    <LoadingSpinner :is-loading="isLoading" />
    <div v-if="!isLoading && (!data || !data.questions || data.questions.length === 0)"
      class="w-full flex items-center justify-center">
      <NotFound message="We are still processing your answers." />
    </div>
    <div v-else class="flex flex-col items-center mt-[2rem] w-full">
      <div class="w-full my-4">
        <div class="w-2xl mx-auto px-[16px] fixed-content flex flex-col gap-3">
          <ExerciseHeader v-if="data?.exercise_name" :dir="direction" :exerciseTitle="data?.exercise_name"
            class="flex flex-row w-full items-start" :textColor="isMobile ? 'black' : 'blue-custom'"
            @x-icon-clicked="handleXIconClickedReview" />
          <div class="w-full flex flex-row justify-start items-start gap-3 dynamic-align" :dir="direction">
            <input type="checkbox" id="showMistakes" v-model="mistakesOnly" class="toggle-switch">
            <div>{{ ShowMistakesOnlyText }}</div>
          </div>
        </div>
      </div>
      <div class="flex flex-column flex-1 mx-auto w-full mb-[90px]">
        <div class="flex flex-col items-center mt-[2rem]">
          <div class="w-full my-4">
            <div v-if="filteredQuestions && filteredQuestions.length" class="exercise-content mx-auto mt-[5%]">
              <div v-for="(question, index) in filteredQuestions" :key="question.id">
                <ExerciseQuestion v-if="question" :question="question" :index="index + 1" :direction="direction" />
                <div v-if="question.answers" class="mt-4 mb-8 max-w-2xl mx-auto px-[36px] md:px-[0]">
                  <AnswerChoices v-for="answer in question.answers" :key="answer.id" :answer="answer"
                    :direction="direction" :selected-answer="question.user_answer"
                    :isCorrectAnswer="isCorrectAnswer(answer.id, question)"
                    :isWrongAnswer="isWrongAnswer(answer.id, question)" />
                </div>
                <!-- Button to toggle explanation -->
                <div v-if="question.model_answer" class="max-w-2xl mx-auto px-[36px] md:px-[0] my-4 font-bold text-lg">
                  <button @click="toggleExplanation(question.id)" class="text-blue-500">
                    <span class="flex items-center gap-2">
                      <span>
                        {{ showExplanation[question.id] ? hideAnswerExplanationText : showAnswerExplanationText }}
                      </span>
                      <span>
                        <svgicon :data="PointingHandIcon" :width="24" :height="24" original class="" />
                      </span>
                    </span>
                  </button>
                </div>
                <!-- Model answer -->
                <AnswerResult v-if="showExplanation[question.id]" class="max-w-2xl mx-auto px-[36px] md:px-[0] my-4"
                  :dir="direction" :isCorrect="question.correct_answer_id === question.user_answer"
                  :modelAnswer="question.model_answer || {}" :correctAnswer="getCorrectAnswer(question.id)"
                  :selectedAnswer="question.user_answer" :correctAnswerText="correctAnswerText"
                  :wrongAnswerText="wrongAnswerText" :explainAnswerText="explainAnswerText"
                  :yourCorrectAnswerText="yourCorrectAnswerText" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Blur ref="closeReview" widthClass="w-10/12" mdWidthClass="md:w-5/12">
      <div class="blur-content">
        <div class="responsive-container">
          <div
            class="image-container bg-gray-50 rounded-full border shadow-md h-14 w-14 flex items-center justify-center">
            <FlagIcon />
          </div>
          <div class="text-container text-base md:text-19px">{{ wantToCloseAnswerReviewText }}</div>
          <div
            class="flex flex-col md:flex-row gap-5 justify-center items-center w-full text-gray-custom px-4 mt-16 buttons-holder">
            <ButtonCustom @click="close" bgColor="bg-gray-font"
              className="text-white rounded-full p-4 text-base font-bold" height="35px" width="100%">
              {{ closeAnswerReviewText }}
            </ButtonCustom>
            <ButtonCustom @click="resume" bgColor="bg-blue-custom"
              className="text-white rounded-full text-base font-bold p-4" height="35px" width="100%">
              {{ continueAnswerReviewText }}
            </ButtonCustom>
          </div>
        </div>
      </div>
    </Blur>
  </div>
</template>

<script>
import NotFound from "@/components/Page404/DataNotFound.vue";
import ButtonCustom from "@/components/General/ButtonCustom.vue";
import AnswerChoices from "@/components/Exercise/Answers/AnswerChoicesResult.vue";
import ExerciseQuestion from "@/components/Exercise/Question.vue";
import ExerciseHeader from "@/components/General/Header.vue";
import routeParamsMixin from "@/mixins/routeParams";
import exerciseContentDataMixin from "@/mixins/exerciseContentData";
import windowSizeMixin from "@/mixins/windowSize";
import FlagIcon from "@/components/Icons/FlagIcon.vue";
import Blur from "@/components/General/Blur.vue";
import { getStudentSavedExerciseAnswers } from "@/graphql/queries/exercise/handlingCalls";
import { handleErrors } from "@/helpers/graphqlQueryErrorResponseHandler";
import AnswerResult from "@/components/Exercise/Answers/AnswerResult.vue";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import PointingHandIcon from "@/assets/svg/pointing-left.svg";

export default {
  name: "ExerciseReview",
  components: {
    Blur, FlagIcon, ExerciseHeader, ExerciseQuestion, ButtonCustom, AnswerChoices, AnswerResult, NotFound, LoadingSpinner
  },
  mixins: [routeParamsMixin, exerciseContentDataMixin, windowSizeMixin],
  props: {
    contentDataWrapperProp: {
      type: String,
      default: 'exerciseContent'
    },
    contentDataProp: {
      type: String,
      default: 'exercise'
    },
    contentErrorDataProp: {
      type: String,
      default: 'exerciseError'
    },
  },
  data() {
    return {
      PointingHandIcon,
      direction: null,
      isLoading: false,
      mistakesOnly: false,
      data: {},
      questions: [],
      showExplanation: {}
    };
  },
  computed: {
    filteredQuestions() {
      if (!this.data || !this.data.questions) return [];
      if (this.mistakesOnly) {
        return this.data.questions.filter(question => {
          if (question.user_answer !== null) {
            return question.answers.some(answer => {
              return question.user_answer === answer.id && !answer.is_correct;
            });
          } else {
            return question;
          }
        });
      }
      return this.data.questions;
    }
  },
  methods: {
    toggleExplanation(questionId) {
      const isCurrentlyShown = this.showExplanation[questionId];
      Object.keys(this.showExplanation).forEach(key => {
        this.showExplanation[key] = false;
      });
      this.showExplanation[questionId] = !isCurrentlyShown;
    },
    handleXIconClickedReview() {
      this.$refs.closeReview.showBlur();
      this.disableFooter = true;
    },
    resume() {
      this.$refs.closeReview.hideBlur();
      this.disableFooter = false;
    },
    close() {
      this.$router.go(-1);
    },

    isCorrectAnswer(answerId, question) {
      if (!question || !question.answers) return false;
      const correctAnswer = question.answers.find(a => a.is_correct);
      return correctAnswer && correctAnswer.id === answerId;
    },
    getCorrectAnswer(questionId) {
      if (!Array.isArray(this.questions) || this.questions.length === 0) {
        return {};
      }

      const question = this.questions.find(q => q.id === questionId);
      if (!question) {
        return {};
      }

      if (!Array.isArray(question.answers) || question.answers.length === 0) {
        return {};
      }

      const correctAnswer = question.answers.find(answer => answer.is_correct === true);
      if (!correctAnswer) {
        return {};
      }

      return correctAnswer;
    },
    isWrongAnswer(answerId, question) {
      if (!question || !question.user_answer || !question.answers) return false;
      return question.user_answer === answerId && !question.answers.find(a => a.id === answerId)?.is_correct;
    },

    fetchSavedExerciseAnswers() {
      const exerciseSessionId = this.$route.params.exerciseSessionId;

      const { studentSavedExerciseAnswers, loading, error } = getStudentSavedExerciseAnswers(exerciseSessionId);

      this.$watch(
        () => error.value,
        (newVal) => {
          if (newVal) {
            this.exerciseError = handleErrors(newVal?.graphQLErrors || []);
          }
        },
        { immediate: true }
      );

      this.$watch(
        () => studentSavedExerciseAnswers.value,
        (newVal) => {
          if (newVal) {
            this.data = newVal || {};
            this.questions = this.data?.questions || [];
            this.direction = this.data?.direction || null;
          }
        },
        { immediate: true }
      );

      this.$watch(
        () => loading.value,
        (newVal) => {
          this.isLoading = newVal || false;
        },
        { immediate: true }
      );
    },

    onLoad() {
      this.fetchSavedExerciseAnswers();
    }
  },
  mounted() {
    this.onLoad();
  }
};
</script>
<style scoped>
.blur-content {
  text-wrap: nowrap;
}

.responsive-container {
  padding: 40px 0px;
}

.text-container {
  margin-top: 50px;

  @media (max-width: 425px) {
    margin-top: 60px;
  }
}

.buttons-holder {
  @media (max-width: 425px) {
    margin-top: 40px;
  }
}

/* Toggle Switch */
.toggle-switch {
  appearance: none;
  width: 40px;
  height: 20px;
  background: #ccc;
  border-radius: 20px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background 0.3s;
}

.toggle-switch:checked {
  background: #4caf50;
}

.toggle-switch::before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: white;
  border-radius: 50%;
  top: 1px;
  left: 1px;
  transition: transform 0.3s;
}

.toggle-switch:checked::before {
  transform: translateX(20px);
}
</style>
