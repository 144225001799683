import {gql} from "@apollo/client/core";

export const MARK_VIDEO_AS_COMPLETED = gql`
    mutation MarkVideoAsCompleted ($id : ID!) {
        markVideoCompleted( input: {
            id: $id
        }) {
            id
            name
            slug
        }
    }
`;