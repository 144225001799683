<template>
  <div>
    <svgicon
      :data="EyeIcon"
      :width="width"
      :height="height"
      original
      :class="style"
    />
  </div>
</template>

<script>
import EyeIcon from "@/assets/svg/eye-icon.svg";

export default {

  name: "EyeIcon",
  data() {
    return {
      EyeIcon,
    };
  },
  props: {
    width: {
        type: Number,
        default: 15
    },
    height: {
      type: Number,
      default: 15
    },
    style:{
      type: String,
      default: ''
    }
  },
};
</script>
