<template>
  <div>
    <LoadingSpinner :is-loading="isLoading" />
    <div class="flex flex-row gap-2 mb-2 mx-4 ">
      <button @click="showBlur" class="cursor-pointer">
        <svgicon :data="editing" width="15" height="15" original />
      </button>
      <div @click="showBlur" class="text-gray-500 font-bold-400 cursor-pointer">{{ section }}</div>
    </div>
    <Blur :coordinates="true" :phone="isMobile" ref="blurComponent" dir="ltr" :closeOnClickOutside="true" >
      <div class="blur-content" style="width: 327px;">
        <ClassMenu @select-item="changeSection" :menuItems="EducationSections" />
      </div>
    </Blur>
  </div>
</template>

<script>
import Blur from "@/components/StudyContent/Blur.vue";
import ClassMenu from "./ClassMenu.vue";
import editing from "@/assets/svg/editing.svg";
import { updateUserEducationSection } from "@/graphql/mutations/updateUserEducationSection/handlingCalls";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import windowSizeMixin from "@/mixins/windowSize";

export default {
  name: "BreadCrumb",
  mixins: [windowSizeMixin],
  data() {
    return {
      editing,
      isLoading: false,
    };
  },
  components: {
    LoadingSpinner,
    Blur,
    ClassMenu,
  },
  props: {
    section: {
      type: String,
      default: "Default Main Section",
    },
    EducationSections: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async changeSection(sectionId) {
      this.errors = [];
      try {
        const response = await this.$withLoading(() =>
          updateUserEducationSection(sectionId)
        );

        if (response.success === false) {
          this.errors = response.displayValidationMessages;
        } else {
          this.$emit("section-changed");
          this.$refs.blurComponent.hideBlur();
        }
      } catch (error) {
        console.error("Error adding to cart:", error);
      }
    },
    showBlur(event) {
      this.$refs.blurComponent.showBlur(event);
    },

    closeBlur() {},
  },
};
</script>

<style scoped></style>
