import {gql} from "@apollo/client/core";

export const UPDATE_USER_EDUCATION_SECTION = gql`
    mutation UpdateUserEducationSection(
        $education_section_id: Int!,
    ) {
        updateUserEducationSection(input: {
            education_section_id: $education_section_id
        }) {
            name
            email
            phone_number
            educationSection{
                id
                name
            }

        }


    }
`;
