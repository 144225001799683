<template>
  <button :class="computedClass" :style="computedStyle" class="flex justify-center align-center" @click="handleClick()">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ButtonCustom",
  props: {
    bgColor: {
      type: String,
    },
    className: {
      type: String,
      default: "py-2 px-10 rounded-full text-gray-font",
    },
    width: {
      type: String,
      default: "154px",
    },
    height: {
      type: String,
      default: "49px",
    },
  },
  computed: {
    computedClass() {
      return `${this.bgColor} ${this.className}`;
    },
    computedStyle() {
      return {
        width: this.width,
        height: this.height,
      };
    },

  },
  methods: {
    handleClick() {
      this.$emit("buttonCustomClicked");
    },
  },
};
</script>