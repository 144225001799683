import { gql } from '@apollo/client/core';

export const RELATED_USER_EDUCATION_SECTIONS = gql`
    query GetRelatedUserEducationSections{
        relatedUserEducationSections  {
            id
            name
            is_selected
            education_class_name
        }
    }
`;
