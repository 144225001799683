const arabicToEnglishMap = {
    '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4',
    '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9'
};

const convertArabicToEnglish = (str) => str.replace(/[٠-٩]/g, m => arabicToEnglishMap[m]);

const egyPhoneRegex = /^(010|011|012|015)\d{8}$/;
const egyInternationalPhoneRegex = /^\+201([0125])\d{8}$/;

const updateInputClass = (input, addClass, removeClass) => {
    if (input) {
        input.classList.remove(removeClass);
        input.classList.add(addClass);
    }
};


export const isValidPhoneForLogin = (value) => {
    const phoneInput = document.getElementById('phoneNumber');
    value = convertArabicToEnglish(value.trim());

    if (!value) {
        updateInputClass(phoneInput, 'badBorder', 'goodBorder');
        return 'الرجاء إدخال رقم الموبايل*';
    }

    if (!/^\d+$/.test(value)) {
        updateInputClass(phoneInput, 'badBorder', 'goodBorder');
        return 'الرجاء إدخال رقم موبايل صحيح*';
    }

    return true;
};

export const isValidPhone = (value) => {
    const phoneInput = document.getElementById('phoneNumber');
    value = convertArabicToEnglish(value.trim());

    if (!value) {
        updateInputClass(phoneInput, 'badBorder', 'goodBorder');
        return 'الرجاء إدخال رقم الموبايل*';
    }

    if (value[0] !== '+') {
        if (egyPhoneRegex.test(value)) {
            updateInputClass(phoneInput, 'goodBorder', 'badBorder');
            return true;
        }
        updateInputClass(phoneInput, 'badBorder', 'goodBorder');
        return "رقم الهاتف محلي مصري الصيغة الصحيحة هي 010xxxxxxxx*";
    }

    if (egyInternationalPhoneRegex.test(value)) {
        updateInputClass(phoneInput, 'goodBorder', 'badBorder');
        return true;
    }

    updateInputClass(phoneInput, 'badBorder', 'goodBorder');
    return value.startsWith('+20')
        ? "الهاتف دولي مصري برجاء إدخاله بشكل صحيح 201xxxxxxxxx+*"
        : true;
};

export const isValidParentPhone = (value) => {
    const phoneInput = document.getElementById('phoneNumber');
    value = convertArabicToEnglish(value.trim());

    if (!value) {
        return true;
    }

    if (value[0] !== '+') {
        if (egyPhoneRegex.test(value)) {
            updateInputClass(phoneInput, 'goodBorder', 'badBorder');
            return true;
        }
        updateInputClass(phoneInput, 'badBorder', 'goodBorder');
        return "*رقم الهاتف محلي مصري الصيغة الصحيحة هي 010xxxxxxxx";
    }

    if (egyInternationalPhoneRegex.test(value)) {
        updateInputClass(phoneInput, 'goodBorder', 'badBorder');
        return true;
    }

    updateInputClass(phoneInput, 'badBorder', 'goodBorder');
    return value.startsWith('+20')
        ? "*الهاتف دولي مصري برجاء إدخاله بشكل صحيح 201xxxxxxxxx+"
        : true;
};

export const isInternationalPhoneOtherThanEgypt = (value) => {
    value = convertArabicToEnglish(value.trim());

    if (value[0] !== '+') {
        return false;
    }

    if (egyInternationalPhoneRegex.test(value)) {
        return false;
    }

    return !value.startsWith('+20');
};
