import { gql } from '@apollo/client/core';

export const STUDENT_ENROLLMENT = gql` 
    query {
        studentCourseEnrollments {
            id
            expiry_date
            expires_in
            expires_in_discount
            order {
                id
            }
            product {
                id
                name
                currency
                duration_type
                is_renewable
                product_discount_type
                product_discount_amount
                price
            product_subscription_text
            product_discount_text
            product_price_text
            product_subscription_lower_text
            }
        }
    }
`;
