export const filters = [
  {
    label: "All",
    key: "ALL",
  },
  {
    label: "First Term",
    key: "FIRST_TERM",
  },
  {
    label: "Second Term",
    key: "SECOND_TERM",
  },
];

export const arabicFilters = [
  {
    label: "الكل",
    key: "ALL",
  },
  {
    label: "التيرم الاول",
    key: "FIRST_TERM",
  },
  {
    label: "التيرم الثانى",
    key: "SECOND_TERM",
  },
];

export function getLocalizedFilters(direction) {
  return direction === "RTL" ? arabicFilters : filters;
}
