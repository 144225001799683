<template>
  <div
      class="w-[324px] border-2 md:w-[440px] min-h-[611px] pb-4 bg-white rounded-[10px] flex flex-col items-center mt-[8px] md:mt-[21px] mb-6">
          <CardHeader v-if="name" :name="name"/>
    <div class="w-[90%] flex flex-col justify-center items-center mt-4">
      <CardFeatures v-if="features" :features="features"/>
      <div v-if="products && products.length > 0">
        <div v-for="product in products" :key="product.id">
          <CardProduct :product="product" class=""/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CardHeader from '@/components/Subscriptions/SubscriptionCard/CardHeader.vue';
import CardFeatures from '@/components/Subscriptions/SubscriptionCard/CardFeatures.vue';
import CardProduct from '@/components/Subscriptions/SubscriptionCard/CardProduct.vue';

export default {
  name: 'SubscriptionCard',
  components: {
    CardHeader,
    CardFeatures,
    CardProduct
  },
  props: {
    service: {
      type: Object,
    }
  },
  data() {
    return {
      name: this.service.name || '',
      features: this.service.features || '',
      products: this.service.products || '',
    }
  },
}
</script>