<template>
  <span class="text-[#1FB865] text-[14px] md:text-[17px] font-medium">
    خصم  {{productDiscountAmount}}<span v-if="productDiscountType !== 'FIXED'">%</span>
    <span v-else>جنيه</span> لو جددت قبل {{expiresInDiscount}}</span>
</template>
    
<script>
  export default {
    name: 'ExpirationMessage',
    props:{
      productDiscountType: {
        type: String,
      },
      productDiscountAmount:{
        type: [String , Number],
      },
      expiresInDiscount:{
        type: String,
      }
    },
  } 
</script>