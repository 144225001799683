<template>
  <div class="flex flex-row justify-between p-1 align-center text-blue-custom b-heading w-full">
    <svgicon :data="BurgerIcon" width="15" height="11" original class="mx-2 my-1"/>
    <span class="mx-2">اختر المحتوى الدراسى</span>
  </div>
  <div class="w-full px-2">
    <ul class="menu-list">
      <li v-for="(item, index) in menuItems" :key="index" class="menu-item text-right pb-1">
        <div class="menu-body">
          <hr class="menu-divider"/>
          <div @click="selectItem(item)" class="mt-2 text-zinc-500 cursor-pointer mr-[3%]"
               :class="item.is_selected ? 'font-bold-700' : 'hover:text-blue-custom transition-colors duration-300'">
            {{ getEducationSectionName(item) }}
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import BurgerIcon from "@/assets/svg/burger-blue.svg";
import { updateUserEducationSection } from "@/graphql/mutations/updateUserEducationSection/handlingCalls";

export default {
  emits: ['select-item'],
  name: "ClassMenu",
  data() {
    return {
      BurgerIcon,
      updateUserEducationSection
    };
  },
  components: {},
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getEducationSectionName(item) {
      if (!item) return '';
      return  item?.name;
    },
    selectItem(item) {
      this.$emit("select-item", item.id);
    }
  },
};
</script>

<style scoped>
.b-heading {
  border: 1px solid #1c9deb;
  border-radius: 10px 10px 0 0;
}

.menu-list {
  max-height: 290px;
  overflow-y: auto;
}

.cursor-disabled {
  cursor: not-allowed;
}
</style>
