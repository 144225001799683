import CryptoJS from 'crypto-js';

const secretPassphrase = process.env.VUE_APP_STUDENT_COOKIE_ENCRYPTION_KEY;
  
const cookieEncryption = {
    encrypt(data) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), secretPassphrase).toString();
    },
    decrypt(encryptedData) {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedData, secretPassphrase);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        } catch (error) {
            console.error('Error decrypting cookie data', error);
            return null;
        }
    },
    setCookie(cookies, name, data, expirationDays) {
        const encryptedData = this.encrypt(data);
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + expirationDays);
        cookies.set(name, encryptedData, { expires: expirationDate });
    },
    getCookie(cookies, name) {
        const encryptedData = cookies.get(name);
        return encryptedData ? this.decrypt(encryptedData) : null;
    },
    deleteCookie(cookies, name) {
        cookies.remove(name);
    },
    updateCookie(cookies, name, data, expirationDays) {
        const existingData = this.getCookie(cookies, name) || {};
        const updatedData = { ...existingData, ...data };
        this.setCookie(cookies, name, updatedData, expirationDays);
    },
    removeFromCookie(cookies, name, key) {
        const existingData = this.getCookie(cookies, name);
        if (existingData && Object.prototype.hasOwnProperty.call(existingData, key)) {
            delete existingData[key];
            this.setCookie(cookies, name, existingData);
        }
    }
};

export default cookieEncryption;