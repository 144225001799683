export default {
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        isMobile() {
            return this.windowWidth < 769;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },

}