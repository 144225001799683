<template>

  <div class="mt-[3%] w-8/12 flex flex-col justify-center items-center mx-auto">
    <BackButton class="flex absolute top-[9.5%] left-[85%] md:absolute md:top-[13.15%] md:left-[66.3%]" />

    <div class=" bg-[#f4f6f8] flex flex-col items-center gap-3">

      <ActivationCodePrompt />
      <PhoneVerificationMessage :registeredPhone="phoneNumber" />

      <ChangeNumberLink :to="{ name: 'Register' }" />

      <div class="w-1/2 flex flex-col content-center justify-center items-center flex-wrap">

        <form class="w-[284px] h-[254px] md:h-[465px] top-[44.625%] md:top-[40.60%] left-auto" action=""
          @submit="onSubmit">

          <div class="mb-4">
            <VerificationCodeInput v-model:codes="codes" :reset="resetInputs" :hasError="verificationError" />

            <CountdownResend @resend="resendOTP" :phoneNumber="phoneNumber" />
          </div>

          <BlueButton
            :customClass="' bg-blue-custom text-white text-[19px] font-bold rounded-[10px] w-[284px] top-[84.5%] md:top-[88.25%] h-[54px] m-auto flex justify-center items-center'"
            id="submitBtn" type="submit">
            تأكيد الكود
          </BlueButton>
        </form>

      </div>
    </div>
  </div>

</template>

<script>
import BackButton from "@/components/FormComponents/BackButton.vue";
import ActivationCodePrompt from "@/components/FormComponents/ActivationCodePrompt.vue";
import PhoneVerificationMessage from "@/components/FormComponents/PhoneVerificationMessage.vue";
import ChangeNumberLink from "@/components/FormComponents/ChangeNumberLink.vue";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import VerificationCodeInput from "@/components/FormComponents/VerificationCodeInput.vue";
import cookieEncryption from "@/helpers/cookieEncryption";
import CountdownResend from "@/components/FormComponents/CountdownResend.vue";
import { otpCookie } from '@/helpers/OTPCookie';
import routes from '@/router/routes.js';

export default {
  name: 'signUpPhoneVerification',
  components: {
    CountdownResend,
    VerificationCodeInput,
    BlueButton,
    PhoneVerificationMessage,
    ActivationCodePrompt,
    BackButton,
    ChangeNumberLink
  },
  data() {
    return {
      codes: ['', '', '', '', '', ''],
      phoneNumber: '',
      resetInputs: false,
      verificationError: false


    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      const enteredCode = this.codes.join('');
      const { sentOTP = '' } = otpCookie.getOTP(this.$cookies, this.phoneNumber) || {};

      if (enteredCode && sentOTP && enteredCode === sentOTP) {
        this.verificationError = false;


        otpCookie.setOTP(this.$cookies, this.phoneNumber, {
          'isValidSentOTP': true,
        });

        routes.navigateTo(routes.REGISTER_ACCOUNT_INFO.key);
      } else {
        this.verificationError = true;
      }

    },

    resendOTP() {

      this.resetInputs = true;
      this.verificationError = false;
      this.$nextTick(() => {
        this.resetInputs = false;
      });

    },
    onLoad() {

      const { studentPhone = '' } = cookieEncryption.getCookie(this.$cookies, 'elkhetaRegistrationData') || {};

      this.phoneNumber = studentPhone;

    },
  },


  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.onLoad();
    next();
  },

}

</script>

<style scoped></style>