<template>
  <div class="flex flex-column justify-center mb-5">
    <div class="flex justify-around" dir="ltr">
      <input
          v-for="(code, index) in localCodes"
          :key="index"
          type="text"
          :ref="'codeInput' + index"
          :class="['text-center', 'inputBorderStyle', 'w-[41px]', 'h-[49px]', 'rounded-[15px]', 'font-bold', 'text-[30px]', { 'border-error': hasError }]"
          v-model="localCodes[index]"
          :id="'code' + (index + 1)"
          maxlength="1"
          pattern="[0-9]"
          @input="focusNext(index)"
          @keydown="handleBackspace(index, $event)"

      >
    </div>
    <div
        :class="['mt-[24px]', 'text-[12px]' ,'text-[#C50000]', 'validation-error', { 'hidden': !hasError }]"

         id="send-otp-error">
      أدخلت كود خاطئ حاول مرة أخرى*
    </div>
  </div>
</template>

<script>


export default {
  name: 'VerificationCodeInput',
  props: {
    codes: {
      type: Array,
      default: () => ['', '', '', '', '', '']
    },
    reset: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },

  },
  data() {
    return {
      localCodes: [...this.codes],

    };
  },
  watch: {

    localCodes:{
      handler(newVal) {
        this.$emit("update:codes", newVal);
      },
      deep: true
    },
    reset(newVal) {
      if (newVal) {
        this.clearInputs();
      }
    },
    hasError(newVal) {
      if (newVal) {
        this.focusFirstInput();
      }
    }
  },
  methods: {

    focusFirstInput() {
      this.$nextTick(() => {
        const firstInput = this.$refs.codeInput0[0];
        if (firstInput) {
          firstInput.focus();
        }
      });
    },


    focusNext(index) {

      if (index < this.codes.length - 1) {
        this.$nextTick(() => {
          const nextInput = this.$refs['codeInput' + (index + 1)][0];
          if (nextInput) {
            nextInput.focus();
          }
        });

      }
    },

    handleBackspace(index, event) {
      if (event.key === 'Backspace') {
        if (this.localCodes[index] === '' && index > 0) {
          this.localCodes[index - 1] = '';
          this.$nextTick(() => {
            const previousInput = this.$refs['codeInput' + (index - 1)][0];
            if (previousInput) {
              previousInput.focus();
            }
          });
        }
      }
    },

    clearInputs() {
      this.localCodes = ['', '', '', '', '', ''];

      this.focusFirstInput();
    },


  }
};
</script>

<style scoped>
.inputBorderStyle {
  @apply border-solid border-gray-300;
}

.border-error {
  @apply border-[1px] border-solid border-[#EC1212] text-[22px];
}

</style>
