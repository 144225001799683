<template>
    <div>
        <div v-if="isRight">
            <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.62999 17.26C13.3962 17.26 17.26 13.3962 17.26 8.62999C17.26 3.86378 13.3962 0 8.62999 0C3.86378 0 0 3.86378 0 8.62999C0 13.3962 3.86378 17.26 8.62999 17.26Z"
                    fill="#1C9DEB" />
                <path
                    d="M8.63005 13.5839C7.6489 13.5839 6.72645 13.2018 6.03269 12.508L2.90906 9.38443C2.2559 8.73127 2.2559 7.67232 2.90906 7.01915C3.56216 6.36606 4.62111 6.36606 5.27434 7.01915L8.39797 10.1428C8.47746 10.2223 8.56807 10.2389 8.63005 10.2389C8.69202 10.2389 8.78263 10.2223 8.86213 10.1428L17.1449 1.86C17.7981 1.2069 18.857 1.2069 19.5102 1.86C20.1634 2.51317 20.1634 3.57211 19.5102 4.22528L11.2274 12.508C10.5336 13.2018 9.6112 13.5839 8.63005 13.5839Z"
                    fill="url(#paint0_linear_311_3397)" stroke="#1C9DEB" />
                <defs>
                    <linearGradient id="paint0_linear_311_3397" x1="7.87052" y1="2.28305" x2="16.4859" y2="10.8984"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#DADADA" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
        <div v-else>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.62999 17.26C13.3962 17.26 17.26 13.3962 17.26 8.62999C17.26 3.86378 13.3962 0 8.62999 0C3.86378 0 0 3.86378 0 8.62999C0 13.3962 3.86378 17.26 8.62999 17.26Z"
                    fill="#EC1212" />
                <path
                    d="M1.35355 14.9393C0.767767 15.5251 0.767767 16.4749 1.35355 17.0607C1.93934 17.6464 2.88909 17.6464 3.47487 17.0607L16.7477 3.78785C17.3335 3.20206 17.3335 2.25231 16.7477 1.66653C16.1619 1.08074 15.2122 1.08074 14.6264 1.66653L1.35355 14.9393Z"
                    fill="white" stroke="#EC1212" />
                <path
                    d="M3.06066 1.35355C2.47487 0.767767 1.52513 0.767767 0.93934 1.35355C0.353553 1.93934 0.353555 2.88909 0.939341 3.47487L14.2122 16.7477C14.7979 17.3335 15.7477 17.3335 16.3335 16.7477C16.9193 16.1619 16.9193 15.2122 16.3335 14.6264L3.06066 1.35355Z"
                    fill="white" stroke="#EC1212" />
                <path
                    d="M1.70711 16.7071C1.31658 16.3166 1.31658 15.6834 1.70711 15.2929L14.9799 2.02008C15.3704 1.62956 16.0036 1.62956 16.3941 2.02008C16.7847 2.4106 16.7847 3.04377 16.3941 3.43429L3.12132 16.7071C2.7308 17.0976 2.09763 17.0976 1.70711 16.7071Z"
                    fill="white" />
            </svg>

        </div>
    </div>
</template>

<script>

export default {
    name: 'RightAndWrongIcons',
    props: {
        isRight: {
            type: Boolean
        }
    }
}
</script>