import {getContactUsURL} from "@/graphql/queries/contactUs/handlingCalls";

export default {
    data(){
        return {
            redirectContactUsUrl: '',
            isLoadingContactUs: false,
        }
    },
    methods: {
        handleContactUsClick(event) {
            event.preventDefault();
            event.stopPropagation();

            const {contactUsURL, loading} = getContactUsURL();

            this.$watch(
                () => contactUsURL.value,
                (newVal) => {
                    this.redirectContactUsUrl = newVal?.url || '';

                    if (this.$isNotEmpty(this.redirectContactUsUrl)){
                        window.open(this.redirectContactUsUrl, '_blank');
                    }
                },
                {immediate: true}
            );

            this.$watch(
                () => loading.value,
                (newVal) => {
                    this.isLoadingContactUs = newVal;
                },
                {immediate: true}
            );
        },
    },

}