import { gql } from "@apollo/client/core";

export const navigationFragment = gql`
    fragment LessonContentNavigation on LessonContentNavigation {
        on_close
        on_next
        next_type
        on_previous
        previous_type
        is_valid_next
        is_valid_previous
        current_index
        total_count
        course_slug
    }
`;