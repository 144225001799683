<template>
  <button :class="computedClass" @click="$emit('click' , $event)">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'FromBackButton',
  props: {
    customClass: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    computedClass() {
      return this.customClass ? this.customClass : 'bg-[#1C9DEB] text-white text-lg font-bold mb-3 rounded-lg w-[284px] h-[56px] m-auto flex justify-center items-center';
    }
  }
};
</script>
