<template>
    <div class="img-container">
      <img v-if="isMobile" :src="phoneImg" class="phone-img" width="316" height="341" />
      <img v-else :src="PhoneLogoDesktop" class="desktop-img" width="764" height="387" />
    </div>
  </template>
  
  <script>
  import phoneImg from "@/assets/images/landing/phone-small.png";
  import PhoneLogoDesktop from "@/assets/images/landing/phone.png";
  import windowSize from '@/mixins/windowSize';

  export default {
    mixins: [windowSize],
    name: "PhoneLogo",
    data() {
      return {
        phoneImg,
        PhoneLogoDesktop,
      };
    },
    methods: {
    },
  };
  </script>
  
  <style scoped>

  </style>
  