import cookieEncryption from './cookieEncryption.js';

const OTP_COOKIE_NAME = 'elkhetaOTP';
const DEFAULT_EXPIRATION_MINUTES = 60;
const COOKIE_EXPIRATION_DAYS = 1;

export const otpCookie = {

    getDefaultExpirationInMinutes() {
        return DEFAULT_EXPIRATION_MINUTES;
    },
    setOTP(cookies, phoneNumber, otpData, expirationMinutes = DEFAULT_EXPIRATION_MINUTES ) {
        const existingData = cookieEncryption.getCookie(cookies, OTP_COOKIE_NAME) || {};
        const expirationDate = new Date();
        expirationDate.setMinutes(expirationDate.getMinutes() + expirationMinutes);

        const updatedData = {
            ...existingData,
            [phoneNumber]: {
                ...existingData[phoneNumber],
                ...otpData,
                expirationDate: expirationDate.toISOString()
            }
        };

        cookieEncryption.setCookie(cookies, OTP_COOKIE_NAME, updatedData, COOKIE_EXPIRATION_DAYS);

    },

    getOTP(cookies, phoneNumber , takeExpirationIntoConsideration = true) {
        const cookieData = cookieEncryption.getCookie(cookies, OTP_COOKIE_NAME);
        if (cookieData && cookieData[phoneNumber]) {
            const { expirationDate, ...otpData } = cookieData[phoneNumber];

            if (takeExpirationIntoConsideration){
                const currentDate = new Date();
                const expiration = new Date(expirationDate);

                if (currentDate <= expiration) {
                    return otpData;
                } else {
                    this.deleteOTP(cookies, phoneNumber);
                    return null;
                }
            }

            return otpData;
        }
        return null;
    },

    deleteOTP(cookies, phoneNumber) {
        cookieEncryption.removeFromCookie(cookies, OTP_COOKIE_NAME, phoneNumber);
    },

    deleteOTPKey(cookies, phoneNumber, key) {
        const existingData = cookieEncryption.getCookie(cookies, OTP_COOKIE_NAME);
        if (existingData && existingData[phoneNumber] && Object.prototype.hasOwnProperty.call(existingData[phoneNumber], key)) {
            delete existingData[phoneNumber][key];
            cookieEncryption.setCookie(cookies, OTP_COOKIE_NAME, existingData);
        }
    },

    deleteOTPValidity(cookies, phoneNumber) {
        this.deleteOTPKey(cookies, phoneNumber, 'isValidSentOTP');
    },

    deleteOTPValue(cookies, phoneNumber) {
        this.deleteOTPKey(cookies, phoneNumber, 'sentOTP');
    },

    deleteAllOTP(cookies) {
        cookieEncryption.deleteCookie(cookies, OTP_COOKIE_NAME);
    },

    getEntireCookie(cookies) {
        return cookieEncryption.getCookie(cookies, OTP_COOKIE_NAME);
    }
};

export const clearRequiredOTPValidation = {
    clear(route , router )  {
        if (route.query.requiredOTPValidation) {
            router.replace({ query: null });
        }
    }
}

