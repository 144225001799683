<template>
  <div>
    <div class="text-container text-base mb-[20px]">
      {{ title }}
    </div>
    <button class="px-4 rounded-full mt-2">
      <div class="flex flex-col gap-0 items-center text-blue-custom underline">
        <div v-for="prerequisite in prerequisites" :key="prerequisite">
          <a @click="redirectToPrerequisites(prerequisite?.type , prerequisite?.slug)">{{ prerequisite?.name }}</a>
        </div>


      </div>
    </button>
  </div>
</template>

<script>
import routeParamsMixin from "@/mixins/routeParams";
import routes from "@/router/routes";

export default {
  mixins: [routeParamsMixin],

  name: "PrerequisitesData",
  props: {
    title: {
      type: String,
      required: true,
    },
    prerequisites: {
      type: Object,
      required: true,
    },
  },
  methods: {
    redirectToPrerequisites(type  , slug) {
      if (type === 'Exercise'){
        routes.navigateToExercise(this.lessongSlug , slug)
      }else if (type === 'Video'){
        routes.navigateToVideo(this.lessongSlug , slug)
      }else{
        routes.navigateTo(routes.STUDY_CONTENT.key)
      }
    }
  }
}
</script>