<template>
  <p class="login-start-and-log" id="loginStartAndLog">
    أهــــــــــــــــــــــــــــــلا <br /> و ابـــــــــــــــــــــــــدأ
  </p>
</template>

<script>
export default {
  name: 'StartAndLog'
};
</script>

<style scoped>
.login-start-and-log {
  @apply text-[50px] font-bold top-[150px] text-black-custom absolute m-auto;
}


</style>
