<template>
  <FormField
      inputType="password"
      name="confirmPassword"
      :rules="isPasswordDuplicate"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      id="confirmPassword"
      :placeholder="placeholder"
  />
</template>

<script>
import { isPasswordDuplicate } from '@/validations/isPasswordDuplicate';
import FormField from "@/components/FormComponents/FormField.vue";

export default {
  name: 'ConfirmPasswordInput',
  components: {FormField},
  props: {
    modelValue: {
      type: [String, Number],
      required: true
    },
    password: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'دخل الباسوورد كمان مرة للتأكيد'
    },
  },
  methods: {
    isPasswordDuplicate(value) {
      return isPasswordDuplicate(value, this.password);
    }
  },
};
</script>


