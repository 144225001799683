import { useMutation } from '@vue/apollo-composable';
import { LOGOUT_USER} from '@/graphql/mutations/logout/logout';
import {handleResponse} from "@/helpers/graphqlResponseHandler";

export const logout = (async () => {

    const { mutate } = useMutation( LOGOUT_USER,
        {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
        })

    try {
        const response = await mutate();
        return handleResponse(response , 'logoutUser');

    } catch (error) {
        console.error('Error adding data:', error);
    }

})