<template>
    <span v-if="productTitle" class="font-bold text-[16px] md:text-[19px] mt-[8px] mb-[6px]">{{ productTitle }}</span>
</template>
    
<script>
  export default {
    name: 'CardProductTitle',
    props:{
      productTitle: {
        type: String,
      }
    },
  }
</script>