<template>

  <div class="min-h-screen w-full">
    <LoadingSpinner :is-loading="isEducationClassesLoading || isCourseClassesLoading || isLoading" />
    <div class="  ">

      <div class="w-full h-full d-flex flex-col content-center items-center justify-center" dir="rtl">

        <div class="w-[324px] md:w-[672px] absolute top-[12.25%] left-auto md:top-[13.8%] md:mb-[161px] md:flex md:justify-center">
          <ProgressBar progress="50"/>
        </div>


        <Form class="w-[284px] md:w-[700px] absolute left-auto top-[26.5%] md:top-[35.9%] mx-auto h-[488px]" @submit="onSubmit" action="">


          <div class="w-full">
            <div class="flex flex-column justify-center mb-[0px]">

              <SelectField
                  :rules="educationClassRules"
                  name="eduClass"
                  id="educationClass"
                  v-model="eduClass"
                  :options="educationClasses"
                  :validateOnModelUpdate="false"
                  :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle mb-[25px] pr-[28px] p-2 w-[284px] md:w-[375px] h-[54px] m-auto rounded-[10px]'"
                  placeholder="اختر السنة الدراسية"
                  @optionSelected="onOptionSelected('eduClass')"
                  :allow-empty="false"
              />
            </div>
          </div>

          <div class="w-full">
            <div class="form-check flex flex-column justify-center mb-[0px]">

              <SelectField
                  :rules="educationSectionRules"
                  name="eduSection"
                  id="educationSection"
                  v-model="eduSection"
                  :options="educationSections"
                  :validateOnModelUpdate="false"
                  :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle mb-[25px] pr-[28px] p-2 w-[284px] md:w-[375px] h-[54px] m-auto rounded-[10px]'"
                  placeholder="اختر القسم الدراسي"
                  :allow-empty="false"
              />
            </div>
          </div>

          <div class="w-full">
            <div class="form-check flex flex-column justify-center mb-[0px]">

              <SelectField
                  :rules="secondaryLanguageRules"
                  name="eduSecondaryLanguage"
                  id="eduSecondaryLanguage"
                  v-model="eduSecondaryLanguage"
                  :options="secondaryLanguages"
                  :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle mb-[25px] pr-[28px] p-2 w-[284px] md:w-[375px] h-[54px] m-auto rounded-[10px]'"
                  placeholder="اختر اللغة الثانية"
                  :allow-empty="true"
              />
            </div>
          </div>

          <div class="w-full">
            <div class="form-check flex flex-column justify-center mb-[0px]">

              <SelectField
                  :rules="religionSubjectsRules"
                  name="eduReligionSubject"
                  id="eduReligionSubject"
                  v-model="eduReligionSubject"
                  :options="religionSubjects"
                  :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle mb-[25px] pr-[28px] p-2 w-[284px] md:w-[375px] h-[54px] m-auto rounded-[10px]'"
                  placeholder="اختر مادة التربية الدينية"
                  :allow-empty="true"
              />
            </div>
          </div>


          <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
        <span v-for="(error, index) in errors" :key="index">
          {{ error }}
        </span>
          </div>


          <BlueButton
              :customClass="'bg-blue-custom text-white text-[19px] font-bold rounded-[10px] w-[284px] h-[54px] flex justify-center items-center absolute top-[89.3%] md:top-[434px] left-[0px] md:left-[30%]'"
              id="submitBtn"
              type="submit">
            التالي
          </BlueButton>
          <FromBackButton
              :customClass="'text-black text-[19px] font-medium  mb-[50px] w-[284px]  md:w-[375px] h-[54px] m-auto absolute top-full md:top-[490px] md:left-[23.4%] left-[0%]'"
              id="formBackButtonAccountInfo"
              @click="handleGoBack"
              type="submit">
            رجوع
          </FromBackButton>
        </Form>



      </div>
    </div>
  </div>

</template>

<script>
import {Form} from 'vee-validate';
import {getAllCourseCLasses} from '@/graphql/queries/courseClasses/handlingCalls'
import {completeRegisterUserProfile} from '@/graphql/mutations/register/handlingCalls';
import {mapActions} from 'vuex'
import ProgressBar from "@/components/FormComponents/ProgressBar.vue";
import SelectField from "@/components/FormComponents/SelectField.vue";
import {isValidEducationClass} from "@/validations/isValidEducationClass";
import {isValidEducationSection} from "@/validations/isValidEducationSection";
import {isValidSecondaryLanguage} from "@/validations/isValidSecondaryLanguage";
import {isValidReligionSubject} from "@/validations/isValidReligionSubject";
import cookieEncryption from "@/helpers/cookieEncryption";
import {getEducationClasses} from "@/graphql/queries/educationClasses/handlingCalls";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import FromBackButton from "@/components/FormComponents/FormBackButton.vue";
import routes from "@/router/routes";
import {isValidParentPhone} from "@/validations/isValidPhone";
// import {isValidPassword} from "@/validations/isValidPassword";
// import {isPasswordDuplicate} from "@/validations/isPasswordDuplicate";
import {isValidFirstName} from "@/validations/isValidFirstName";
import {isValidLastName} from "@/validations/isValidLastName";
import {otpCookie} from "@/helpers/OTPCookie";
// import VueCookies from "vue-cookies";

export default {
  name: 'signUpEducationInfo',
  components: {
    FromBackButton,
    BlueButton,
    SelectField,
    ProgressBar,
    Form,
  },
  data() {
    return {
      eduClass: '',
      educationClasses: [],
      eduSection: '',
      educationSections: [],
      eduSecondaryLanguage: '',
      secondaryLanguages: [],
      eduReligionSubject: '',
      religionSubjects: [],

      isEducationClassesLoading: false,
      isCourseClassesLoading: false,
      isLoading: false,


      errors: [],

    }
  },
  computed: {
    educationClassRules() {
      return (value) => isValidEducationClass(value, this.educationClasses, this.eduClass);
    },
    educationSectionRules() {
      return (value) => isValidEducationSection(value, this.educationClasses, this.eduClass);
    },
    secondaryLanguageRules() {
      return (value) => isValidSecondaryLanguage(value, this.secondaryLanguages, this.eduSecondaryLanguage);
    },
    religionSubjectsRules() {
      return (value) => isValidReligionSubject(value, this.religionSubjects, this.eduReligionSubject);
    },
  },

  methods: {
    ...mapActions({
      registerUser: 'Auth/registerUser',
      updateIsCompletedProfile: 'Auth/updateIsCompletedProfile'
    }),
    isValidEducationClass,
    isValidEducationSection,
    isValidSecondaryLanguage,
    isValidReligionSubject,
    fetchEducationClasses() {
      const {studentEducationStage} = cookieEncryption.getCookie(this.$cookies, "elkhetaRegistrationData") || {};


      const {educationClasses, loading} = getEducationClasses(studentEducationStage);

      this.$watch(
          () => educationClasses.value,
          (newVal) => {
            this.educationClasses = newVal;
          },
          {immediate: true}

      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.isEducationClassesLoading = newVal;
            this.checkAndSetDefaultValues();

          },
          {immediate: true}

      );

    },
    fetchCourseClasses() {

      const {secondaryLanguageClasses, religionClasses, loading} = getAllCourseCLasses();

      this.$watch(
          () => secondaryLanguageClasses.value,
          (newVal) => {
            this.secondaryLanguages = newVal;
          },
          {immediate: true}

      );

      this.$watch(
          () => religionClasses.value,
          (newVal) => {
            this.religionSubjects = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.isCourseClassesLoading = newVal;

            this.checkAndSetDefaultValues();

          },
          {immediate: true}
      );

    },
    checkAndSetDefaultValues() {
      if (!this.isCourseClassesLoading && !this.isEducationClassesLoading) {
        this.setDefaultValues();
      }
    },
    setDefaultValues() {

      const defaultFields = [
        {field: 'eduClass', value: '', callback: 'eduClass'},
        {field: 'eduSection', value: ''},
        {field: 'eduSecondaryLanguage', value: ''},
        {field: 'eduReligionSubject', value:''}
      ];


      defaultFields.forEach(({field, value, callback}) => {
        this[field] = value || '0';
        if (callback && value) {
          this.onOptionSelected(callback, false);
        }
      });


    },
    onOptionSelected(field, clearState = true) {
      switch (field) {
        case 'eduClass':
          if (clearState) {
            this.resetFields('educationSections');
          }

          if (this.eduClass) {

            const selectedClass = this.educationClasses.find(
                (type) => type.id === this.eduClass
            );
            this.educationSections = selectedClass ? selectedClass.educationSections : [];

          } else {
            this.educationSections = [];
          }
          break;
      }
    },
    resetFields(startField) {
      switch (startField) {
        case 'educationSections':
          this.eduSection = '0';
          this.educationSections = [];
          break;
      }
    },
    handleGoBack() {
      routes.navigateTo(routes.REGISTER_ACCOUNT_INFO.key);

    },

    async onSubmit() {


      const {
        studentFirstName = '',
        studentLastName = '',
        studentParentPhone = ''
      } = cookieEncryption.getCookie(this.$cookies, "elkhetaRegistrationData") || {};

      // const {isValidSentOTP = false} = otpCookie.getOTP(VueCookies, studentPhone) || {};

      this.errors = [];

      const validations = [
        // {validation: () => isValidPhone(studentPhone)},
        // {validation: () => isValidPassword(studentP)},
        // {validation: () => isPasswordDuplicate(studentPC, studentP)},
        {validation: () => isValidFirstName(studentFirstName)},
        {validation: () => isValidLastName(studentLastName)},
        {validation: () => isValidParentPhone(studentParentPhone)},
        {validation: () => isValidEducationSection(this.eduSection, this.educationClasses, this.eduClass)},
        {validation: () => isValidSecondaryLanguage(this.eduSecondaryLanguage, this.secondaryLanguages, this.eduSecondaryLanguage)},
        {validation: () => isValidReligionSubject(this.eduReligionSubject, this.religionSubjects, this.eduReligionSubject)}
      ];


      validations.forEach(({validation}) => {
        const result = validation();
        if (result !== true) {
          this.errors.push(result);
        }
      });

      // if (isValidSentOTP !== true) {
      //   this.errors.push('*يجب تأكيد رقم الهاتف.');
      // }


      const userData = {
        first_name: studentFirstName,
        last_name: studentLastName || '',
        parent_phone_number: studentParentPhone || '',
        education_section_id: this.eduSection,
        ...(this.eduSecondaryLanguage && this.eduSecondaryLanguage !== '0' && this.eduSecondaryLanguage !== 0 && {course_class_sl_id: this.eduSecondaryLanguage}),
        ...(this.eduReligionSubject && this.eduReligionSubject !== '0' && this.eduReligionSubject !== 0 && {course_class_r_id: this.eduReligionSubject}),
      };


      let registerResponse =  await this.$withLoading(() =>  completeRegisterUserProfile(userData));

      if (registerResponse.success === false) {
        this.errors = registerResponse.displayValidationMessages;
      } else {
        
        const isCompletedProfile = registerResponse.data.is_completed_profile;
        await this.updateIsCompletedProfile(isCompletedProfile, this.$cookies);        
        cookieEncryption.deleteCookie(this.$cookies, 'elkhetaRegistrationData')
        otpCookie.deleteAllOTP(this.$cookies);
        routes.navigateTo(routes.REGISTER_DONE.key);
      }
    },
    onLoad() {

      this.fetchEducationClasses();
      this.fetchCourseClasses();


    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {

    this.onLoad();
    next();
  },

}

</script>
