import { useMutation } from '@vue/apollo-composable';
import {handleResponse} from "@/helpers/graphqlResponseHandler";
import {UPDATE_USER_PHONE_NUMBER} from "@/graphql/mutations/updateUserPhoneNumber/updateUserPhoneNumber";


export const updateUserPhoneNumber = (async (phoneNumber) => {

    const { mutate } = useMutation(UPDATE_USER_PHONE_NUMBER, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            phone_number: phoneNumber,
        },
    });


    try {
        const response = await mutate();


        return handleResponse(response , 'updateUserPhoneNumber');



    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }

})