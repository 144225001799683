import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";
import {ASK_TEACHERS} from "@/graphql/queries/askTeachers/askTeachers";

export const getAskTeachers =  () => {

    const { result, loading, error } = useQuery(ASK_TEACHERS , null ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const askTeachersContent = computed(() => result.value?.askTeachers ?? []);


    return {
        askTeachersContent,
        loading,
        error
    }

};