import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";
import {STUDY_CONTENT} from "@/graphql/queries/studyContent/studyContent";
import {STUDY_CONTENT_TAB} from "@/graphql/queries/studyContent/studyContentTab";
import {COURSE_CONTENT} from "@/graphql/queries/studyContent/courseContent";
import {LESSON_CONTENT} from "@/graphql/queries/studyContent/lessonContent";

export const getStudyContent =  (fetchPolicy) => {


    const { result, loading, error } = useQuery(STUDY_CONTENT, null, {
        fetchPolicy: fetchPolicy,
        errorPolicy: 'all'
    })

    const studyContent = computed(() => result.value?.studyContent ?? []);

    return {
        studyContent,
        loading,
        error
    }
}

export const getStudyContentTab =  (tabId , fetchPolicy) => {


    const { result, loading, error } = useQuery(STUDY_CONTENT_TAB
        ,
        {
            id : parseInt(tabId)
        },
        {
            fetchPolicy: fetchPolicy,
            errorPolicy: 'all'
        }
    )

    const studyContentTab = computed(() => result.value?.studyContentTab ?? []);

    return {
        studyContentTab,
        loading,
        error
    }
}
export const getCourseContent =  (slug,fetchPolicy) => {


    const { result, loading, error } = useQuery(COURSE_CONTENT
        ,
        {
            slug : slug
        },
        {
            fetchPolicy: fetchPolicy,
            errorPolicy: 'all'
        }
    )

    const courseContent = computed(() => result.value?.courseContent ?? []);

    return {
        courseContent,
        loading,
        error
    }
}


export const getLessonContent =  (slug) => {


    const { result, loading, error } = useQuery(LESSON_CONTENT
        ,
        {
            slug : slug
        }
        ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        }
    )

    const lessonContent = computed(() => result.value?.lessonContent ?? []);

    return {
        lessonContent,
        loading,
        error
    }
}
