
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const  isValidEmail = (value) => {
    let emailInput = document.getElementById('email');

    if (!(value && value.trim())) {
        emailInput?.classList.remove('goodBorder');

        return true;
    }


    if (!emailRegex.test(value)) {
        emailInput?.classList.remove('goodBorder');
        return "*الرجاء إدخال إيميل صحيح xxxx@xxxx.com";
    }

    if (emailInput){
        emailInput.classList.add('goodBorder');
    }

    return true;
}

export const  isValidEmailCheckout = (value) => {
    let emailInput = document.getElementById('email');

    if (!(value && value.trim())) {
        emailInput?.classList.remove('goodBorder');
        return "*الرجاء إدخال إيميل";
    }


    if (!emailRegex.test(value)) {
        emailInput?.classList.remove('goodBorder');
        return "*الرجاء إدخال إيميل صحيح xxxx@xxxx.com";
    }

    if (emailInput){
        emailInput.classList.add('goodBorder');
    }

    return true;
}