<template>
  <div :dir="dir">
    <div class="flex items-center gap-2 mt-10 text-xl w-10/12 mx-auto md:w-full">
      <svgicon :data="Wrong" :width="isMobile ? 20 : 32" :height="isMobile ? 20 : 32" original :fill="true" />
      <span class="text-red-custom  font-bold-500 text-lg  md:font-bold md:text-19px"> {{ wrongAnswerText }} </span>
    </div>
    <hr class="border my-4 lg:my-10" />

    <div class="flex flex-col gap-5 items-start">
      <h6 class="mt-2 text-blue-custom  font-bold-500 text-lg  md:font-bold md:text-19px">{{ yourCorrectAnswerText }}
      </h6>
      <div class="flex flex-row justify-between w-full sm:w-9/12 text-blue-custom">
        <div
          class="py-2 px-4 rounded-lg w-full bg-white items-center  shadow-sm cursor-pointer border-blue-custom border-2">
          <div class="flex flex-row gap-2 items-center">
            <span v-if="$isNotEmpty(answerLabel)"
              class="rounded-full p-2 font-bold w-7 h-7 lg:w-10 lg:h-10 flex items-center justify-center bg-blue-custom text-white">{{
                answerLabel }}</span>
            <span class="text-blue-custom" v-if="isTextAnswer || isNumericAnswer">{{ answerBody }}</span>
            <div v-if="isTextAndPhotoAnswer">
              <p v-if="$isNotEmpty(answerBody)">{{ answerBody }}</p>
              <img v-if="$isNotEmpty(answerPhoto)" :src="answerPhoto">
            </div>
          </div>
        </div>
      </div>
      <div v-if="isValidModelAnswer" class="dynamic-align w-full">
        <div>
          <div class="font-bold-500 text-xl   text-19px text-black-custom my-2">{{ explainAnswerText }}</div>
          <p class=" font-bold-500 text-base  text-17px" v-if="$isNotEmpty(this.modelAnswerBody)"> {{
            this.modelAnswerBody }}</p>
        </div>
        <div v-if="$isNotEmpty(this.modelAnswerPhoto)">
          <img :src="this.modelAnswerPhoto" alt="Content Image" class="h-[306px] w-[484px]" />
        </div>
        <div class="w-full my-2" v-if="$isNotEmpty(this.modelAnswerURL)">
          <div v-html="this.modelAnswerURL"></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Wrong from "@/assets/svg/red-x.svg";
import answerDataMixin from "@/mixins/answerData";
import windowSize from "@/mixins/windowSize";

export default {
  mixins: [answerDataMixin, windowSize],
  data() {
    return {
      Wrong,
    };
  },
  props: {
    answer: {
      type: Object,
      required: true,
    },
    dir: {
      type: String,
      required: true,
    },
    modelAnswerBody: {
      type: String,
      required: true,
    },
    modelAnswerURL: {
      type: String,
      required: true,
    },
    modelAnswerPhoto: {
      type: String,
      required: true,
    },
    isValidModelAnswer: {
      type: Boolean,
      required: true,
    },
    wrongAnswerText: {
      type: String,
      required: true,
    },
    explainAnswerText: {
      type: String,
      required: true,
    },
    yourCorrectAnswerText: {
      type: String,
      required: true,
    },
  },
};
</script>
