<template>
  <div class="w-[324px] md:w-[440px] rounded-[10px] flex flex-col justify-center items-center mt-[80px] md:mt-[134px] mx-auto">
    <div class="w-[100%] flex flex-col justify-center items-center mt-[56px] md:m-4">
      <Features :features="features" class="w-[125%] md:w-full flex justify-start items-start mb-[25px] md:mb-[91px]"/>
      <PaymentHere :paymentId="paymentId"/>
      <ContactUs :paymentPhoneNumber="paymentPhoneNumber"/>
    </div>
  </div>
</template>

<script>
import ContactUs from '@/components/Subscriptions/VodafoneCash/ContactUs.vue';
import Features from '@/components/Subscriptions/VodafoneCash/Features.vue';
import PaymentHere from '@/components/Subscriptions/VodafoneCash/PaymentHere.vue';

export default {
  name: 'SubscriptionCard',
  props: {
    cartData: {
      type: Object
    },
    vodafoneCashData: {
      type: Object
    }
  },
  components: {
    Features,
    PaymentHere,
    ContactUs
  },
  data() {
    return {
      features: this.vodafoneCashData.description || '',
      paymentId: this.vodafoneCashData.id || 0,
      paymentPhoneNumber: this.vodafoneCashData.phone_number || '',
      paymentURL: this.vodafoneCashData.url || '',
    }
  },
}
</script>