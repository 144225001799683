import { gql } from '@apollo/client/core';

export const GET_ME_EDIT_PROFILE = gql`
query {
    me {
      name
      email
      phone_number
      educationTypes{
          id
          name
      }
      educationStage{
          id
          name
      }
      educationClass{
          id
          name
      }
      educationSection{
          id
          name
      }
      courseClassSecondaryLanguage{
          id
          name
      }
      courseClassReligion{
          id
          name
      }
      personalData {
        last_name
        parent_phone_number
      }
    }
  }
`;

export const GET_ME_MY_PROFILE = gql`
    query {
        me {
            name
            personalData {
                last_name
                parent_phone_number
            }
        }
    }
`;
