export const isValidSecondaryLanguage = (value, secondaryLanguages, secondaryLanguage) => {
    let secondaryLanguageInput = document.getElementById('eduSecondaryLanguage');

    if (!(value && value.trim()) || value === '0' || value === 0) {
        secondaryLanguageInput?.classList.remove('goodBorder');

        return true;
    }
    const selectedSecondaryLanguage = secondaryLanguages.find(type => type.id === secondaryLanguage);

    if (!selectedSecondaryLanguage) {
        secondaryLanguageInput?.classList.remove('goodBorder');

        return '*اللغة الثانية المحددة غير صحيحة.';

    }
    if (secondaryLanguageInput){
        secondaryLanguageInput.classList.add('goodBorder');
    }
    return true;
};