import { gql } from "@apollo/client/core";
import { navigationFragment } from '@/graphql/fragments/navigationFragments';

export const EXERCISE_CONTENT = gql`
    query GetExerciseContent($slug: String!){
        exerciseContent(input: {
            slug: $slug
        }) {
            id
            name
            slug
            exercise_time
            is_exam
            is_completed
            number_of_questions
            difficulty_level
            is_already_started_solution
            completed_exercise_sessions {
                id
                score
                start_time
                end_time
                formatted_time
                show
                time_taken
            }
            navigation {
                ...LessonContentNavigation
            }
        }
    }
    ${navigationFragment}

`;

export const EXERCISE_RESULT_CONTENT = gql`
    query GetExerciseResultContent($slug: String!){
        exerciseResultContent(input: {
            slug: $slug
        }) {
            grade
            time
            exercise {
                id
                name
                slug
                is_exam
                is_completed
                direction
                navigation {
                    ...LessonContentNavigation
                }
            }
            userExerciseSessionId
            show
        }
    }
    ${navigationFragment}

`;

export const EXERCISE_SAVED_ANSWERS = gql`
    query getStudentSavedExerciseAnswers($UserExerciseSessionId: ID!){
        studentSavedExerciseAnswers(input: {
            UserExerciseSessionId: $UserExerciseSessionId
        }) {
            exercise_name
            is_exam
            direction
            backend_update_time
            created_at
            completed_at
            total_score
            completed
            questions {
                id
                body
                photo_link
                correct_answer_id
                user_answer
                answers {
                    id
                    answer_type
                    body
                    label
                    photo_link
                    is_correct
                }
                model_answer {
                    body
                    url
                    photo_link
                }
            }
        }
    }
`;