<template>
<div class="bg-yellow-custom font-bold-500 w-11/12 md:w-10/12 mr-auto md:mx-auto rounded-tr-6xl rounded-br-6xl md:rounded-3xl relative py-[10%] md:py-[1%]">
    <div class="flex flex-col gap-1 lg:text-center relative sm:py-4 md:py-8 text-black-custom">
        <img :src="bag"  :width="isMobile ? 110 : 142" :height="isMobile ? 110 : 142" class="absolute transform translate-x-1/2 -translate-y-1/2
                md:-top-[0%] md:right-[0%] -top-[20%] right-[8%] lg:top-0 lg:right-[1%]" />

        <h1 class="text-xl lg:text-3xl font-bold mb-[12%] md:mb-[2%] ">السنوات اللى بنقدمها؟</h1>
        <div class="flex flex-col gap-2 lg:font-bold-500 lg:text-3xl w-9/12 mx-auto items-start md:w-full md:items-center dynamic-align text-lg">
            <div>هتلاقى على الخطة</div>
            <div class="lg:text-25px">
                كل المواد
                <span> عام او لغات </span>
            </div>
            <div class="lg:text-25px">
                لكل السنوات الدراسية ابتداء من
                <br v-if="isMobile" />
                <span>الرابع الإبتدائى</span>
                حتى
                <span> الثالث الثانوي </span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import bag from "@/assets/images/landing/bag.png";
import windowSize from '@/mixins/windowSize';

export default {
  mixins: [windowSize],
  name: "LandingInfo",
    data() {
        return {
            bag,
        };
    },
};
</script>

<style scoped>
span {
    color: #1c9deb;
    font-weight: bold;
}
</style>
