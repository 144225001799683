export const isValidEducationSection = (value, educationClasses, educationClass) => {
    let educationSectionInput = document.getElementById('educationSection');

    if (!(value && value.trim()) || value === '0' || value === 0) {
        educationSectionInput?.classList.remove('goodBorder');

        return '*الرجاء اختيار القسم الدراسى.';
    }
    const selectedEducationClass = educationClasses.find(type => type.id === educationClass);

    if (selectedEducationClass) {
        const educationClassExists = selectedEducationClass.educationSections.some(stage => stage.id === value);

        if (!educationClassExists) {
            educationSectionInput?.classList.remove('goodBorder');

            return '*لقسم الدراسى المحدد غير صالح.';
        }
    } else {
        educationSectionInput?.classList.remove('goodBorder');
        return '*السنة الدراسية المحددة غير صالحة.';
    }

    if (educationSectionInput) {
        educationSectionInput.classList.add('goodBorder');
    }

    return true;
};