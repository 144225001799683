import {gql} from "@apollo/client/core";
import {navigationFragment} from '@/graphql/fragments/navigationFragments';

export const PAPER_SUMMARY = gql`
    query GetPaperSummaryContent($slug: String!){
        paperSummaryContent(input: {
            slug: $slug
        }) {
            name
            slug
            body
            is_completed
            direction
            navigation {
                ...LessonContentNavigation
            }
        }

    }
    ${navigationFragment}
`;