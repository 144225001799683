<template>
  <div v-if="showValidationMessage" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-center pr-2 mt-1">
    يجب اختيار المرحلة التعليمية اولا
  </div>
</template>


<script>


export default {
  name: 'RequiredEducationTypeAndEducationStageMessage',
  data() {
    return {
      showValidationMessage: false
    };
  },
  mounted() {

    if (this.$route.query.requiredEducationTypeAndEducationStage) {
      this.showValidationMessage = true;
      this.$router.replace({ query: null });

    }




  }
};
</script>
