<template>
  <div class="w-full flex flex-column content-center justify-center flex-wrap" dir="rtl">
    <span class="font-bold text-[19px] mb-[11px] md:mb-0">أو</span>
    <span class="font-bold text-[19px] mt-[13px]">تواصل معنا واتس على رقم {{paymentPhoneNumber}}</span>
    <span class="text-[#EC1212] text-[17px]">ملحوظة <span class="font-bold">مهمة</span> ده رقم التواصل فقط <br> مش اللي هتدفع عليه الاشتراك</span>
  </div>
</template>
    
<script>
  export default {
    name: 'PaymentHere',
    props:{
      paymentPhoneNumber: {
        type: String,
      }
    },
  }
</script>