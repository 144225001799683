<template>
  <div class="relative flex flex-col min-h-screen">

    <div class=" mb-[1500px] md:mb-0 mt-[117px] md:mt-[77px] ">
      <LoadingSpinner :is-loading="studentEnrollmentsLoading || studentServicesLoading" />
      
    <CartEmptyMessage/>
    <div class="relative w-full">
      <span v-if="studentEnrollments.length > 0" class="font-bold relative top-[0%] md:top-[6.2%] left-[30.22%] md:left-[19.76%] w-[150px] ">اشتراكك الحالي</span>
      <div v-if="studentEnrollments.length > 0" class="available-subs flex flex-col justify-center items-center flex-wrap relative md:max-w-[calc(100vw-256px)] mt-[18px] md:mt-[13px] mb-[52px] md:mb-[48px]">
        <div v-for="studentEnrollment in studentEnrollments" :key="studentEnrollment.id">
          <CurrentSubscribedCard :enrollment="studentEnrollment"/>
        </div>
      </div>
    </div>
    <div class="relative">
      <span v-if="studentServices.length > 0 && studentEnrollments.length > 0" class="font-bold relative top-[6.2%] md:top-[4.2%] left-[30.22%] md:left-[19.76%] w-[150px]">تقدر تشترك في</span>
      <span v-if="studentServices.length > 0 && studentEnrollments.length == 0" class="font-bold relative top-[6.2%] md:top-[0%] left-[30.22%] md:left-[36%] w-[150px]">تقدر تشترك في</span>
      <div v-if="studentServices.length > 0" class="current-subs flex justify-center items-center flex-wrap relative md:max-w-[calc(100vw-256px)]" dir="rtl">
        <div v-for="studentService in studentServices" :key="studentService.id">
          <SubscriptionCard class="mx-[18px]" :service="studentService"/>
        </div>
      </div>
    </div>

    <router-link :to="{ name: 'SubscriptionPerSubject' }"  v-if="studentServices.length > 0 || studentEnrollments.length > 0" class="md:max-w-[calc(100vw-256px)]" dir="rtl">
      <button class="btn mx-auto w-[248px] md:w-[284px] md:text-[19px] text-[16px] flex justify-center items-center mb-32">عرض
        الاشتراك بالمادة
        <svgicon :data="pointerIcon" width="30" height="30" original class="mr-4" />
      </button>
    </router-link>
  </div>
  </div>
</template>

<script>
import CurrentSubscribedCard from '@/components/Subscriptions/CurrentSubscribedCard.vue';
import SubscriptionCard from "@/components/Subscriptions/SubscriptionCard.vue";
import {studentEnrollment , studentServices} from '@/graphql/queries/subscriptions/handlingCalls';
import pointerIcon from '@svgicon/pointer.svg';
import CartEmptyMessage from "@/components/FormComponents/CartEmptyMessage.vue";

export default {
  name: "SubscriptionPage",
  components: {
    CartEmptyMessage,
    SubscriptionCard,
    CurrentSubscribedCard,
  },
  data() {
    return {
      studentServices: [],
      studentEnrollments: [],
      studentEnrollmentsLoading : true,
      studentServicesLoading : true,
      pointerIcon: pointerIcon
    }
  },
  studentEnrollment,
  studentServices,
  methods : {
    fetchCourseEnrollments () {
      const {studentCourseEnrollments,  loading} = studentEnrollment();

      this.$watch(
          () => studentCourseEnrollments.value,
          (newVal) => {
            this.studentEnrollments = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.studentEnrollmentsLoading = newVal;
          },
          {immediate: true}
      );
    },
    fetchStudentServices (){
      const {studentServices: services,  loading} = studentServices();

      this.$watch(
          () => services.value,
          (newVal) => {
            this.studentServices = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.studentServicesLoading = newVal;
          },
          {immediate: true}
      );
    },
    onLoad() {
      this.fetchCourseEnrollments();
      this.fetchStudentServices();
    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.onLoad();
    next();
  },

}

</script>

<style scoped>
.btn {
  background-color: #1C9DEB;
  color: white;
  font-weight: bold;
  height: 35px;
  border-radius: 20px;
}

</style>