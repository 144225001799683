import { useMutation } from '@vue/apollo-composable';
import {FORGOT_PASSWORD} from "@/graphql/mutations/forgotPassword/forgotPassword";
import {handleResponse} from "@/helpers/graphqlResponseHandler";


export const forgotPassword = (async (submittedPhoneNumber) => {

    const { mutate } = useMutation( FORGOT_PASSWORD,
        {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
            variables: {
                phone_number: submittedPhoneNumber,
            },
        }
    )

    try {
        const response = await mutate()
        return handleResponse(response , 'forgotPassword');

    } catch (error) {
        console.error('Error adding data:', error);
    }

})