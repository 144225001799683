export const filters = [
    {
      label: "All",
      key: "ALL",
    },
    {
      label: "Videos",
      key: "videos",
    },
    {
      label: "Exercises",
      key: "exercises",
    },
    {
      label: "Paper Summaries",
      key: "paperSummaries",
    },
  ];
  
  export const arabicFilters = [
    {
      label: "الكل",
      key: "ALL",
    },
    {
      label: "فيديوهات",
      key: "videos",
    },
    {
      label: "تمارين",
      key: "exercises",
    },
    {
      label: "ملخصات",
      key: "paperSummaries",
    },
  ];
  
  export function getLocalizedFilters(direction) {
    return direction === "RTL" ? arabicFilters : filters;
  }
  