<template>
  <template v-if="isRTL">
    <i class="fa-solid fa-angle-right" :class="'text-' + color"></i>
  </template>
  <template v-else>
    <i class="fa-solid fa-angle-left" :class="'text-' + color"></i>
  </template>
</template>

  
<script>
export default {
  props: {
    color: {
      type: String,
      required: true
    }
  },
  computed: {
    isRTL() {
      return document.dir === 'rtl';
    }
  }
};
</script>
