import {gql} from "@apollo/client/core";

export const UPDATE_USER_PASSWORD = gql`
    mutation UpdateUserPassword(
        $password: String!,
        $password_confirmation: String!,
    ) {
        updateUserPassword(input: {
            password: $password
            password_confirmation: $password_confirmation
        }) {
            name
        }

        
    }
`;
