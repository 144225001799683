import { useMutation } from '@vue/apollo-composable';
import {handleResponse} from "@/helpers/graphqlResponseHandler";
import {EXERCISE_START} from "@/graphql/mutations/exerciseStart/exerciseStart";

export const startExercise = (async (exerciseSlug) => {

    const { mutate } = useMutation(EXERCISE_START, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            slug: exerciseSlug,

        },
    });


    try {
        const response = await mutate();


        return handleResponse(response , 'startExercise');


    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }

})