<template>

  <div
      class="w-[324px] md:w-[440px] rounded-[10px] flex flex-col justify-center items-center mt-[80px] md:mt-[134px] mx-auto" dir="rtl">

    <LoadingSpinner :is-loading="isLoading" />

    <div class="w-[90%] relative flex flex-col justify-center items-center m-4">
      <Features :features="features" class="w-[125%] md:w-full mb-4 flex justify-start items-start"/>

      <div v-if="!cartIsValidToAddCoupon">
        <Price  :price="cartPriceDisplay"/>
      </div>

      <div v-if="cartIsValidToAddCoupon" class="flex flex-col justify-center items-center">
        <Price v-if="!isCouponApplied && !isPanelOpened" :price="cartPriceDisplay"/>
        <CouponActivated class="w-[284px] mx-auto" v-if="!isCouponApplied && !isPanelOpened" @click="activateCoupon()"/>
        <div class="w-[370px] flex flex-col justify-between md:justify-center items-center mb-8 relative">

          <div v-if="isCouponApplied || isPanelOpened" class="flex flex-col w-full mt-[35px]">
            <Form  class="flex w-full justify-center md:justify-between items-center mb-[8px] md:mb-[4px]">
              <Field type="text" name="coupon"  dir="ltr"
                     class="inputBorderStyle border-2 border-light-gray-custom p-2 w-[188px] h-[36px] rounded-[10px] text-center ml-3 md:ml-0"
                     v-model="couponCode" id="coupon" placeholder="ضع كود الخصم هنا"
              />

              <button v-if="!isCouponApplied"
                      class='btn w-[109px] md:w-[169px] relative h-[54px] rounded-[10px] flex justify-center items-center text-[14px] md:text-[17px] font-medium'
                      id="submitBtn" @click="onApplyCoupon($event)" type="submit">تطبيق الكود
              </button>
              <span v-if="isCouponApplied" @click="onRemoveCoupon($event)"
                    class='bg-light-gray-custom text-gray-custom w-[109px] md:w-[169px] relative h-[35px] rounded-[10px] flex justify-center items-center cursor-pointer text-[14px] md:text-[17px] font-medium'
                    id="submitRemoveCouponBtn">حذف الكود</span>
            </Form>
            <ErrorCoupon v-if="errorCoupon" :errorCoupon="errorCoupon"/>
            <ValidCoupon v-if="successCoupon" :success="successCoupon"/>

            <PriceTable :total="cartPrice" :discount="couponDiscount" :afterDiscount="cartTotal"/>
          </div>

        </div>

      </div>


      <div class="w-full flex flex-column content-center justify-center flex-wrap" dir="rtl">

        <Form class="w-full flex flex-col justify-center items-center" @submit="onSubmit()" action="">
          <div class="relative flex flex-col justify-center mb-1 mt-[10px] md:mt-[29px] w-[284px]  border-green-700">

            <PhoneNumberInput
                v-model="phoneNumber"
                placeholder="اكتب رقم تليفونك هنا"
                :rules="isValidPhone"
                :styleClass="'p-2 pr-[17px] inputBorderStyle w-[100%] h-[54px] rounded-[10px]'"
                :containerFieldClass="'relative md:max-w-[284px] h-[54px] m-auto rounded-[10px] flex justify-center items-center'"
            >
              <template v-slot:icon>
                <svgicon :data="smartPhoneIcon" width="30" height="30" original class="absolute left-[14px] md:left-[14px]"/>
              </template>
            </PhoneNumberInput>

          </div>

            <div class="relative flex flex-col justify-center mb-1 w-[284px]  border-green-700">
              <EmailInput
                  name="email"
                  :rules="isValidEmailCheckout"
                  placeholder="اكتب ايميلك هنا"
                  v-model="email"
                  id="email"
                  :styleClass="'p-2 pr-[17px] inputBorderStyle w-[100%] h-[54px] rounded-[10px]'"
                  :containerFieldClass="'relative md:max-w-[284px] h-[54px] m-auto rounded-[10px] flex justify-center items-center'"
              >
                <template v-slot:icon>
                  <svgicon :data="smartPhoneIcon" width="30" height="30" original class="absolute left-[14px] md:left-[14px]"/>
                </template>
              </EmailInput>
            </div>

          <button
              class="btn w-[248px] font-bold relative h-[54px] m-auto rounded-[10px] flex justify-center items-center"
              id="submitBtn" type="submit">إرسال كود الدفع
          </button>

          <div v-if="checkoutErrors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
              <span v-for="(error, index) in checkoutErrors" :key="index">
                {{ error }}
              </span>
          </div>

        </Form>
        <FacingProblems :url="paymentURL"/>
      </div>

    </div>
  </div>
</template>

<script>
import {Form, Field} from 'vee-validate';
import {applyCoupon , removeCoupon , checkout} from '@/graphql/mutations/cart/handlingCalls';
import Features from '@/components/Subscriptions/AddToCartFawry/Features.vue';
import Price from "@/components/Subscriptions/AddToCartFawry/Price.vue";
import ErrorCoupon from '@/components/Subscriptions/AddToCartFawry/ErrorCoupon.vue';
import ValidCoupon from '@/components/Subscriptions/AddToCartFawry/ValidCoupon.vue';
import CouponActivated from '@/components/Subscriptions/AddToCartFawry/CouponActivated.vue';
import PriceTable from '@/components/Subscriptions/AddToCartFawry/PriceTable.vue';
import PhoneNumberInput from '@/components/FormComponents/PhoneNumberInput.vue';
import FacingProblems from '@/components/Subscriptions/AddToCartFawry/FacingProblems.vue';
import {isValidCoupon} from "@/validations/isValidCoupon";
import {isValidPhone} from "@/validations/isValidPhone";
import smartPhoneIcon from '@svgicon/smartphone.svg'
import EmailInput from "@/components/FormComponents/EmailInput.vue";
import {isValidEmailCheckout} from "@/validations/isValidEmail";
import routes from "@/router/routes";

export default {

  name: 'SubscriptionCard',
  props: {
    cartData: {
      type: Object
    },
    fawryData: {
      type: Object
    }
  },
  components: {
    EmailInput,
    Form,
    Field,
    Features,
    Price,
    ErrorCoupon,
    ValidCoupon,
    CouponActivated,
    PriceTable,
    PhoneNumberInput,
    FacingProblems
  },
  computed: {
    cartPriceDisplay() {
      if(!this.cartIsValidToAddCoupon)
        return this.cartTotal;

      return this.cartPrice;
    },
    isCouponApplied(){


      if (!this.cartIsValidToAddCoupon)
        return false;

      return Boolean(this.couponCode && this.couponType);
    },
    couponDiscount(){
      if (!this.cartIsValidToAddCoupon || !this.isCouponApplied)
        return 0;

      return this.cartPrice  - this.cartTotal;
    },
    isPanelOpened (){
      return this.isOpenCouponPanel;
    }
  },
  data() {
    return {
      features: this.fawryData.description || '',
      paymentId: this.fawryData.id || 0,
      paymentPhoneNumber: this.fawryData.phone_number || '',
      paymentURL: this.fawryData.url || '',

      cartProductId: this.cartData.product_id || 0,
      cartIsValidToAddCoupon: this.cartData.is_valid_to_add_coupon || false,

      cartPrice: this.cartData.price || 0,
      cartTotal: this.cartData.total || 0,

      couponCode: this.cartData?.coupon?.code || '',
      couponType: this.cartData?.coupon?.type || '',

      isOpenCouponPanel: false,

      errorCoupon: '',
      successCoupon: '',


      phoneNumber: '',
      email: '',
      checkoutErrors: [],

      smartPhoneIcon: smartPhoneIcon,
      isLoading: false
    }
  },
  methods: {
    isValidPhone,
    isValidEmailCheckout,
    isValidCoupon,
    activateCoupon() {
      this.isOpenCouponPanel = !this.isOpenCouponPanel
    },
    async onSubmit() {
      this.checkoutErrors = [];


      try {
        const checkoutResponse = await this.$withLoading(() => checkout(this.paymentId  , this.phoneNumber, this.email)  );

        if (checkoutResponse.success === false) {
          this.checkoutErrors = checkoutResponse.displayValidationMessages;
        } else {
          this.$store.dispatch('Order/updateReferenceNumber', checkoutResponse.data?.reference_number);
          this.$store.dispatch('Order/updateSubmittedPhoneNumber', checkoutResponse.data?.submitted_phone_number);
          this.$store.dispatch('Order/updateValidUntil', checkoutResponse.data?.valid_until);
          routes.navigateTo(routes.FAWRY_CHECKOUT.key);
        }

      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    },

    async onApplyCoupon(event) {
      event.preventDefault();
      event.stopPropagation();
      this.resetCouponMessages();

      const isCouponCodeValid = isValidCoupon(this.couponCode);
      if (!isCouponCodeValid) {
        this.errorCoupon = isCouponCodeValid;
        return;
      }

      try {
        const applyCouponResponse = await this.$withLoading(() => applyCoupon(this.couponCode));

        this.handleCouponResponse(applyCouponResponse, 'تم قبول الكود الخاص بك');

      } catch (error) {
        console.error('Error adding to cart:', error);
      }

    },

    async onRemoveCoupon(event) {
      event.preventDefault();
      event.stopPropagation();
      this.resetCouponMessages();


      try {
        const removeCouponResponse = await this.$withLoading(() => removeCoupon());

        this.handleCouponResponse(removeCouponResponse, '');
        this.resetCouponData();

      } catch (error) {
        console.error('Error adding to cart:', error);
      }

    },

    resetCouponMessages() {
      this.successCoupon = '';
      this.errorCoupon = '';
    },

    handleCouponResponse(response, successMessage) {
      if (response.success === false) {
        this.errorCoupon = response.message;
      } else {
        this.successCoupon = successMessage;
        this.updateCartData(response.data);
      }
    },

    updateCartData(data) {
      this.couponCode = data?.coupon?.code || '';
      this.couponType = data?.coupon?.type || '';
      this.cartPrice = data?.price || 0;
      this.cartTotal = data?.total || 0;
      this.cartIsValidToAddCoupon = data?.is_valid_to_add_coupon || false;
    },

    resetCouponData() {
      this.couponCode = '';
      this.couponType = '';
    },
  },
}
</script>

<style scoped>


.inputBorderStyle {
  border: 1px solid #DADADA;
}

.btn {
  background-color: #1C9DEB;
  color: white;
  font-size: 17px;
  height: 35px;
  border-radius: 20px;
}
</style>