<template>
  <div :class="computedClasses">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: 'bg-white' 
    }
  },
  computed: {
    computedClasses() {
      return [
        'top-shadow',
        this.bgColor
      ];
    }
  }
};
</script>

<style scoped>
.top-shadow {
  width: 100%;
  min-width: 100%;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
  z-index: 999;
}

@media (min-width: 769px) {
  .top-shadow {    
    width: calc(100% - var(--sidebar-width, 255px)); /* Adjust based on sidebar width */
  }
}
</style>
