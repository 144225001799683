<template>
    <div class="w-full">
      <div id="featureVodafone" v-html="formattedFeatures" class="features cursor-pointer relative left-4"></div>
    </div>
</template>
    
<script>
import formatFeatures from '@/mixins/formatFeatures';

  export default {
    mixins: [formatFeatures],

    name: 'VodafoneCashFeatures',
    props:{
      features: {
        type: String,
      }
    },
  }
</script>