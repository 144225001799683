<template>
  <div :class="fontClass" class="bg-[#f4f6f8]">
    <LoadingSpinner :is-loading="isLoadingVideo" />
    <VideoContent v-if="!videoError" :video="video" :videoError="videoError"/>
    <Page404Content v-else-if="videoError && isContentNotFoundError"/>
    <NotEnrolledData v-else-if="videoError && isContentNotEnrolledError" :goTo="handleNotEnrolledNavigation"/>
    <PrerequisitesContent v-else-if="videoError && isContentRequiredPreRequisitesError" title="محتاج اولا تخلص" :prerequisites="contentErrorMessageData"/>

  </div>
</template>

<script>
import VideoContent from "@/components/Video/VideoContent.vue";
import routeParamsMixin from "@/mixins/routeParams";
import contentDataMixin from "@/mixins/contentData";
import windowSizeMixin from "@/mixins/windowSize";
import {handleErrors} from "@/helpers/graphqlQueryErrorResponseHandler";
import {getVideoContent} from "@/graphql/queries/video/handlingCalls";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import Page404Content from "@/components/Page404/Page404Content.vue";
import NotEnrolledData from "@/components/Lesson/NotEnrolledData.vue";
import PrerequisitesContent from "@/components/Lesson/PrerequisitesContent.vue";
import { getFontClass } from '@/helpers/getFontClassHelper';

export default {
  name: "VideoLesson",
  mixins: [routeParamsMixin,contentDataMixin ,  windowSizeMixin],
  components: {
    PrerequisitesContent,
    NotEnrolledData,
    Page404Content,
    LoadingSpinner,
    VideoContent,
  },
  props: {
    contentDataProp: {
      type: String,
      default: 'video'
    },
    contentErrorDataProp: {
      type: String,
      default: 'videoError'
    },
  },
  computed: {
    fontClass() {
      return getFontClass(this.direction);
    },
  },
  data() {
    return {
      video: {},
      videoError: null,
      isLoadingVideo: true,
    };
  },
  methods: {
    fetchVideoContent() {

      const {videoContent, loading, error} = getVideoContent(this.videoSlug);

      this.$watch(
          () => error.value,
          (newVal) => {
            this.videoError = handleErrors(newVal?.graphQLErrors || []);
          },
          {immediate: true}
      );

      this.$watch(
          () => videoContent.value,
          (newVal) => {
            this.video = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.isLoadingVideo = newVal;
          },
          {immediate: true}
      );
    },

    onLoad() {
      this.fetchVideoContent();
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.getRouteParamsData(to.params));
    this.onLoad();
    next();
  },

};
</script>


