<template>
    <div v-if="course.content_type === 'Lesson'" :dir="dir">
        <div class="bg-white rounded-content-radius my-2 px-[10px] lg:px-[26px] py-2 blue-custom-border cursor-pointer w-full dynamic-align flex flex-row items-center justify-between mx-1 text-black-custom"
            :class="{ 'blue-custom-border ': course.is_completed && !course.schedule_at, 'gray-custom-border': course.schedule_at || !course.is_completed }"
            @click="handleClick(course.schedule_at)">
            <div class="flex flex-col justify-start" :dir="dir" :class="{ 'not-available': course.schedule_at }">
                <div class="w-full">
                    <div class="flex gap-4 items-start w-full">
                        <div class="w-full">
                            <div class="flex flex-row items-end justify-between md:w-full gap-4">
                                <div class="flex flex-row items-center gap-2 mx-1 font-bold-500 md:font-bold-400 text-13px md:text-17px"
                                    :class="{ ' text-blue-custom ': course.is_completed && !course.schedule_at, ' text-blue-custom': course.schedule_at || !course.is_completed }">
                                    <div class="font-bold-700">{{ course.name }}</div>
                                    <div v-if="course.free_sample"
                                        class="bg-green-custom px-2 text-gray-100 rounded-2xl text-xs md:text-sm">
                                        {{ freeLessonText() }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-row gap-5 justify-between items-center w-full text-gray-custom text-17px">
                        <div class="font-bold-500 line-clamp-2">
                            {{ course.label }}
                        </div>
                    </div>
                    <div class="flex gap-1 text-left text-xs md:text-sm text-blue-custom mt-2 "
                        v-if="course.schedule_at" :class="{ 'mr-1': dir === 'RTL', 'ml-1': dir === 'LTR' }">
                        <ClockIcon :width="20" :height="20" :isBlack="true" />
                        {{ getScheduleAtText() }}
                        <span class="text-blue-custom font-bold">
                            {{ getScheduleAt() }}
                        </span>
                    </div>
                </div>
            </div>
            <div>
                <CheckMarkIcon :isFinished="course.is_completed" />
            </div>
        </div>
    </div>

    <Blur ref="schedule_at" id="schedule_at_course" :dir="dir">
        <div class="blur-content ">
            <div class="relative responsive-container">
                <div class="text-container">
                    <div class="image-container p-4 mt-2 bg-gray-50 rounded-full shadow-lg">
                        <ClockIcon :width="30" :height="30" :isBlue="true" />
                    </div>
                    <div class="text-13px">هذا الدرس سيكون متاح بتاريخ</div>
                    <div class="font-bold mt-2 text-base">{{ getScheduleAt() }}</div>
                    <button @click="closeBlur"
                        class="bg-blue hover:bg-blue text-white font-bold py-1 px-4 rounded-full mt-3 align-top">
                        <div class="flex flex-row gap-4 items-center px-2">
                            <BackBtn :dir="dir" :width="19.5" :height="19.5" />
                            <span class="text-base font-bold-700">الرجوع</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </Blur>
</template>

<script>
import Blur from "@/components/General/Blur.vue";
import BackBtn from "@/components/Icons/BackBtn.vue";
import CheckMarkIcon from "@/components/General/CheckMarkIcon.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import routes from "@/router/routes.js";
import routeParamsMixin from "@/mixins/routeParams";

export default {
    mixins: [routeParamsMixin],
    name: "CourseContent",
    components: {
        Blur,
        CheckMarkIcon,
        BackBtn,
        ClockIcon,
    },
    data() {
        return {
            freeLessonEnglish: "Free Lesson",
            freeLessonArabic: "حصة مجانية",
        };
    },
    props: {
        course: {
            type: Object,
            required: true,
        },
        dir: {
            type: String,
            required: true,
        },
    },
    computed: {},
    methods: {
        handleClick(schedule_at) {
            if (schedule_at) {
                this.$refs.schedule_at.showBlur();
            } else {
                this.lessonLink();
            }
        },
        closeBlur() {
            this.$refs.schedule_at.hideBlur();
        },
        getScheduleAt() {
            return this.dir === "LTR" ?
                this.course.schedule_at_english :
                this.course.schedule_at_arabic;
        },
        getScheduleAtText() {
            return this.dir === "LTR" ? "Will be Available" : "سيكون متاح";
        },
        lessonLink() {
            return this.course.schedule_at ?
                "" :
                routes.navigateToLesson(this.courseSlug, this.course.slug);
        },
        freeLessonText() {
            if (this.dir === "LTR") return this.freeLessonEnglish;
            else return this.freeLessonArabic;
        },
    },
};
</script>

<style scoped>
.responsive-container {
    padding: 70px 0px;
}

.text-container {
    margin-top: 25px;
}

@media screen and (max-width: 767px) {
    .responsive-container {
        padding: 40px 0px 24px;
    }
}
</style>
