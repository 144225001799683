<template>
<div class="filter-container flex mt-3 justify-start gap-2 items-center" :dir="dir">
    <button v-for="(filter, index) in filters" :key="index" class="text-sm md:text-17px text-black-custom" :class="[getButtonClasses(filter), getFontBoldClass(filter)]" @click="handleFilter(filter)">
        {{ filter.label }}
    </button>
</div>
</template>

<script>
import windowSize from '@/mixins/windowSize';

export default {
    mixins: [windowSize],
    name: "FilterButtons",
    props: {
        filters: {
            type: Array,
            required: true,
            default: () => [],
        },
        activeTab: {
            type: String,
            default: "ALL",
        },
        dir: {
            type: String,
            default: "RTL",
        },
        isLessonPage: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        handleFilter(selectedFilter) {
            this.$emit("changeTap", selectedFilter.key);
        },
        getButtonClasses(filter) {
            return filter.key === this.activeTab ?
                "bg-yellow-custom" :
                "btn-inactive bg-light-buttons";
        },
        getFontBoldClass(filter) {
            if (this.isMobile) {
                if (this.isLessonPage) {
                    return filter.key === this.activeTab ? "font-bold-400" : "font-bold-500";
                } else {
                    return filter.key === this.activeTab ? "font-bold-500" : "font-bold-400";
                } 
            } else {
                return filter.key === this.activeTab ? "font-bold-700" : "font-bold-400";
            }
        },
    },
};
</script>

<style scoped>
.filter-container {
    flex-wrap: wrap;
    max-width: 100%;
}

button {
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 17px;
    padding: 8px 18px;
    text-wrap: nowrap;
    white-space: nowrap;
}

.btn-inactive:hover {
    background-color: #e5e7eb;
}

@media screen and (max-width: 767px) {
    button {
        padding: 4px 10px;
    }
}
</style>
