<template>
  <p :class="computedClass">
    {{ message }} <br> <span dir="ltr">{{ registeredPhone }}</span> رقم
  </p>
</template>

<script>
export default {
  name: 'PhoneVerificationMessage',
  props: {
    registeredPhone: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: 'بعتنالك كود تفعيل في رسالة على موبايل'
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    computedClass() {
      return this.customClass ? this.customClass : 'phone-verification-message';
    }
  }
};
</script>

<style scoped>
.phone-verification-message {
  @apply  text-black opacity-70 top-[30.75%] md:top-[29.01%] text-[17px];
}
</style>
