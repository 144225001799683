<template>
  <div class="absolute">
  <LoadingSpinner :is-loading="isLoading || isLoadingContactUs"/>
  <aside v-if="!isMobile" id="sidebar-multi-level-sidebar" dir="rtl"
         class=" lg:block fixed top-0 shadow-lg right-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
         aria-label="Sidebar">
    <div class="h-full px-3 py-4 overflow-y-auto relative flex flex-col justify-around  bg-white  dark:bg-gray-800  ">
      <ul class="space-y-2 font-medium">


        <li class="absolute top-[-5%] right-[25%]">
          <router-link class="w-full flex" :to="{ name: 'studyContent' }" >
            <img class="w-[132px] cursor-pointer block m-auto " alt="logo"
                 :src="require('@images/logo.png')">
          </router-link>
        </li>

        <div class="sidebar-middle w-[222px] absolute top-[36.1%] right-[17px]">

          <router-link :to="{ name: 'studyContent' }"  active-class="active-sidebar-link-es" exact-active-class="active-sidebar-link-es" >
            <li class="mb-2">
                <a href="#"  class="sidebar-item">
                  <svgicon :data="studyContentIcon" width="32" height="32" original class="ml-[13px]"/>
                <span
                    class="font-bold text-gray-custom hover:text-blue-custom focus:text-blue-custom whitespace-nowrap">المحتوى الدراسي</span>
                </a>
            </li>
          </router-link>


          <router-link :to="{ name: 'AskTeacher' }" active-class="active-sidebar-link-es" exact-active-class="active-sidebar-link-es">
            <li class="mb-2 ">
              <a href="#"
                 class="sidebar-item">
                <svgicon :data="askTeacherIcon" width="32" height="32" original class="ml-[13px]"/>
                <span
                    class="font-bold cursor-pointer text-gray-custom hover:text-blue-custom focus:text-blue-custom whitespace-nowrap">اسأل مدرس</span>
              </a>
            </li>
          </router-link>

          <router-link :to="{ name: 'Subscription' }" active-class="active-sidebar-link-es" exact-active-class="active-sidebar-link-es">
            <li class="mb-2 ">
              <a href="#"
                 class="sidebar-item">
                <svgicon :data="subscriptionsIcon" width="32" height="32" original class="ml-[13px]"/>
                <span
                    class="font-bold cursor-pointer text-gray-custom hover:text-blue-custom focus:text-blue-custom whitespace-nowrap">الاشتراكات</span>
              </a>
            </li>
          </router-link>


          <router-link :to="{ name: 'MainProfile' }" active-class="active-sidebar-link-es" exact-active-class="active-sidebar-link-es">
            <li class="mb-2 ">
              <a href="#" id="myProfile"
                 class="sidebar-item">
                <svgicon :data="myProfileIcon" width="32" height="32" original class="ml-[13px]"/>
                <span id="myProfileText"
                      class="font-bold text-gray-custom hover:text-blue-custom focus:text-blue-custom whitespace-nowrap">حسابي</span>
              </a>
            </li>
          </router-link>

        </div>

        <div  @click="handleContactUsClick" class="sidebar-bottom w-[222px] absolute top-[79.88%] right-[17px]">
          <li class="mb-2 ">
            <a href="#"
               class="sidebar-item">
              <svgicon :data="whatsAppIcon" width="32" height="32" original class="ml-[13px]"/>
              <span
                  class="font-bold cursor-pointer text-gray-custom hover:text-blue-custom focus:text-blue-custom whitespace-nowrap">تواصل معنا</span>
            </a>
          </li>

          <li class="mb-2 " @click="handleSignOutOnClick">
            <a class="sidebar-item">
              <svgicon :data="logOutIcon" width="30" height="30" original class="ml-[13px]"/>
              <span
                  class="font-bold cursor-pointer text-gray-custom hover:text-blue-custom focus:text-blue-custom whitespace-nowrap">تسجيل الخروج</span>
            </a>
          </li>
        </div>

      </ul>
    </div>
  </aside>



  <aside v-if="isMobile" id="sidebar-multi-level-sidebar" dir="rtl" class=" block  fixed bottom-0 shadow-2xl right-0 z-40 h-[75px] w-screen" aria-label="Sidebar">
    <div class="h-full overflow-y-auto relative flex justify-around bg-white  dark:bg-gray-800 ">
      <ul class="flex font-medium h-full w-screen ">

        <div class="sidebar-middle w-full h-full relative flex justify-between">

          <router-link :to="{ name: 'studyContent' }"  class=" w-1/4 h-full" active-class="active-sidebar-mobile-link-es" exact-active-class="active-sidebar-mobile-link-es" >
            <li class="mb-2">
              <a href="#"
                 class="flex flex-col pt-2 h-full items-center rounded-[10px] md:bg-[#F5F5F5]">
                <svgicon :data="studyContentIcon" width="45" height="45" original class="icon"/>
                <span
                    class="flex-1 m-auto md:ms-3 text-[12px] font-medium cursor-pointer text-black hover:text-blue-custom focus:text-blue-custom whitespace-nowrap">المنهج</span>
              </a>
            </li>
          </router-link>

          <router-link :to="{ name: 'AskTeacher' }"  class=" w-1/4 h-full" active-class="active-sidebar-mobile-link-es" exact-active-class="active-sidebar-mobile-link-es" >

            <li class="mb-2">
              <a href="#"
                 class="flex items-center pt-2 h-full flex-col rounded-[10px] md:bg-[#F5F5F5]">
                <svgicon :data="askTeacherIcon" width="45" height="45" original class="icon"/>
                <span
                    class="flex-1 m-auto md:ms-3 text-[12px] font-medium cursor-pointer text-black hover:text-blue-custom focus:text-blue-custom whitespace-nowrap">اسأل مدرس</span>
              </a>
            </li>
          </router-link>

          <router-link :to="{ name: 'Subscription' }" class=" w-1/4 h-full"  active-class="active-sidebar-mobile-link-es" exact-active-class="active-sidebar-mobile-link-es" >

          <li class="mb-2 " >
            <a href="#"
               class="flex items-center pt-2 h-full flex-col rounded-[10px] md:bg-[#F5F5F5]">
              <svgicon :data="subscriptionsIcon" width="45" height="45" original class="icon"/>
              <span
                  class="flex-1 m-auto md:ms-3 text-[12px] font-medium cursor-pointer text-black hover:text-blue-custom focus:text-blue-custom whitespace-nowrap">الاشتراكات</span>
            </a>
          </li>
        </router-link>

          <router-link :to="{ name: 'MainProfile' }" class=" w-1/4 h-full" active-class="active-sidebar-mobile-link-es" exact-active-class="active-sidebar-mobile-link-es">
            <li class="mb-2">
              <a href="#" id="myProfile"
                 class="flex items-center pt-2 h-full flex-col rounded-[10px] md:bg-[#F5F5F5]">
                <svgicon :data="myProfileIcon" width="45" height="45" original class="icon"/>
                <span id="myProfileText"
                      class="flex-1 m-auto md:ms-3 text-[12px] font-medium cursor-pointer text-black hover:text-blue-custom focus:text-blue-custom whitespace-nowrap ">حسابي</span>
              </a>
            </li>
          </router-link>
        </div>

      </ul>
    </div>
  </aside>
</div>
</template>

<script>
import logOutIcon from '@svgicon/logout.svg';
import whatsAppIcon from '@svgicon/whats-app.svg';
import myProfileIcon from '@svgicon/my-profile.svg';
import subscriptionsIcon from '@svgicon/subscriptions.svg';
import askTeacherIcon from '@svgicon/ask-teacher.svg';
import studyContentIcon from '@svgicon/study-content.svg';
import contactUs from "@/mixins/contactUs";
import LogOut from "@/mixins/logOut";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";

export default {
  mixins: [contactUs , LogOut],

  components: {
    LoadingSpinner
  },
  props :{
    isMobile: {
      type: Boolean
    }
  },
  data() {
    return {
      logOutIcon: logOutIcon,
      whatsAppIcon: whatsAppIcon,
      myProfileIcon: myProfileIcon,
      subscriptionsIcon: subscriptionsIcon,
      askTeacherIcon: askTeacherIcon,
      studyContentIcon: studyContentIcon,
    }
  },
  name: 'SideBar',
}
</script>

