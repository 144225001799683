<template>
  <div class="min-h-screen w-full flex flex-col items-center justify-center mt-[20px] md:mt-10">
    <LoadingSpinner :is-loading="educationTypesLoading" />
    <!-- Progress Bar -->
    <div class="w-full flex flex-col items-center space-y-1 md:space-y-10 mt-32" dir="rtl">

      <div
        class="w-[324px] md:w-[672px] absolute top-[12.25%] left-auto md:top-[13.8%] md:mb-[161px] md:flex md:justify-center">
        <ProgressBar progress="25" />
      </div>
      <!-- Required Education Message -->
      <RequiredEducationTypeAndEducationStageMessage />

      <!-- Form -->
      <Form class="w-[284px] md:w-[700px]" @submit="onSubmit" action="">

        <!-- First Name Input -->
        <div class="mb-6 w-full">
          <NameInput name="firstName" :rules="isValidFirstName" v-model="firstName"
            :styleClass="'p-2 pr-[17px] md:pr-[29px] border border-light-gray-custom w-full h-[54px] rounded-[10px] md:w-[375px]'"
            id="firstName" placeholder="اكتب اسمك الأول بالعربي">
            <template v-slot:icon>
              <svgicon :data="userIcon" width="16" height="16" original :fill="false"
                class="absolute left-[12px] md:left-[75px]" />
            </template>
          </NameInput>
        </div>

        <!-- Last Name Input -->
        <div class="mb-6 w-full">
          <NameInput name="lastName" :rules="isValidLastName" v-model="lastName"
            :styleClass="'p-2 pr-[17px] md:pr-[29px] border border-light-gray-custom w-full h-[54px] rounded-[10px] text-right md:w-[375px]'"
            id="lastName" placeholder="اسم الأب والعائلة">
            <template v-slot:icon>
              <svgicon :data="userIcon" width="16" height="16" original :fill="false"
                class="absolute left-[12px] md:left-[75px]" />
            </template>
          </NameInput>
        </div>

        <!-- Parent Phone Input -->
        <div class="mb-6 w-full">
          <PhoneNumberInput v-model="parentPhone" placeholder="رقم صحيح لولي الأمر"
            :styleClass="'p-2 pr-[17px] md:pr-[29px] border border-light-gray-custom w-full h-[54px] rounded-[10px] text-right md:w-[375px]'"
            :rules="isValidParentPhone">
            <template v-slot:icon>
              <svgicon :data="smartPhoneIcon" width="24" height="24" original
                class="absolute left-[8px] md:left-[70px]" />
            </template>
          </PhoneNumberInput>
        </div>

        <!-- Education Type Select -->
        <div class="mb-6 w-full">
          <SelectField :rules="isValidEducationType" name="eduType" id="educationType" v-model="eduType"
            :options="educationTypes" :validateOnModelUpdate="false"
            :selectClass="'border border-light-gray-custom select-box decorated bg-white inputStyle p-2 w-full h-[54px] rounded-[10px] md:w-[375px]'"
            placeholder="اختر نوع التعليم" @optionSelected="onOptionSelected('eduType')" :allow-empty="false" />
        </div>

        <!-- Education Stage Select -->
        <div class="mb-6 w-full">
          <SelectField :rules="educationStageRules" name="eduStage" id="educationStage" v-model="eduStage"
            :options="educationStages" :validateOnModelUpdate="false"
            :selectClass="'selector border border-light-gray-custom select-box decorated bg-white inputStyle p-2 w-full h-[54px] rounded-[10px] md:w-[375px]'"
            placeholder="اختر المرحلة الدراسية" :allow-empty="false" />
        </div>

        <!-- Submit Button -->
        <div class="w-full flex justify-center">
          <BlueButton
            :customClass="'bg-blue-custom text-white text-[19px] font-bold rounded-[10px] w-[284px] h-[54px] flex justify-center items-center'"
            id="submitBtn" type="submit">
            التالي
          </BlueButton>
        </div>

        <!-- Back Button -->
        <div class="w-full flex justify-center mt-6">
          <FromBackButton :customClass="'text-black text-[19px] font-medium w-[284px] md:w-[375px] h-[54px]'"
            id="formBackButtonAccountInfo" @click="handleGoBack" type="submit">
            الرجوع
          </FromBackButton>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { Form } from 'vee-validate';
import { getEducationTypes } from '@/graphql/queries/educationTypes/handlingCalls'
import ProgressBar from "@/components/FormComponents/ProgressBar.vue";
import NameInput from "@/components/FormComponents/NameInput.vue";
import { isValidFirstName } from "@/validations/isValidFirstName.js";
import { isValidLastName } from "@/validations/isValidLastName.js";
import { isValidParentPhone } from "@/validations/isValidPhone.js";
import PhoneNumberInput from "@/components/FormComponents/PhoneNumberInput.vue";
import SelectField from "@/components/FormComponents/SelectField.vue";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import routes from '@/router/routes.js';
import FromBackButton from "@/components/FormComponents/FormBackButton.vue";
import cookieEncryption from "@/helpers/cookieEncryption";
import { isValidEducationType } from "@/validations/isValidEducationType";
import { isValidEducationStage } from "@/validations/isValidEducationStage";
import RequiredEducationTypeAndEducationStageMessage
  from "@/components/FormComponents/RequiredEducationTypeAndEducationStageMessage.vue";
import userIcon from '@svgicon/user.svg';
import smartPhoneIcon from '@svgicon/smartphone.svg';

export default {
  name: 'signUpAccountInfo',
  components: {
    RequiredEducationTypeAndEducationStageMessage,
    FromBackButton,
    BlueButton,
    SelectField,
    PhoneNumberInput,
    NameInput,
    ProgressBar,
    Form,
  },
  computed: {
    educationStageRules() {
      return (value) => isValidEducationStage(value, this.educationTypes, this.eduType);
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      parentPhone: '',
      eduType: "",
      educationTypes: [],
      eduStage: "",
      educationStages: [],
      userIcon: userIcon,
      smartPhoneIcon: smartPhoneIcon,
      educationTypesLoading: false,

    }
  },
  methods: {
    isValidEducationStage,
    isValidEducationType,
    isValidFirstName,
    isValidLastName,
    isValidParentPhone,
    fetchEducationTypes() {
      const { educationTypes, loading } = getEducationTypes();

      this.$watch(
        () => educationTypes.value,
        (newVal) => {
          this.educationTypes = newVal;
        },
        { immediate: true }
      );

      this.$watch(
        () => loading.value,
        (newVal) => {
          this.educationTypesLoading = newVal;
          this.checkAndSetDefaultValues();

        },
        { immediate: true }
      );

    },
    checkAndSetDefaultValues() {
      if (!this.educationTypesLoading) {
        this.setDefaultValues();
      }
    },
    setDefaultValues() {
      const {
        studentFirstName,
        studentLastName,
        studentParentPhone,
        studentEducationType,
        studentEducationStage
      } = cookieEncryption.getCookie(this.$cookies, "elkhetaRegistrationData") || {};


      if (studentFirstName)
        this.firstName = studentFirstName;

      if (studentLastName)
        this.lastName = studentLastName;

      if (studentParentPhone)
        this.parentPhone = studentParentPhone;

      const defaultFields = [
        { field: 'eduType', value: studentEducationType, callback: 'eduType' },
        { field: 'eduStage', value: studentEducationStage, callback: 'eduStage' },
      ];

      defaultFields.forEach(({ field, value, callback }) => {
        this[field] = value || '0';
        if (callback && value) {
          this.onOptionSelected(callback, false);
        }
      });


    },
    onOptionSelected(field, clearState = true) {
      switch (field) {
        case 'eduType':

          if (clearState) {
            this.resetFields('educationStages');

          }
          if (this.eduType) {
            const selectedType = this.educationTypes.find(
              (type) => type.id === this.eduType
            );

            this.educationStages = selectedType ? selectedType.educationStages : [];

          } else {
            this.educationStages = [];
          }
          break;
      }
    },
    resetFields(startField) {
      switch (startField) {
        case 'educationStages':
          this.eduStage = '0';
          this.educationStages = [];
          break;
      }
    },

    onSubmit() {

      const userData = {
        studentFirstName: this.firstName,
        studentLastName: this.lastName,
        studentParentPhone: this.parentPhone,
        studentEducationType: this.eduType,
        studentEducationStage: this.eduStage,
      };

      cookieEncryption.updateCookie(this.$cookies, "elkhetaRegistrationData", userData, 1);


      routes.navigateTo(routes.REGISTER_EDUCATION_INFO.key);

    },
    handleGoBack() {
      routes.navigateTo(routes.REGISTER.key);

    },
    onLoad() {
      this.fetchEducationTypes();

    },

  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {

    this.onLoad();
    next();
  },

}

</script>
