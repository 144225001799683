import { gql } from '@apollo/client/core';

export const FORGOT_PASSWORD = gql`
    mutation ForgotPassword(
        $phone_number: String!
    ) {
        forgotPassword(input: {
            phone_number: $phone_number
        })
    }
`;
