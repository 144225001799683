<template>
  <div class="w-full bg-circle-gray rounded-full h-[11px] md:h-[16px] dark:bg-gray-700 relative">
    <div v-if="progress == 100 || progress100">
      <div class=" bg-[#4CB3F1] h-1/3 rounded-md absolute mr-1 mt-[1px]" :style="{ width: progress/2 + '%' }"></div>
      <div class=" bg-blue-custom h-[11px] md:h-[15px] rounded-full" :style="{ width: progress + '%' }"></div>
    </div>
    <div v-else>
      <div class=" bg-[#FBE571] h-1/3 rounded-md absolute mr-1 mt-[1px]" :style="{ width: progress/2 + '%' }"></div>
      <div class=" bg-yellow-custom h-[11px] md:h-[15px] rounded-full" :style="{ width: progress + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    progress: {
      type: [Number, String],
      required: true,
      validator: function (value) {
        return value >= 0 && value <= 100;
      }
    },
    progress100: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
/* Add any styles specific to the ProgressBar component here */
</style>
