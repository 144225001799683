<template>
  <span class="text-blue-custom font-medium text-[14px] md:text-[17px] cursor-pointer text-right">إدخال كود الخصم!</span>
</template>
    
<script>
  export default {
    name: 'FawryApplyCoupon',
    props:{
      price: {
        type: String,
      }
    },
  }
</script>