<template>
<div class="copyright dynamic-align">
    <div class=" flex flex-row items-center gap-3 text-gray-custom">
        <span class="text-3xl">
            &copy;
        </span>
        <div class="text-xs lg:text-sm lg:text-center align-center">
            <span>
                جميع الحقوق محفوظة لشركة الخطة ش . ذ .م . م
            </span>
            <span class="mt-[12px] lg:mt-[0px] block lg:inline-block">
                2018-2024
            </span>
        </div>
    </div>
</div>
</template>

  
<script>
export default {
    name: 'CopyrightNotice',
};
</script>

  
<style scoped>
.copyright {
    padding: 10px 0 15px 0;
    font-size: 14px;
    color: #333;
    /* Example text color */
}
</style>
