<template>
<div>
    <svgicon :data="BookIcon" :width="width" :height="height" original :style="{ transform: rotate180 }"/>
</div>
</template>

  
<script>
import BookIcon from "@/assets/svg/back-button-white.svg";

export default {
    name: "BackBtn",
    props: {
        dir: {
            type: String,
        },
        width: {
            type: Number,
            default: 24
        },
        height: {
            type: Number,
            default: 24
        }
    },
    data() {
        return {
            BookIcon,
        };
    },
    computed: {
        rotate180() {
            return this.dir === 'LTR' ? 'rotate(180deg)' : '';
        }
    }
};
</script>
