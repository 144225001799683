
function transformValidationErrors(validationErrors) {
    const transformedErrors = [];
    const validationMessages = [];

    for (const field in validationErrors) {
        if (Object.prototype.hasOwnProperty.call(validationErrors, field)) {
            const transformedField = field.replace(/^input\./, '');
            const messages = validationErrors[field];

            transformedErrors.push({
                field: transformedField,
                messages: messages,
            });

            validationMessages.push(...messages);
        }
    }

    return {
        transformedErrors,
        validationMessages
    };
}


function handleSuccess(response, operationName) {
    if (response.data && response.data[operationName]) {
        return {
            success: true,
            data: response.data[operationName],
        };
    } else {
        return {
            success: false,
            message: "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
            displayValidationMessages: "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",

        };
    }
}


function handleError(response) {
    if (response.errors && response.errors.length > 0) {
        const error = response.errors[0];
        const validation = error.extensions && error.extensions.validation
            ? transformValidationErrors(error.extensions.validation)
            : { transformedErrors: null, validationMessages: null };

        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
            messageCode: error.message_code || "",
            messageData: error.message_data || "",
            validationErrors: validation.transformedErrors,
            validationMessages: validation.validationMessages,
            displayValidationMessages : validation.validationMessages?.length && validation.validationMessages?.length > 0 ? validation.validationMessages : [error.message]
        };
    } else {
        return {
            success: false,
            message: "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
            displayValidationMessages: "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }
}


function handleResponse(response, operationName) {

    if (!response){
        return {
            success: false,
            message: "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
            displayValidationMessages: "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }
    if (response.errors) {
        return handleError(response);
    } else {
        return handleSuccess(response, operationName);
    }
}

export { handleSuccess, handleError, handleResponse, transformValidationErrors };
