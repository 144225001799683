<template>
  <div :class="fontClass">
    <LoadingSpinner :is-loading="isLoadingExercise"/>
    <ExerciseResultContent v-if="!exerciseError"  :exerciseContent="exerciseContent" :exercise="exercise" :exerciseError="exerciseError"/>
    <Page404Content v-else-if="exerciseError && isContentNotFoundError"/>
    <NotEnrolledData v-else-if="exerciseError && isContentNotEnrolledError" :goTo="handleNotEnrolledNavigation"/>

  </div>
</template>

<script>

import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import Page404Content from "@/components/Page404/Page404Content.vue";
import ExerciseResultContent from "@/components/Exercise/ExerciseResultContent.vue";
import {handleErrors} from "@/helpers/graphqlQueryErrorResponseHandler";
import {getExerciseResultContent} from "@/graphql/queries/exercise/handlingCalls";
import routeParamsMixin from "@/mixins/routeParams";
import exerciseContentDataMixin from "@/mixins/exerciseContentData";
import windowSizeMixin from "@/mixins/windowSize";
import NotEnrolledData from "@/components/Lesson/NotEnrolledData.vue";
import routes from "@/router/routes";
import { getFontClass } from '@/helpers/getFontClassHelper';

export default {
  mixins: [routeParamsMixin, exerciseContentDataMixin,  windowSizeMixin],

  name: "ResultPage",
  components: {
    NotEnrolledData,
    ExerciseResultContent, Page404Content,
    LoadingSpinner,

  },
  props: {
    contentDataWrapperProp: {
      type: String,
      default: 'exerciseContent'
    },
    contentDataProp: {
      type: String,
      default: 'exercise'
    },
    contentErrorDataProp: {
      type: String,
      default: 'exerciseError'
    },
  },
  computed: {
    fontClass() {
      return getFontClass(this.direction);
    },
  },
  watch: {
    isContentRequiredPreRequisitesError: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          routes.navigateToExercise(this.lessonSlug , this.exerciseSlug)
        }
      },
    },
    isContentNotCompletedError: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          routes.navigateToExercise(this.lessonSlug , this.exerciseSlug , {contentNotCompletedError: true})
        }
      },
    },
  },
  data() {
    return {
      exerciseContent: {},
      exercise: {},
      exerciseError: null,
      isLoadingExercise: false
    }
  },
  methods: {

    fetchExerciseResultContent() {
      const {exerciseResultContent, loading, error} = getExerciseResultContent(this.exerciseSlug);

      this.$watch(
          () => error.value,
          (newVal) => {
            this.exerciseError = handleErrors(newVal?.graphQLErrors || []);
          },
          {immediate: true}
      );

      this.$watch(
          () => exerciseResultContent.value,
          (newVal) => {
            this.exerciseContent = newVal || {};
            this.exercise = newVal?.exercise || {};
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.isLoadingExercise = newVal;
          },
          {immediate: true}
      );
    },
    onLoad() {
      this.fetchExerciseResultContent();
    },

  },
  beforeRouteEnter(to, from, next) {
    next(vm => {

      if (from.params && Object.keys(from.params).length > 0) {
        Object.assign(vm.$data, vm.getRouteParamsData(from.params));
      }

      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.getRouteParamsData(to.params));
    this.onLoad();
    next();
  },
}
</script>
