<template>
  <div class="w-full h-full" >
    <Page404Content :isSideBarHidden="true"/>
  </div>
</template>

<script>
import Page404Content from "@/components/Page404/Page404Content.vue";

export default {
  name: 'Page-404',
  components: {Page404Content}
}
</script>