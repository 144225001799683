<template>
  <div :class="fontClass">
    <LoadingSpinner :is-loading="isLoading" />
    <ExerciseStartContent v-if="(!exerciseError || exerciseError && isTimeExceedError)" :exercise="exercise" :exerciseContent="exerciseContent" :exerciseError="exerciseError"/>
    <Page404Content v-else-if="exerciseError && isContentNotFoundError"/>
    <NotEnrolledData v-else-if="exerciseError && isContentNotEnrolledError" :goTo="handleNotEnrolledNavigation"/>
    <PrerequisitesContent v-else-if="exerciseError && isContentRequiredPreRequisitesError" title="محتاج اولا تخلص" :prerequisites="contentErrorMessageData"/>

  </div>
</template>

<script>
import ExerciseStartContent from "@/components/Exercise/ExerciseStartContent.vue";
import Page404Content from "@/components/Page404/Page404Content.vue";
import routeParamsMixin from '@/mixins/routeParams';
import windowSizeMixin from '@/mixins/windowSize';
import exerciseContentDataMixin from '@/mixins/exerciseContentData';
import {startExercise} from "@/graphql/mutations/exerciseStart/handlingCalls";
import PrerequisitesContent from "@/components/Lesson/PrerequisitesContent.vue";
import NotEnrolledData from "@/components/Lesson/NotEnrolledData.vue";
import routes from '@/router/routes';
import { getFontClass } from '@/helpers/getFontClassHelper';

export default {
  mixins: [routeParamsMixin, exerciseContentDataMixin,  windowSizeMixin],

  name: "ExercisePage",
  components: {
    NotEnrolledData, PrerequisitesContent,
    Page404Content,
    ExerciseStartContent,
  },
  props: {
    contentDataWrapperProp: {
      type: String,
      default: 'exerciseContent'
    },
    contentDataProp: {
      type: String,
      default: 'exercise'
    },
    contentErrorDataProp: {
      type: String,
      default: 'exerciseError'
    },
  },
  computed: {
    fontClass() {
      return getFontClass(this.direction);
    },
  },
  data() {
    return {
      exerciseContent: {},
      exercise: {},
      exerciseError: null,
      isLoading: false
    }
  },
  watch: {
    isContentNumberOfTriesExceeded: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          routes.navigateToExercise(this.lessonSlug , this.exerciseSlug , {numberOfTriesError: true})
        }
      },
    },
    isContentTimeExceeded: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          routes.navigateToExerciseResult(this.lessonSlug , this.exerciseSlug , {timeExceededError: true})
        }
      },
    },
  },
  methods: {
    async startExercise() {
      try {
        const startExerciseResponse = await this.$withLoading(() =>
            startExercise(this.exerciseSlug)
        );

        if (startExerciseResponse.success === false){
          this.exerciseError = startExerciseResponse;
        }else{
          this.exerciseContent = startExerciseResponse.data;
          this.exercise = startExerciseResponse.data?.exercise;
        }
      } catch (error) {
        console.error("Error starting exercise:", error);
      }

    },

    onLoad() {
      this.startExercise();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.params && Object.keys(from.params).length > 0) {
        Object.assign(vm.$data, vm.getRouteParamsData(from.params));
      }

      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.getRouteParamsData(to.params));
    this.onLoad();
    next();
  },
};
</script>
