import {mapActions} from "vuex";
import {logout} from "@/graphql/mutations/logout/handlingCalls";

export default {
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        ...mapActions({
            logoutUser: 'Auth/logoutUser',
        }),
        async handleSignOutOnClick(event) {
            event.preventDefault();
            event.stopPropagation();

            await this.$withLoading(() => logout());
            await this.logoutUser();

            this.$router.push({
                name: 'Login',
            });
        },
    },

}