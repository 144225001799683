import { gql } from '@apollo/client/core';

export const LOGIN_USER = gql`
    mutation LoginUser(
        $phone_number: String!,
        $password: String!,
    ) {
        loginUser(input: {
            phone_number: $phone_number
            password: $password
        }) {
            token
            user {
                is_completed_profile
            }
        }
    }
`;
