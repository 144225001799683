<template>
  <div class="border-2 w-[284px] h-[200px] p-[20px] mt-[26px] rounded-[10px] bg-white flex flex-col">
    <span class="text-[17px] text-gray-custom">كود الدفع الخاص بك هو</span>
    <span class="m-[20px] text-gray-custom text-[25px] ">{{referenceNumber}}</span>
    <span class="text-[17px] text-gray-custom">خدمة فوري كود 788</span>
    <span class="text-[17px] text-gray-custom">الكود صالح حتى {{formattedValidUntil}}</span>
  </div>
</template>
    
<script>
  export default {
    name: 'FawryCheckoutInfo',
    props:{
      referenceNumber: {
        type: String,
      },
      validUntil: {
        type: String,
      },
    },
    computed: {
      formattedValidUntil() {
        if (this.validUntil) {
          const parts = this.validUntil.split(' ');
          if (parts.length === 2) {
            return parts[1] + ' ' + parts[0];
          }
        }
        return this.validUntil;
      }
    }
  }
</script>