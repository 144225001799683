<template>
  <div class="" id="blurry"></div>
  <div class="popup" id="popup">
    <div class="popup-content">
      <p class="popup-text">
        في حال كنت تريد إنشاء حساب من خارج مصر يرجى التواصل معنا من هنا لإنشاء حساب خاص بك
      </p>
      <div class="absolute top-[52.25%] left-[21.53%] md:left-[36.35%]">
        <ContactUsButton/>
      </div>
      <p class="popup-back" @click="undoPopup">الرجوع</p>
    </div>
  </div>
</template>


<script>
import ContactUsButton from '@/components/FormComponents/ContactUsButton.vue';

export default {
  name: 'OutSideEgyptPopup',
  components:{
    ContactUsButton
  },
  methods: {
    undoPopup() {
      const startAndLogField = document.getElementById("startAndLog");
      const backButtonField = document.getElementById("backButton");
      const girafeField = document.getElementById("girafe");
      const containerField = document.getElementById("container");
      const popupField = document.getElementById("popup");
      const blurryField = document.getElementById("blurry");

      startAndLogField.classList.remove('blurred');
      backButtonField.classList.remove('blurred');
      girafeField.classList.remove('blurred');
      containerField.classList.remove('blurred');
      popupField.classList.add('popup');
      popupField.classList.remove('popup-display');
      blurryField.classList.remove('blurry');

    },
  },
};
</script>

<style scoped>

.blurry{
  @apply absolute w-screen top-[62px] h-[894px] z-[10000] right-0 bg-[#4D4D4D80] opacity-50;
}
.popup-display{
  @apply flex w-screen h-[700px] md:h-[800px] z-[10001] top-0 right-0 justify-center items-center;
}
.popup {
  @apply absolute hidden w-screen h-[800px] z-[10001] top-0 right-0 justify-center items-center opacity-100;
}
.popup-content {
  @apply w-[325px] md:w-[700px] h-[240px] rounded-[15px] bg-white absolute top-[38.2%] mx-auto;
}
.popup-text {
  @apply w-[294px] text-[14px] md:text-[16px] font-medium md:font-bold absolute top-[19.41%] md:top-[13.41%] left-[4.7%] md:left-[29.9%];
}
.popup-back {
  @apply cursor-pointer font-bold text-gray-custom absolute top-[82.33%] left-[44.3%] md:left-[46.5%];
}
</style>