<template>
  <div class="w-[324px] md:w-[440px] h-screen rounded-[10px] flex flex-col justify-center items-center mx-auto">
    <SubmittedPhone :submittedPhoneNumber="submittedPhoneNumber"/>
    <CheckoutInfo :referenceNumber="referenceNumber" :validUntil="validUntil"/>
    <router-link :to="{name:'Home'}">
      <BackToMainButton/>
    </router-link>
  </div>
</template>

<script>
import BackToMainButton from '@/components/Subscriptions/FawryCheckout/BackToMainButton.vue';
import CheckoutInfo from '@/components/Subscriptions/FawryCheckout/CheckoutInfo.vue';
import SubmittedPhone from '@/components/Subscriptions/FawryCheckout/SubmittedPhone.vue';

export default {
  name: 'CheckoutPage',
  components: {
    SubmittedPhone,
    CheckoutInfo,
    BackToMainButton
  },
  computed: {
    referenceNumber() {
      return this.$store.getters['Order/getReferenceNumber'];
    },
    submittedPhoneNumber() {
      return this.$store.getters['Order/getSubmittedPhoneNumber'];
    },
    validUntil() {
      return this.$store.getters['Order/getValidUntil'];
    }
  },
  beforeMount() {
    if (!this.referenceNumber) {
      this.$router.push({name: 'Subscription'});
    }
  }
}
</script>