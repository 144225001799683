<template>
    <div class="bg-red-600 text-white text-center justify-center align-center rounded-[10px] text-[12px] cursor-pointer">
        حذف حسابى
    </div>
</template>

<script>
export default {
    name: "DeleteAccountBtn"
}
</script>