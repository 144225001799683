import {gql} from "@apollo/client/core";

export const UPDATE_USER = gql`
    mutation UpdateUser(
        $first_name: String!,
        $last_name: String,
        $email: String,
        $parent_phone_number: String,
        $education_section_id: ID!,
        $course_class_sl_id: ID,
        $course_class_r_id: ID
    ) {
        updateUser(input: {
            first_name: $first_name
            email: $email,
            last_name : $last_name,
            parent_phone_number: $parent_phone_number,
            education_section_id: $education_section_id,
            course_class_sl_id: $course_class_sl_id,
            course_class_r_id: $course_class_r_id
        }) {
            name
            email
            phone_number
            educationTypes{
                id
                name
            }
            educationStage{
                id
                name
            }
            educationClass{
                id
                name
            }
            educationSection{
                id
                name
            }
            courseClassSecondaryLanguage{
                id
                name
            }
            courseClassReligion{
                id
                name
            }
            personalData {
                last_name
                parent_phone_number
            }
        }
    }
`;
