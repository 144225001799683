<template>
          <span class="text-[14px] md:text-[17px] text-blue-custom cursor-pointer mr-[4px] md:mr-[16px] font-medium">
            {{ productName }}  | {{ durationType }} | {{ productPrice }} {{ productCurrency }}
          </span>
</template>
    
<script>
  export default {
    name: 'CurrentSubscribedCardTitle',
    props:{
      product: {
        type: Object,
      }
    },
    data (){
      return {
        productName : this.product?.name || '',
        durationType : this.product?.duration_type || '',
        productPrice : this.product?.price || '',
        productCurrency : this.product?.currency || '',
      }
    }
  }
</script>