<template>
  <div class="bg-[#f4f6f8] min-h-screen flex flex-col justify-center items-center absolute md:w-[calc(100%-260px)]">
    <ChangePhoneNumber/>
  </div>
</template>

<script>
  import ChangePhoneNumber from "@/components/ChangePhoneNumber/ChangePhoneInitial.vue";

  export default {
    name: "ChangePhoneNumberPage",
    components: {
      ChangePhoneNumber
    }     
  }
  
</script>
