import { gql } from '@apollo/client/core';

export const STUDENT_SERVICES = gql`
    query {
        studentServices {
            id
            name
            features
            products {
                id
                price
                currency
                product_subscription_text
                product_discount_text
                product_price_text
                product_subscription_lower_text
            }
        }
    }
`;
