import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";
import {RELATED_USER_EDUCATION_SECTIONS} from "@/graphql/queries/educationSections/educationSections";


export const getRelatedUserEducationSections =  (fetchPolicy='cache-first') => {

    const { result, loading, error } = useQuery(RELATED_USER_EDUCATION_SECTIONS, null,{
        fetchPolicy: fetchPolicy,
        errorPolicy: 'all'
    })

    const educationSections = computed(() => result.value?.relatedUserEducationSections ?? []);


    return {
        educationSections,
        loading,
        error
    }
}

