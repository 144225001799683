<template>
  <div
      v-if="productTitle || productPrice || productDiscountText || productSubscriptionText || productSubscriptionLowerText"
      class="w-[284px] h-min-[134px] border-2 border-blue-custom rounded-[10px] mb-[17px] flex flex-col justify-around items-center relarive" dir="rtl">
    <LoadingSpinner :is-loading="isLoading" />

    <CardProductTitle :productTitle="productTitle" />
    <CardProductPrice :productPrice="productPrice" :productDiscountText="productDiscountText"/>
    <SubsButton @click="addToCart(product.id)" :productSubscriptionText="productSubscriptionText"/>
    <div v-if="productSubscriptionLowerText" class="flex justify-center items-center mb-[8px]" dir="rtl">
      <svgicon :data="moneyBagIcon" width="35" height="35" original/>
       <span class="text-[#1FB865]">{{ productSubscriptionLowerText }}</span>
    </div>

    <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] m-auto text-center pr-2 mt-1 mb-2 mx-auto">
        <span v-for="(error, index) in errors" :key="index">
          {{ error }}
        </span>
    </div>

  </div>
</template>

<script>
import CardProductTitle from '@/components/Subscriptions/SubscriptionCard/CardProduct/CardProductTitle.vue';
import CardProductPrice from '@/components/Subscriptions/SubscriptionCard/CardProduct/CardProductPrice.vue';
import SubsButton from '@/components/Subscriptions/SubscriptionCard/CardProduct/SubsButton.vue';
import moneyBagIcon from '@svgicon/money-bag.svg';
import {addToCart} from "@/graphql/mutations/cart/handlingCalls";
import routes from "@/router/routes";

export default {
  name: 'CardProduct',
  components: {
    CardProductTitle,
    CardProductPrice,
    SubsButton,
  },
  props: {
    product: {
      type: Object
    }
  },
  data() {
    const currency = this.product.currency === 'EGP' ? 'جنيه' : (this.product.currency || '');

    return {
      moneyBagIcon: moneyBagIcon,
      productTitle: this.product.product_price_text || '',
      productPrice: `${this.product.price || ''} ${currency}`,
      productDiscountText: this.product.product_discount_text || '',
      productSubscriptionText: this.product.product_subscription_text || '',
      productSubscriptionLowerText: this.product.product_subscription_lower_text || '',
      errors: [],
      isLoading: false
    }
  },
  methods: {
    async addToCart(productId) {
      this.errors = [];

      try {
        const addToCartResponse = await this.$withLoading(() => addToCart(false, productId));

        if (addToCartResponse.success === false) {
          this.errors = addToCartResponse.displayValidationMessages;
        } else {
          routes.navigateTo(routes.CART.key)
        }
      } catch (error) {
        console.error('Error adding to cart:', error);
      }


    },
  }
}
</script>
