<template>
  <div class="flex flex-col gap-5 items-start">
    <div class="flex items-center gap-2 mt-10 text-xl w-10/12 mx-auto md:w-full">
      <svgicon :data="correct" width="50" height="50" original :fill="false" />
      <span class="text-green-custom font-bold">
        {{ correctAnswerText }}
      </span>
    </div>
    <div v-if="isValidModelAnswer" class="flex flex-col gap-6 items-start mt-2 w-full">
      <div>
        <div class="font-bold-400 lg:font-bold my-2">{{ explainAnswerText }}</div>
        <p v-if="$isNotEmpty(this.modelAnswerBody)"> {{ this.modelAnswerBody }}</p>
      </div>

      <div v-if="$isNotEmpty(this.modelAnswerPhoto)">
        <img :src="this.modelAnswerPhoto" alt="Content Image" class="h-[306px] w-[484px]" />
      </div>
      <div class="w-full my-2" v-if="$isNotEmpty(this.modelAnswerURL)">
        <div v-html="this.modelAnswerURL"></div>
      </div>
    </div>
  </div>
</template>

<script>
import correct from '@/assets/svg/Done_ring_round.svg'
export default {
  data() {
    return {
      correct
    }
  },
  props: {
    modelAnswerBody: {
      type: String,
      required: true,
    },
    modelAnswerURL: {
      type: String,
      required: true,
    },
    modelAnswerPhoto: {
      type: String,
      required: true,
    },
    isValidModelAnswer: {
      type: Boolean,
      required: true,
    },
    correctAnswerText: {
      type: String,
      required: true,
    },
    explainAnswerText: {
      type: String,
      required: true,
    },
  }
}
</script>