import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';
import { ref, watch } from 'vue';

const GET_USER_PROFILE = gql`
    query {
        me {
            is_completed_profile
        }
    }
`;

export const getUserProfileStatus = async () => {
    const { result, loading, error } = useQuery(GET_USER_PROFILE, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
    });

    const isCompletedProfile = ref(false);

    watch(result, (newValue) => {
        if (newValue && newValue.me) {
            isCompletedProfile.value = newValue.me.is_completed_profile;
        }
    }, { immediate: true }); // Immediate to set the value on first call

    while (loading.value) {
        await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for loading to complete
    }

    // Handle errors if needed
    if (error.value) {
        console.error("Error fetching user profile status:", error.value);
        throw error.value;
    }

    return isCompletedProfile.value;
};
