<template>
  <div>
    <LoadingSpinner :is-loading="isLoading" />
    <BackButton class="absolute top-[11.5%] left-[88.5%] md:absolute md:top-[13.15%] md:left-[66.3%] z-[1000]"/>
    <div class="w-full min-h-screen flex flex-column content-center justify-center relative flex-wrap z-[999]" dir="rtl">

      <p class="text-[30px] font-bold mb-[42px]">ادخل الباسوورد الجديد</p>

      <Form class="w-full relative h-[378px] md:h-[340px] flex flex-col items-center" @submit="onSubmit" action="">

        <div class=" w-full h-[75px] mb-3">
          <PasswordInput
              v-model="password"
          />
        </div>

        <div class="w-full h-[100px]">
          <ConfirmPasswordInput
              v-model="password_confirmation"
              :password="password"
          />
        </div>

        <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
        <span v-for="(error, index) in errors" :key="index">
          {{ error }}
        </span>
        </div>

        <BlueButton type="submit" :customClass="'w-[284px] absolute top-[100%] left-auto h-[54px] m-auto rounded-[10px] flex justify-center items-center bg-blue-custom text-white font-bold '">تغيير الباسوورد</BlueButton>

      </form>
    </div>

  </div>

</template>

<script>
import BackButton from '@/components/FormComponents/BackButton.vue';

import { Form } from 'vee-validate';
import { resetPassword } from '@/graphql/mutations/resetPassword/handlingCalls';
import PasswordInput from "@/components/FormComponents/PasswordInput.vue";
import ConfirmPasswordInput from "@/components/FormComponents/ConfirmPasswordInput.vue";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import routes from "@/router/routes";

export default {

  name: 'NewPasswordPage',
  components:{
    BlueButton,
    ConfirmPasswordInput,
    PasswordInput,
    Form,
    BackButton
  },
  data() {
        return {
          password: '',
          password_confirmation: '',
          token: '',
          errors: [],
          isLoading: false
        }
      },
  methods:{
    getTokenFromURL() {
      this.token = this.$route.query.token;
    },
    async onSubmit(){
      this.errors = [];

      if (!this.token){
        this.errors.push('الرمز المميز خالى');
        return ;
      }

      const useData = {
        token: this.token,
        password: this.password,
        password_confirmation: this.password_confirmation,
      }


      let resetPasswordResponse = await this.$withLoading(() => resetPassword(useData) );

      if (resetPasswordResponse.success === false){
        this.errors = resetPasswordResponse.displayValidationMessages;
      }
      else {

        routes.navigateTo(routes.LOGIN.key);
      }



    },

  },
  created() {
    this.getTokenFromURL();
  },

}

</script>
