<template>
  <div class="w-full">
    <div class="form-check flex flex-col justify-center mb-4">
      <div
        :class="{
          'goodBorder': isValid,
          'disabledCursor': disabled
        }"
      >
        <div :class="containerFieldClass">
          <Field
            :type="inputType"
            :name="name"
            :rules="rules"
            :class="[fieldClass, disabled ? 'disabledField' : '']"
            :required="required"
            :modelValue="modelValue"
            @update:modelValue="$emit('update:modelValue', $event)"
            @blur="validationTouched = true"
            :id="id"
            :placeholder="placeholder"
            :disabled="disabled"
          />
          <template v-if="isPassword">
            <EyeOff v-if="!showPassword" @click="togglePassword" :class="eyeClass" />
            <Eye v-if="showPassword" @click="togglePassword" :class="eyeClass" />
          </template>
          <slot name="icon"></slot>
        </div>
      </div>
      <ErrorMessage :class="errorClass" :name="name" />
    </div>
  </div>
</template>


<script>
import { Field, ErrorMessage } from 'vee-validate';
import Eye from '@/components/Icons/EyeIcon.vue';
import EyeOff from '@/components/Icons/EyeOffIcon.vue';

export default {
  name: 'InputField',
  components: { Field, ErrorMessage, Eye, EyeOff },
  props: {
    inputType: {
      type: String,
      default: 'text'
    },
    name: {
      type: String,
      required: true
    },
    rules: {
      type: [String, Object, Function],
      required: true
    },
    fieldClass: {
      type: String,
      default: 'p-2 pr-[17px] md:pr-[29px] form-check-input inputBorderStyle w-[284px] md:w-[375px] h-[54px] rounded-[10px] text-right'
    },
    containerFieldClass: {
      type: String,
      default: 'relative w-[284px] md:w-[500px] h-[54px] m-auto rounded-[10px] flex justify-center items-center'
    },
    required: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    errorClass: {
      type: String,
      default: 'text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1 mb-1'
    },
    isValid: {
      type: Boolean,
      default: false
    },
    eyeClass: {
      type: String,
      default: 'absolute w-[20px] h-[20px] left-[5%] md:left-[16%] cursor-pointer ml-2 scale-[150%] mb-2'
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPassword: false,
      validationTouched: false
    };
  },
  computed: {
    isPassword() {
      return this.inputType === 'password';
    },
    eyeOffClass() {
      return 'absolute w-[20px] h-[20px] left-[8%] md:left-[16%] cursor-pointer ml-2';
    }
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
      const inputField = document.getElementById(this.id);
      inputField.type = this.showPassword ? 'text' : 'password';
    }
  }
};
</script>

<style scoped>
.inputBorderStyle {
  @apply border-solid ;
}

.goodBorder {
  @apply border-solid border-green-500 !important;
}
.disabledField {
  background-color: #f0f0f0; /* Light gray background */
  border-color: #d3d3d3; /* Light gray border */
  color: #a1a1a1; /* Disabled text color */
  cursor: not-allowed;
}
</style>
