import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";
import {EXERCISE_CONTENT, EXERCISE_RESULT_CONTENT, EXERCISE_SAVED_ANSWERS} from "@/graphql/queries/exercise/exercise";

export const getExerciseContent =  (slug) => {


    const { result, loading, error } = useQuery(EXERCISE_CONTENT
        ,
        {
            slug : slug
        },
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        }
    )

    const exerciseContent = computed(() => result.value?.exerciseContent ?? []);

    return {
        exerciseContent,
        loading,
        error
    }
}

export const getExerciseResultContent =  (slug) => {


    const { result, loading, error } = useQuery(EXERCISE_RESULT_CONTENT
        ,
        {
            slug : slug
        },
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        }
    )

    const exerciseResultContent = computed(() => result.value?.exerciseResultContent ?? []);

    return {
        exerciseResultContent,
        loading,
        error
    }
}

export const getStudentSavedExerciseAnswers = (UserExerciseSessionId) => {

    const { result, loading, error } = useQuery(EXERCISE_SAVED_ANSWERS
        ,
        {
            UserExerciseSessionId : UserExerciseSessionId
        },
        {
            // fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        }
    )
    const studentSavedExerciseAnswers = computed(() => result.value?.studentSavedExerciseAnswers ?? []);

    return {
        studentSavedExerciseAnswers,
        loading,
        error
    }
}
