<template>
    <div class="flex bg-[#f4f6f8]" :dir="documentDir">
      <Navbar v-if="(!isAuth || isMobile || isForceTopNavBar) && !isForceHideNavBar" :isFixed="isFixedTopNavBar"/>
      <Sidebar v-if="isAuth && !hideSideBar" :isMobile="isMobile" :class="{  'sidebar' : !hideSideBar }"/>
      <div class="flex flex-col " :class="{ 'w-full': !isAuth || hideSideBar , 'main-content' : !hideSideBar }">
        <router-view class="w-full h-screen"/>
      </div> 
    </div>
</template>


<script>
import "@/assets/tailwind.css"
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import store from "@/store";
import windowSizeMixin from '@/mixins/windowSize';

export default {
  mixins: [windowSizeMixin],

  name: 'App',
  components: {
    Navbar,
    Sidebar,
  },
  computed: {
    hideSideBar() {
      return Boolean(this.$route.meta.hideSideBar);
    },
    isForceTopNavBar() {
      return Boolean(this.$route.meta.forceTopNavBar);
    },
    isForceHideNavBar() {
      return Boolean(this.$route.meta.forceHideTopNavBar);
    },
    isShowFooter() {
      return Boolean(this.$route.meta.showFooter);
    },
    isFixedTopNavBar() {
      return Boolean(this.$route.meta.fixedTopNavBar);
    },
    documentDir() {
      return document.dir || "ltr";
    },
    isAuth() {
      return store.getters['Auth/isAuth'];
    },
  },
};
</script>

<style>
#app {
  font-family: tajawal, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f4f6f8;
}


.mb-72 {
  margin-bottom: 72px;
}

.sidebar {
  flex-basis: 256px;
  flex-shrink: 0;
}

.main-content {
  width: calc(100% - 256px);
}

@media (max-width: 768px) {
  .main-content {
    width: 100%;
  }
}

</style>
