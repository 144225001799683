<template>
  <span  class="text-green-custom mx-1">{{ successCoupon }}</span>
</template>
    
<script>
  export default {
    name: 'FawryValidCouponText',
    props:{
      successCoupon:{
        type: String
      }
    }
  }
</script>