export const isValidEducationClassForEducationStage = (value, educationStages, educationStage) => {
    let educationClassInput = document.getElementById('educationClass');

    if (!(value && value.trim()) || value === '0' || value === 0) {
        educationClassInput?.classList.remove('goodBorder');

        return '*الرجاء اختيار السنة الدراسية.';
    }
    const selectedEducationStage = educationStages.find(type => type.id === educationStage);

    if (selectedEducationStage) {
        const educationStageExists = selectedEducationStage.educationClasses.some(stage => stage.id === value);

        if (!educationStageExists) {
            educationClassInput?.classList.remove('goodBorder');

            return '*السنة الدراسية المحددة غير صالحة.';
        }
    } else {
        educationClassInput?.classList.remove('goodBorder');
        return '*المرحلة الدراسية المحددة غير صالحة.';
    }

    if (educationClassInput) {
        educationClassInput.classList.add('goodBorder');
    }

    return true;
};

export const isValidEducationClass = (value, educationClasses, educationClass) => {
    let educationClassInput = document.getElementById('educationClass');

    if (!(value && value.trim()) || value === '0' || value === 0) {
        return '*الرجاء اختيار السنة الدراسية.';
    }
    const selectedEducationClass = educationClasses.find(type => type.id === educationClass);

    if (!selectedEducationClass) {
        return '*السنة الدراسية المحددة غير صالحة.';

    }
    if (educationClassInput) {
        educationClassInput.classList.add('goodBorder');
    }

    return true;
};
