<template>
  <img class="girafe" :src="require('@images/girafe.png')"  alt="girafe" id="girafe"/>
</template>

<script>
export default {
  name: 'GirafeImage'
};
</script>

<style scoped>
.girafe {
  @apply w-[123px] h-[404px] z-[999];
}

.blurred {
  @apply blur-[5px] transition duration-300 ease-in-out
}
</style>
