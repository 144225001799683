import { useMutation } from '@vue/apollo-composable';
import {handleResponse} from "@/helpers/graphqlResponseHandler";
import {COMPLETE_REGISTRATION, INITIALIZE_REGISTRATION, REGISTER_USER} from "@/graphql/mutations/register/registerUser";
import { ref } from 'vue';

export const handlingCalls = (async (userData) => {

    const { mutate } = useMutation(REGISTER_USER, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            phone_number: userData.phone_number,
            password: userData.password,
            password_confirmation: userData.password_confirmation,
            education_section_id: userData.education_section_id,
            first_name: userData.first_name,
            ...(userData.last_name && userData.last_name !== '' && { last_name: userData.last_name }),
            ...(userData.parent_phone_number && userData.parent_phone_number !== '' && { parent_phone_number: userData.parent_phone_number }),
            ...(userData.course_class_sl_id && userData.course_class_sl_id !== '' && { course_class_sl_id: userData.course_class_sl_id }),
            ...(userData.course_class_r_id && userData.course_class_r_id !== '' && { course_class_r_id: userData.course_class_r_id }),
        },
    });

    try {
        const response = await mutate();


        return handleResponse(response, 'registerUser');



    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }

})

export const initializeRegistration = async (userData) => {
    const { mutate } = useMutation(INITIALIZE_REGISTRATION, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            phone_number: userData.phone_number,
            password: userData.password,
            password_confirmation: userData.password_confirmation,
        },
    });

    try {
        const response = await mutate();
        
        return handleResponse(response, 'initialRegisterUser');
    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    } 
};


export const completeRegisterUserProfile = async (userData) => {
    const isLoading = ref(false);
    const { mutate } = useMutation(COMPLETE_REGISTRATION, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            first_name: userData.first_name,
            last_name: userData.last_name,
            parent_phone_number: userData.parent_phone_number,
            education_section_id: userData.education_section_id,
            course_class_sl_id: userData.course_class_sl_id,
            course_class_r_id: userData.course_class_r_id,
        },
    });

    try {
        isLoading.value = true;
        const response = await mutate();
        return handleResponse(response, 'completeRegisterUserProfile');
    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    } finally {
        isLoading.value = false;
    }
};
