import CryptoJS from 'crypto-js';



export const decryptOTP = (encrypted) => {
    try {
        const payload = JSON.parse(CryptoJS.enc.Base64.parse(encrypted).toString(CryptoJS.enc.Utf8));

        const iv = CryptoJS.enc.Base64.parse(payload.iv);
        const value = payload.value;



        const key = CryptoJS.enc.Base64.parse(process.env.VUE_APP_OTP_ENCYRPTION_KEY);

        const decrypted = CryptoJS.AES.decrypt(
            value,
            key,
            {
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        const decryptedString = CryptoJS.enc.Utf8.stringify(decrypted);

        const lengthStart = decryptedString.indexOf(':') + 1;
        const lengthEnd = decryptedString.indexOf(':"');
        const length = parseInt(decryptedString.substring(lengthStart, lengthEnd));
        return   decryptedString.substring(lengthEnd + 2, lengthEnd + 2 + length);


    } catch (error) {
        console.error('Decryption Error:', error);
        return '';
    }
};