<template>
  <span class="text-gray-custom text-[14px] mt-[8px]">ينتهي خلال {{ expiration }} أيام</span> 
</template>
    
<script>
  export default {
    name: 'CurrentSubscribedCardOrderId',
    props:{
      expiration: {
        type: [String , Number],
      }
    },
  }
</script>