<template>
  <div @click="handleClick" :class="backButton">
    <svgicon :data="backButtonIcon" width="30" height="30" original />
  </div>
</template>

<script>
import backButtonIcon from '@svgicon/back-button.svg';

export default {
  name: 'BackButton',
  props: {
      backButton: {
      type: String,
      default: 'cursor-pointer flex justify-center items-center w-[25px] h-[25px] md:w-[30px] md:h-[30px] border-2 bg-[#CCCCCC] bg-opacity-40 rounded-[10px]'
    },
    customGoBack: {
      type: Function,
      default: null
    }
  },
  data(){
    return {
      backButtonIcon: backButtonIcon

    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    handleClick() {
      if (this.customGoBack) {
        this.customGoBack();
      } else {
        this.goBack();
      }
    }
  }
};
</script>