<template>
  <p :class="computedClass">{{ promptText }}</p>
</template>

<script>
export default {
  name: 'ActivationCodePrompt',
  props: {
    promptText: {
      type: String,
      default: 'ادخل كود التفعيل'
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    computedClass() {
      return this.customClass ? this.customClass : 'activation-code-prompt';
    }
  }
};
</script>

<style scoped>
.activation-code-prompt {
  @apply text-[30px] text-black font-bold top-[24.5%] md:top-[23.79%] ;
}
</style>
