import { useQuery } from '@vue/apollo-composable';
import {computed} from "vue";
import {GET_EDUCATION_CLASSES} from "@/graphql/queries/educationClasses/educationClasses";



export const getEducationClasses =  (education_stage_id) => {
    const { result, loading, error } = useQuery(GET_EDUCATION_CLASSES
        ,
        {
            education_stage_id : education_stage_id
        },
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        }
    )

    const educationClasses = computed(() => result.value?.educationClasses ?? []);

    return {
        educationClasses,
        loading,
        error
    }

}

