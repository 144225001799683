<template>
  <button
      v-if="productSubscriptionText"
      class="bg-[#1C9DEB] text-white font-bold w-[248px] h-[35px] rounded-[20px] mt-[9px] mb-[10px] text-[16px] md:text-[19px]">
    {{ productSubscriptionText }}
  </button>
</template>

<script>
export default {
  name: 'SubsButton',
  props: {
    productSubscriptionText: {
      type: String
    }
  }
}
</script>