<template>
    <div class="flex flex-col items-center justify-center w-full h-full py-10">
      <img :src="Page404" class="phone-img" width="349" height="349" />
      <h1 class="text-3xl text-gray-700 mt-4">{{ message }}</h1>
      <div class="mt-6 flex space-x-4">
        <ButtonCustom @click="goBack" bgColor="bg-gray-300" className="text-gray-800 rounded p-2">
          Go Back
        </ButtonCustom>
        <ButtonCustom @click="goHome" bgColor="bg-blue-500" className="text-white rounded p-2">
          Go Home
        </ButtonCustom>
      </div>
    </div>
  </template>
  
  <script>
  import Page404 from "@/assets/images/Page404.png";
  import ButtonCustom from "@/components/General/ButtonCustom.vue"; // Import your custom button component
  
  export default {
    name: 'DataNotFound',
    components: {
      ButtonCustom, // Register the ButtonCustom component
    },
    props: {
      message: {
        type: String,
        default: 'No data found'
      }
    },
    data() {
      return {
        Page404
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1); // Go back to the previous page
      },
      goHome() {
        this.$router.push({ name: 'Home' }); // Adjust the route name for your home page
      }
    }
  };
  </script>
  
  <style scoped>
  .phone-img {
    max-width: 100%;
  }
  </style>
  