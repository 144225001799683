import { gql } from '@apollo/client/core';


export const UPDATE_USER_PHONE_NUMBER = gql`
    mutation UpdateUserPhoneNumber(
        $phone_number: String!,
    ) {
        updateUserPhoneNumber(input: {
            phone_number: $phone_number
        }) {
            name
        }


    }
`;


