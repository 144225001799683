import { GET_ME_EDIT_PROFILE, GET_ME_MY_PROFILE} from '@/graphql/queries/myProfile/myProfile';
import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";


export const getEditProfileData =  () => {


    const { result, loading, error } = useQuery(GET_ME_EDIT_PROFILE , null ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const profileData = computed(() => result.value?.me ?? []);

    return {
        profileData,
        loading,
        error
    }
}

export const getMyProfileData =  () => {


    const { result, loading, error } = useQuery(GET_ME_MY_PROFILE , null ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const profileData = computed(() => result.value?.me ?? []);

    return {
        profileData,
        loading,
        error
    }
}


