import store from '@/store';
import { getUserProfileStatus } from '@/graphql/queries/me/getUserProfile';
import routeNames from "@/router/routes.js";

const redirectToThankYouPage = (to, hasCompletedOrder) => {
    if (to.path === routeNames.SUBSCRIPTION_THANK_YOU.path && !hasCompletedOrder) {
        return routeNames.STUDY_CONTENT.path;
    }
    return null;
};

const handleAuthRequiredRoutes = async (to, isUserLoggedIn) => {
    if (!isUserLoggedIn) {
        return { redirect: routeNames.LOGIN.path, query: { loginRequired: true } };
    }

    const accountInfoURL = `${routeNames.REGISTER.path}/${routeNames.REGISTER_ACCOUNT_INFO.path}`;
    const educationInfoURL = `${routeNames.REGISTER.path}/${routeNames.REGISTER_EDUCATION_INFO.path}`;
    const logout = routeNames.LOGOUT.path;
    // Check if the user has completed their profile in local storage
    let isProfileCompletionInLocalStorage = store.getters["Auth/getIsCompletedProfile"];

    // If not completed, check with the server
    if (isProfileCompletionInLocalStorage !== 'true') {
        const isProfileCompletedFromServer = await getUserProfileStatus(); // Fetch profile completion status from the server
        // If completed, update the local storage
        if (isProfileCompletedFromServer) {
            store.dispatch("Auth/updateIsCompletedProfile", true);
        }
        // Use the latest status for further checks
        isProfileCompletionInLocalStorage = isProfileCompletedFromServer;
    }

    // Handle routing based on profile completion status
    if (isProfileCompletionInLocalStorage) {
        // Redirect to study content if navigating to account or education info URLs
        if (to.path === accountInfoURL || to.path === educationInfoURL) {
            return { redirect: routeNames.STUDY_CONTENT.path };
        }
        return { continue: true }; // Continue with the requested route
    } else {
        // If the user has not completed their profile, redirect them to the appropriate page
        if (to.path !== accountInfoURL && to.path !== educationInfoURL && to.path !== logout) {
            return { redirect: accountInfoURL }; // Redirect to account info URL
        }
        return { continue: true }; // Allow access to the account or education info pages
    }
};


const handleGuestRequiredRoutes = (isLoggedIn) => {
    if (isLoggedIn) {
        return { redirect: routeNames.STUDY_CONTENT.path };
    }
    return { continue: true };
};

export const setupRouterGuard = (router) => {
    router.beforeEach(async (to, from, next) => {
        const isLoggedIn = store.getters["Auth/isAuth"];
        const hasCompletedOrder = store.getters["UserOrderCompletedSession/getHasCompletedOrder"];

        const requiresAuth = to.matched.some((rec) => rec.meta.requiresAuth);
        const requiresGuest = to.matched.some((rec) => rec.meta.requiresGuest);

        // Handle subscription thank you page redirection
        const thankYouRedirect = redirectToThankYouPage(to, hasCompletedOrder);
        if (thankYouRedirect) {
            return next({ path: thankYouRedirect });
        }

        // Handle authenticated routes
        if (requiresAuth) {
            const authResult = await handleAuthRequiredRoutes(to, isLoggedIn);
            if (authResult.redirect) {
                return next({ path: authResult.redirect, query: authResult.query });
            }
            if (authResult.continue) {
                return next();
            }
        }

        // Handle guest routes
        if (requiresGuest) {
            const guestResult = handleGuestRequiredRoutes(isLoggedIn);
            if (guestResult.redirect) {
                return next({ path: guestResult.redirect });
            }
        }

        // Handle home route for logged-in users
        if (isLoggedIn && to.path === routeNames.HOME.path) {
            return next({ path: routeNames.STUDY_CONTENT.path });
        }

        // Proceed with the next route
        return next();
    });
};
