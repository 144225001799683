<template>
  <div>
    <svgicon
      v-if="whiteColor"
      :data="EmptyBox"
      :width="width"
      :height="height"
      original
      class=""
    />
    <svgicon
      v-else
      :data="CorrectArrow"
      :width="width"
      :height="height"
      original
      class=""
    />
  </div>
</template>

<script>
import EmptyBox from "@/assets/svg/empty-box.svg";
import CorrectArrow from "@/assets/svg/correct-arrow.svg";
import windowSizeMixin from "@/mixins/windowSize";

export default {
  mixins: [windowSizeMixin],

  name: "CheckedIcon",
  data() {
    return {
      EmptyBox,
      CorrectArrow,
    };
  },
  props: {
    whiteColor: {
      type: Boolean,
      default: false,
      required: true,
    },
    inHeader: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 24,
    },
    height: {
      type: Number,
      default: 24,
    },
  },
};
</script>
