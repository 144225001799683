<template>
  <FormField
      inputType="password"
      name="password"
      :rules="isValidPassword"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
      id="password"
      :placeholder="placeholder"
  />
</template>


<script>
import { isValidPassword } from '@/validations/isValidPassword';
import FormField from "@/components/FormComponents/FormField.vue";

export default {
  name: 'PasswordInput',
  components: {FormField},
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'ادخل باسوورد جديد لحسابك'
    },

  },
  methods: {
    isValidPassword
  },
};
</script>


