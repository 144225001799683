<template>
  <div class="pt-mobile-top pb-mobile-bottom font-rtl" dir="rtl">
    <div class="flex flex-col mt-[64px] md:mt-[120px]">
      <MainBanner/>
      <WhyUs class="lg:mb-20"/>
      <Feedback class="my-10"/>
      <Info class="my-10"/>
      <ContactUs class="mt-[20px] mb-[60px] md:mt-[80px] md:mb-[120px]" :type="'WHATSAPP'" >"> تواصل معنا</ContactUs>
      <MainFooter/>
    </div>
  </div>
</template>

<script>
import MainBanner from "@/components/Landing/MainBanner.vue";
import WhyUs from "@/components/Landing/WhyUs.vue";
import Feedback from "@/components/Landing/Feedback.vue";
import Info from "@/components/Landing/Info.vue";
import ContactUs from '@/components/Landing/ContactUs.vue';
import MainFooter from "@/components/Landing/Footer.vue";

export default {
  name: "HomePage",
  data() {
    return {
      name: '',
    }
  },
  components: {
    MainFooter,
    MainBanner,
    WhyUs,
    Feedback,
    Info,
    ContactUs
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
.custom-header{
  position: fixed;
}
</style>
