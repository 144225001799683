<template>
  <span class="terms-and-conditions">
    بإنشائك حساب على المنصة فأنت توافق على <br/>
    شرط
      <router-link :to="{ name: 'TermsOfUse' }" class="privacy-link">
          <span class="terms-link">الاستخدام والخصوصية</span>
      </router-link>
  </span>
</template>

<script>
export default {
  name: 'TermsAndConditions'
};
</script>

<style scoped>
.terms-and-conditions {
  @apply text-gray-500 mt-3 md:mt-[62px] md:mb-4;
}

.terms-link {
  @apply font-medium text-black;
}
</style>
