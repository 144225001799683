<template>
  <div
    :dir="lessonDirection"
    class="bg-[#f4f6f8] px-4 pt-mobile-top flex flex-column min-h-screen items-center mt-[40px]"
  >
    <div class="flex-1 mx-auto w-full md:w-[58%]">
        <div>
          <BreadCrumb
            :dir="lessonDirection"
            :mainSection="lessons?.name"
            :subSection = "lessons?.label"
            :isFinished="lessons?.is_completed"
            :backPage="goBack"
          />

          <SectionFilter
            :filters="localizedFilters"
            :dir="lessonDirection"
            :showAllButton="true"
            :activeTab="activeTab"
            @changeTap="activeTab = $event"
            :isLessonPage="true"
          />
        </div>

        <Content
          v-if="activeLessons.length > 0"
          :data="activeLessons"
          :dir="lessonDirection"
          :isEnrolled="isEnrolled"
          :ContentType="activeTab"
          type="lesson"
        />
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/General/BreadCrumb.vue";
import SectionFilter from "@/components/General/SectionFilter.vue";
import Content from "@/components/General/Content.vue";
import { getLocalizedFilters } from "@/data/lessonFiltersData";
import routes from "@/router/routes.js";
import routeParamsMixin from "@/mixins/routeParams";
import windowSizeMixin from "@/mixins/windowSize";

export default {
  mixins: [routeParamsMixin, windowSizeMixin],

  name: "LessonContent",
  components: {
    BreadCrumb,
    SectionFilter,
    Content,
  },
  props: {
    lessons: {
      type: Object,
      required: true,
    },
    lessonsError: {
      type: [Object, Array, String, null],
      required: true,
    },
  },

  data() {
    return {
      activeTabValue: null,
    };
  },
  computed: {
    localizedFilters() {
      return getLocalizedFilters(this.lessonDirection);
    },
    isEnrolled() {
      return this.lessons?.is_enrolled;
    },
    lessonDirection() {
      return this.lessons?.direction || "RTL";
    },
    activeTab: {
      get() {
        return this.activeTabValue || "ALL";
      },
      set(newVal) {
        this.activeTabValue = newVal;
      },
    },
    activeLessons() {
      return this.lessons?.lessonTabs?.[this.activeTab] || [];
    },
  },
  methods: {
    goBack() {
      routes.navigateToCourse(this.courseSlug);
    },
  },
};
</script>
