import { gql } from '@apollo/client/core';

export const GET_RELIGION = gql`
query {
    courseClasses(input: { is_religion_class: ${true} }) {
        id
        name
    }
}
`;


export const GET_SECOND_LANGUAGES = gql`
query {
    courseClasses(input: { is_secondary_language: ${true} }) {
        id
        name
        status
        is_religion_class
    }
}
`;

export const GET_ALL_COURSE_CLASSES = gql`
    query {
        courseClasses {
            id
            name
            is_secondary_language
            is_religion_class
        }
    }
`;
