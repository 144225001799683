
export const sanitizePhoneNumber = (argumentValue) => {
    const sanitizedValue = argumentValue.replace(/^(\+2|٢\+)/, '');
    const englishValue = phoneNumberArabicToEnglish(sanitizedValue);
    return englishValue.replace(/\s+/g, '');
};

/**
 * Convert Arabic digits to English digits.
 *
 * @param {string} string
 * @returns {string}
 */
export  const phoneNumberArabicToEnglish = (string) => {
    const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    const englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

    return string.split('').map(char => {
        const index = arabicDigits.indexOf(char);
        return index !== -1 ? englishDigits[index] : char;
    }).join('');
};

