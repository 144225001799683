import { GET_CART  } from '@/graphql/queries/cart/getCart';
import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";


export const getCart =  () => {


    const { result, loading, error } = useQuery(GET_CART , null , {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
    })

    const cart = computed(() => result.value?.getCart ?? {});

    const fawryData = computed(() => {
        const fawryMethod = cart.value.paymentMethods?.find(
            (method) => method.simple_gateway === 'fawry'
        );
        return fawryMethod ? { ...fawryMethod } : null;
    });

    const vodafoneCashData = computed(() => {
        const vodafoneCashMethod = cart.value.paymentMethods?.find(
            (method) => method.simple_gateway === 'vodafonecash'
        );
        return vodafoneCashMethod ? { ...vodafoneCashMethod } : null;
    });

    return {
        cart,
        fawryData,
        vodafoneCashData,
        loading,
        error
    }
}

