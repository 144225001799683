<template>
<div class="flex flex-column min-h-screen items-center">
    <div class="w-full max-w-3xl"  :dir="direction">
        <ExerciseHeader class=" fixed-content" :gap="'gap-4'" :exerciseTitle="name" @x-icon-clicked="handleXIconClicked">
            <div class="flex align-center gap-3">
                <HeaderCounter :all="totalCount" :counter="currentIndex" v-if="!isMobile" />
                <CheckMarkIcon :whiteColor="!isCompleted" />
            </div>
        </ExerciseHeader>
    </div>
    <div class="container flex-1 mx-auto px-4">
        <div class="mt-[70px] flex flex-col items-center" v-html="body"></div>
    </div>

    <FooterBlur class="p-4" v-if="isMobile">
        <MobileNavigationButtons :dir="direction" :nextText="nextText" :isValidPrevious="true" :isValidNext="true" :previousButtonWidth="'35px'" :previousButtonHeight="'35px'" :nextButtonWidth="'277px'" :nextButtonHeight="'35px'" @back-clicked="handleBackClicked" @next-clicked="handleNextClicked" />
    </FooterBlur>

    <FooterBlur class="p-4" v-else>
        <NavigationButtons :dir="direction" :previousText="previousText" :nextText="nextText" :isValidNext="isValidNext" :isValidPrevious="isValidPrevious" @back-clicked="handleBackClicked" @next-clicked="handleNextClicked" />
    </FooterBlur>
</div>
</template>

<script>
import ExerciseHeader from "@/components/General/Header.vue";
import CheckMarkIcon from "@/components/Icons/CheckedIcon.vue";
import routeParamsMixin from '@/mixins/routeParams';
import contentDataMixin from '@/mixins/contentData';
import windowSizeMixin from '@/mixins/windowSize';
import FooterBlur from "@/components/General/FooterSection.vue";
import NavigationButtons from "@/components/General/NavigationButtons.vue";
import HeaderCounter from "@/components/General/HeaderCounter.vue";
import MobileNavigationButtons from "@/components/General/MobileNavigationButtons.vue";

export default {
    mixins: [routeParamsMixin, contentDataMixin, windowSizeMixin],
    name: "PaperSummaryContent",
    components: {
        FooterBlur,
        ExerciseHeader,
        CheckMarkIcon,
        NavigationButtons,
        HeaderCounter,
        MobileNavigationButtons
    },
    props: {
        contentDataProp: {
            type: String,
            default: 'paperSummary'
        },
        contentErrorDataProp: {
            type: String,
            default: 'paperSummaryError'
        },
        paperSummary: {
            type: Object,
            required: true
        },
        paperSummaryError: {
            type: [Object, Array, String, null],
            required: true
        }
    },

    data() {
        return {

        }
    },
    computed: {
        name() {
            return this.paperSummary?.name || '';
        },
        body() {
            return this.paperSummary?.body || '';

        },
    },

}
</script>

<style>
.container {
    max-width: 668px;
}

.container iframe {
    width: 100% !important;
    max-width: 100% !important;
}
</style>
