<template>
  <div class="w-full h-full flex flex-col content-center items-center justify-center flex-wrap" dir="ltr">
  <LoadingSpinner :is-loading="isLoading" />
  <BackButton class="absolute top-[11.5%] left-[88.5%] md:absolute md:top-[13.15%] md:left-[66.3%]"/>
  <p class="text-[30px] font-bold absolute top-[24.5%] md:top-[39.35%] left-auto">ادخل رقمك المسجل</p>

    <Form class="w-full absolute h-[378px] md:h-[340px] top-[34.625%] md:top-[47.8%] flex flex-col items-center justify-start" @submit="onSubmit" action="">

      <div class="w-full h-[150px] flex flex-col items-center">
          <PhoneNumberInput
              v-model="phoneNumber"
              placeholder="رقم تليفونك المسجل"
              :rules="isValidPhone"
              :fieldClass="'p-2 pr-[17px] md:pr-[29px] inputBorderStyle w-[284px] md:w-[375px] h-[54px] rounded-[10px] text-right absolute left-auto'"
              :errorClass="'text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-7 md:pr-2 mt-1'"
          >
            <template v-slot:icon>
              <svgicon :data="smartPhoneIcon" width="24" height="24" original  class="relative right-[40%] md:right-[33%]"/>
            </template>
          </PhoneNumberInput>
          <span class="text-[17px] text-black opacity-70">هنبعتلك كود تأكيد على الرقم</span>
      </div>

      <BlueButton type="submit" :customClass="'w-[284px] absolute top-[100%] left-auto h-[54px] m-auto rounded-[10px] bg-blue-custom text-white font-bold '">تأكيد الكود</BlueButton>

      <div class="p-5 items-center">
        <span class="block mb-4 font-bold text-blue-custom">الخطوات</span>
        <span class="block mb-2 pl-5 ">هيوصلك رسالة فيها رابط اضغط عليه</span>
        <span class="block mb-2 pl-5 ">دخل باسورد جديد</span>
        <span class="block mb-2 pl-5 ">بكدا غيرت الباسورد وتقدر تعمل تسجيل </span>
        <span class="block pl-5 relative "> دخول باسورد جديد</span>
      </div>
    </form>

  </div> 
</template>

<script>
import BackButton from '@/components/FormComponents/BackButton.vue';
import { Form } from 'vee-validate';
import { forgotPassword } from '@/graphql/mutations/forgotPassword/handlingCalls';
import PhoneNumberInput from "@/components/FormComponents/PhoneNumberInput.vue";
import { isValidPhone} from '@/validations/isValidPhone';
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import routes from "@/router/routes";
import smartPhoneIcon from '@svgicon/smartphone.svg';

export default {

  name: 'ForgotPasswordInitial',
  components:{
    BlueButton,
    PhoneNumberInput,
    Form,
    BackButton
  },
  data() {
        return {
          phoneNumber: '',
          smartPhoneIcon: smartPhoneIcon,
          isLoading: false,
        }
      },
    methods:{
      isValidPhone,
      async onSubmit(){

        await this.$withLoading(() => forgotPassword(this.phoneNumber));

        routes.navigateTo(routes.LOGIN.key);


      },

  },
}

</script>

