import { gql } from '@apollo/client/core';


export const GET_CART = gql`
    query GetCart{
        getCart {
            product_id
            price
            total
            is_valid_to_add_coupon
            coupon {
                code
                amount
                type
            }
            paymentMethods {
                id
                name
                description
                simple_gateway
                url
                phone_number
            }
        }
    }
`;
