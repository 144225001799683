<template>
  <span class="text-gray-custom mt-[77px] mb-[77px]">لو بتقابلك أي مشكلة في الدفع <br> <a :href=url target="_blank" class="text-blue-custom font-medium cursor-pointer">اضغط هنا</a></span>
</template>
    
<script>
  export default {
    name: 'FawryPrice',
    props:{
      url: {
        type: String,
      }
    },
  }
</script>