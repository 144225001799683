<template>
  <FormField
      inputType="text"
      :name="name"
      :rules="rules"
      :modelValue="modelValue"
      :fieldClass="styleClass"
      @update:modelValue="$emit('update:modelValue', $event)"
      :id="id"
      :placeholder="placeholder"
      :containerFieldClass="containerFieldClass"
      :errorClass="errorClass"
  >
    <template v-slot:icon>
      <slot name="icon"></slot>
    </template>
  </FormField>
</template>

<script>
import FormField from "@/components/FormComponents/FormField.vue";

export default {
  name: 'NameInput',
  components: { FormField },
  props: {
    name: {
      type: String,
      required: true
    },
    validation: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: [String, Object, Function],
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: true
    },
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      default: 'اكتب اسمك الأول بالعربي'
    },
    styleClass: {
      type: String,
      default: ''
    },
    containerFieldClass: {
      type: String,
      default: 'relative w-[284px] md:w-[500px] h-[54px] m-auto rounded-[10px] flex justify-center items-center'
    },
    errorClass: {
      type: String,
      default: 'text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1 mb-1'
    },
  },
};
</script>