<template>
  <div class="bg-[#f4f6f8] min-h-screen flex flex-col justify-center items-center absolute md:w-[calc(100%-260px)]">
    <BackButton class="absolute top-[9.5%] left-[85%] md:absolute md:top-[13.15%] md:left-[66.3%] z-[1000]"/>
    <div class=" bg-[#f4f6f8] w-full h-full flex flex-col absolute items-center ">
      <ActivationCodePrompt/>
      <PhoneVerificationMessage :registeredPhone="phoneNumber"/>

      <ChangeNumberLink :to="{ name: 'Register' }"/>

      <div class="w-1/2 md:min-h-[800px] flex flex-col content-center justify-center items-center flex-wrap">

        <form class="w-[284px] h-[354px] md:h-[465px] absolute top-[44.625%] md:top-[40.60%] left-auto" action="" @submit="onSubmit">

          <div class="mb-4">
            <VerificationCodeInput
                v-model:codes="codes"
                :reset="resetInputs"
                :hasError="verificationError"
            />

            <CountdownResend
                @resend="resendOTP"
                :phoneNumber="phoneNumber"/>
          </div>

          <BlueButton
              :customClass="' bg-blue-custom text-white text-[19px] font-bold rounded-[10px] w-[284px] absolute top-[84.5%] md:top-[88.25%] h-[54px] m-auto flex justify-center items-center'"
              id="submitBtn"
              type="submit">
            تأكيد الكود
          </BlueButton>
        </form>

      </div>
    </div>
  </div>
</template>

<script>
import {updateUserPhoneNumber} from '@/graphql/mutations/updateUserPhoneNumber/handlingCalls';
import ChangeNumberLink from "@/components/FormComponents/ChangeNumberLink.vue";
import ActivationCodePrompt from "@/components/FormComponents/ActivationCodePrompt.vue";
import BackButton from "@/components/FormComponents/BackButton.vue";
import PhoneVerificationMessage from "@/components/FormComponents/PhoneVerificationMessage.vue";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import VerificationCodeInput from "@/components/FormComponents/VerificationCodeInput.vue";
import CountdownResend from "@/components/FormComponents/CountdownResend.vue";
import {otpCookie} from "@/helpers/OTPCookie";
import routes from "@/router/routes";
import cookieEncryption from "@/helpers/cookieEncryption";
import {mapActions} from "vuex";

export default {
  name: 'signUpPhoneVerification',
  components: {
    CountdownResend, VerificationCodeInput, BlueButton,
    PhoneVerificationMessage, BackButton, ActivationCodePrompt, ChangeNumberLink,

  },
  data() {
    return {
      codes: ['', '', '', '', '', ''],
      phoneNumber: '',
      resetInputs: false,
      verificationError: false,
      errors: [],
      isLoading: false,
    }
  },
  methods: {
    ...mapActions({
      logoutUser: 'Auth/logoutUser',
    }),
    updateUserPhoneNumber,
    async onSubmit(event) {
      event.preventDefault();
      const enteredCode = this.codes.join('');
      const {sentOTP = ''} = otpCookie.getOTP(this.$cookies, this.phoneNumber) || {};

      if (enteredCode && sentOTP && enteredCode === sentOTP) {
        this.verificationError = false;

        const updateUserPhoneNumberResponse = await this.$withLoading(() => this.updateUserPhoneNumber(this.phoneNumber));

        if (updateUserPhoneNumberResponse.success) {
          cookieEncryption.deleteCookie(this.$cookies, 'elkhetaRegistrationData');
          otpCookie.deleteOTP(this.$cookies, this.phoneNumber);

          await this.logoutUser();
          routes.navigateTo(routes.LOGIN.key);
        } else {
          this.errors = updateUserPhoneNumberResponse.displayValidationMessages;
        }
      } else {
        this.verificationError = true;
      }

    },

    resendOTP() {

      this.resetInputs = true;
      this.verificationError = false;
      this.$nextTick(() => {
        this.resetInputs = false;
      });

    },
    onLoad() {
      const {studentPhone = ''} = cookieEncryption.getCookie(this.$cookies, 'elkhetaChangePhoneNumber') || {};

      this.phoneNumber = studentPhone;

    },
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.onLoad();
    next();
  },
}

</script>

