<template>
<div class="blur-content" :dir="dir">
    <div class="relative responsive-container">
        <div class="image-container w-[60px] h-[60px] flex justify-center items-center bg-gray-50 rounded-full border shadow-lg">
            <img loading="lazy" :src="bell" class="aspect-square w-[37px]" />
        </div>
        <PrerequisitesContent :title="title" :prerequisites="prerequisites"/>
        <button @click="closeBlur" class="w-full block text-center mt-4 text-black-custom font-bold-500 text-base">
            {{ dir === 'RTL' ? 'إغلاق' : 'Close' }}
        </button>
    </div>
</div>
</template>

<script>
import bell from "@/assets/images/bell.png";
import PrerequisitesContent from "@/components/Lesson/PrerequisitesContent.vue";

export default {
    name: 'LessonPrerequisites',
    components: {
        PrerequisitesContent
    },
    data() {
        return {
            bell
        }
    },
    props: {
        title: {
            type: String,
            default: "محتاج أولا تخلص:"
        },
        closeBlur: {
            type: Function
        },
        dir: {
            type: String
        },
        prerequisites: {
            type: Object
        }
    }
}
</script>
<style scoped>
.responsive-container {
    padding: 30px 0px;
  }
@media screen and (max-width: 767px) {
  .responsive-container {
    padding: 10px 0px;
  }
  img{
    width: 35px;
    height: 35px;
  }
}
</style>