<template>
    <div dir="rtl" class="bg-[#f4f6f8] mb-20 mt-[20%] md:mt-[5%] font-rtl">
      <div class="flex flex-col items-start bg-[#f4f6f8] mt-[4%] sm:mt-[2%] w-10/12 mx-auto dynamic-align mb-[5%] px-4">
        <BreadCrumb :gap="'gap-10'" mainSection="من نحن" subSection="" backPage="Home" mobileFontSize="20px" desktopFontSize="30px" class=" font-bold-700" :color="'black'" />
        <div>
          <div v-for="(item, index) in aboutUsData" :key="index" class="mt-8">
            <h2 class="text-xl font-bold-700 text-blue-custom">{{ item.title }}</h2>
            <p class="mt-2 font-bold-500 text-xl">{{ item.paragraph }}</p> 
            <div class="flex flex-col items-center gap-3">
                <img v-if="item.imageSrc" :src="require(`@/assets/images/about-us/${item.imageSrc}`)" :alt="item.imageAlt" class="mt-20">
                <span class="text-sm">{{ item.imageAlt }}</span>
            </div>
          </div>
        </div>       
      </div>
      <MainFooter/>
    </div>
  </template>
  
  <script>
  import BreadCrumb from "@/components/General/BreadCrumb.vue";
  import aboutUsData from "@/data/AboutUs.json";
  import MainFooter from "@/components/Landing/Footer.vue";
  
  export default {
    name: "AboutUs",
    components: {
      MainFooter,
      BreadCrumb,
    },
    data() {
      return {
        aboutUsData: aboutUsData.data,
      };
    },
    mounted() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
  };
  </script>
  
  <style scoped>
  /* Add your scoped styles here */
  </style>
  