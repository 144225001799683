<template>
  <div>
    <svg v-if="isRed" :width="width" :height="height" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75 6.33301V19.9163C8.75 20.152 8.75 20.2699 8.82322 20.3431C8.89645 20.4163 9.0143 20.4163 9.25 20.4163H25.0429C25.5566 20.4163 25.8135 20.4163 25.8774 20.262C25.9413 20.1077 25.7597 19.9261 25.3964 19.5628L19.2412 13.4075C19.1078 13.2742 19.0412 13.2075 19.0412 13.1247C19.0412 13.0418 19.1078 12.9752 19.2412 12.8418L25.3964 6.68656C25.7597 6.3233 25.9413 6.14167 25.8774 5.98734C25.8135 5.83301 25.5566 5.83301 25.0429 5.83301H9.25C9.0143 5.83301 8.89645 5.83301 8.82322 5.90623C8.75 5.97945 8.75 6.09731 8.75 6.33301Z"
        fill="#F9030F" />
      <rect x="8.75" y="4.375" width="1.45833" height="24.7917" rx="0.729167" fill="#222222" />
    </svg>

    <svg v-else :width="width" :height="height" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.75 6.3335V19.9168C8.75 20.1525 8.75 20.2704 8.82322 20.3436C8.89645 20.4168 9.0143 20.4168 9.25 20.4168H25.0429C25.5566 20.4168 25.8135 20.4168 25.8774 20.2625C25.9413 20.1082 25.7597 19.9265 25.3964 19.5633L19.2412 13.408C19.1078 13.2747 19.0412 13.208 19.0412 13.1252C19.0412 13.0423 19.1078 12.9757 19.2412 12.8423L25.3964 6.68705C25.7597 6.32379 25.9413 6.14215 25.8774 5.98783C25.8135 5.8335 25.5566 5.8335 25.0429 5.8335H9.25C9.0143 5.8335 8.89645 5.8335 8.82322 5.90672C8.75 5.97994 8.75 6.09779 8.75 6.3335Z"
        fill="#2A4157" fill-opacity="0.24" />
      <rect x="8.75" y="4.375" width="1.45833" height="24.7917" rx="0.729167" fill="#222222" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "FlagIcon",
  props: {
    width: {
      type: Number,
      default: 35,
    },
    height: {
      type: Number,
      default: 35,
    },
    isRed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
