<template>
    <div dir="rtl" class="bg-[#f4f6f8] mb-20 mt-[20%] md:mt-[5%] font-rtl">
        <div class="flex flex-col items-start bg-[#f4f6f8] mt-[4%] sm:mt-[2%] w-10/12 mx-auto dynamic-align mb-[5%]">
            <BreadCrumb :gap="'gap-10'" mainSection="سياسة الخصوصية" subSection="" backPage=" Home" mobileFontSize="20px"
                desktopFontSize="30px" class="font-bold-700" :color="'black'" />
            <div class="mt-[20px ]">
                <div v-for="(item, index) in PrivacyPolicy" :key="index" class="text-lg font-bold-500">
                    <p v-if="item.type === 'paragraph'" v-html="item.content"></p>
                    <ul v-if="item.type === 'list'" class="mx-5">
                        <li v-for="(listItem, index) in item.content" :key="index">
                            {{ listItem }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <MainFooter />

    </div>
</template>

<script>
import BreadCrumb from "@/components/General/BreadCrumb.vue";
import PrivacyPolicy from "@/data/PrivacyPolicy.json";
import MainFooter from "@/components/Landing/Footer.vue";

export default {
    name: "PrivacyPolicy",
    components: {
        MainFooter,
        BreadCrumb,
    },
    data() {
        return {
            PrivacyPolicy: PrivacyPolicy.data,
        };
    },
    mounted() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },
};
</script>

<style scoped>
p {
    display: block;
}

ul {
    list-style-type: disc;
    padding-left: 20px;
}
</style>
