<template>
  <div :class="fontClass">
    <LoadingSpinner :is-loading="isLoadingExercise" />
    <ExerciseInfoContent v-if="!exerciseError" :exercise="exercise" :exerciseError="exerciseError"/>
    <Page404Content v-else-if="exerciseError && isContentNotFoundError"/>
    <NotEnrolledData v-else-if="exerciseError && isContentNotEnrolledError" :goTo="handleNotEnrolledNavigation"/>
    <PrerequisitesContent v-else-if="exerciseError && isContentRequiredPreRequisitesError" title="محتاج اولا تخلص" :prerequisites="contentErrorMessageData"/>

  </div>
</template>

<script>
import ExerciseInfoContent from "@/components/Exercise/ExerciseInfoContent.vue";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import Page404Content from "@/components/Page404/Page404Content.vue";
import routeParamsMixin from '@/mixins/routeParams';
import windowSizeMixin from '@/mixins/windowSize';
import contentDataMixin from '@/mixins/contentData';
import {handleErrors} from "@/helpers/graphqlQueryErrorResponseHandler";
import {getExerciseContent} from "@/graphql/queries/exercise/handlingCalls";
import PrerequisitesContent from "@/components/Lesson/PrerequisitesContent.vue";
import NotEnrolledData from "@/components/Lesson/NotEnrolledData.vue";
import { getFontClass } from '@/helpers/getFontClassHelper';


export default {
  mixins: [routeParamsMixin,contentDataMixin ,  windowSizeMixin],

  name: "ExercisePage",
  components: {
    NotEnrolledData, PrerequisitesContent,
    Page404Content,
    LoadingSpinner,
    ExerciseInfoContent,
  },
  props: {
    contentDataProp: {
      type: String,
      default: 'exercise'
    },
    contentErrorDataProp: {
      type: String,
      default: 'exerciseError'
    },
  },
  data() {
    return {
      exercise: {},
      exerciseError: null,
      isLoadingExercise: false,
    }
  },
  computed: {
    fontClass() {
      return getFontClass(this.direction);
    },
  },
  methods: {
    getFontClass,
    fetchExerciseContent() {
      const {exerciseContent, loading, error} = getExerciseContent(this.exerciseSlug);

      this.$watch(
          () => error.value,
          (newVal) => {
            this.exerciseError = handleErrors(newVal?.graphQLErrors || []);
          },
          {immediate: true}
      );

      this.$watch(
          () => exerciseContent.value,
          (newVal) => {
            this.exercise = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.isLoadingExercise = newVal;
          },
          {immediate: true}
      );
    },

    onLoad() {
      this.fetchExerciseContent();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.getRouteParamsData(to.params));
    this.onLoad();
    next();
  },
};
</script>
