<template>
  <div class="flex flex-col justify-center items-center w-full gap-4 font-bold-700">
    <h1 class="title">
      المنصة الشاملة رقم 1
    </h1>
    <span class="text-blue-custom subtitle  mb-4">
        انضم لاكثر من 500 ألف طالب مع الخطة
    </span>
    <div class="w-full 3xl:w-6/12 flex flex-col mx-auto items-center font-bold-700 text-lg md:text-xl">
      <div v-if="!isAuth" class="flex flex-col gap-4">
        <router-link class="" :to="{ name: 'Login' }">
          <ButtonCustom :bg-color="'bg-yellow-custom'" className="py-2 px-10 rounded-full text-black-custom"  :width="'250px'" :height="'49px'">
            تسجيل دخول
          </ButtonCustom>
        </router-link>
        <router-link class="" :to="{ name: 'Register' }">
          <ButtonCustom :bg-color="'bg-blue-custom'" class="text-white" :width="'250px'" :height="'49px'">
            فتح حساب جديد
          </ButtonCustom>
        </router-link>
      </div>
      <div v-else>
        <router-link class="" :to="{ name: 'studyContent' }">
          <ButtonCustom :bg-color="'bg-yellow-custom'" className="py-2 px-10 rounded-full text-black-custom" :width="'250px'" :height="'49px'">
            المحتوى الدراسى
          </ButtonCustom>
        </router-link>
      </div>

    </div>

    <PhoneLogo class="mt-[3%]"/>
  </div>
</template>

<script>
import ButtonCustom from "@/components/General/ButtonCustom.vue";
import PhoneLogo from "@/components/Landing/PhoneLogo.vue"
import store from "@/store";

export default {
  name: "MainBanner",
  components: {
    ButtonCustom,
    PhoneLogo
  },
  computed: {
    isAuth() {
      return store.getters['Auth/isAuth'];
    },
  },
}
</script>

<style scoped>
.title {
  font-size: 64px;
}

.subtitle {
  font-size: 30px;
}

@media (max-width: 768px) {
  .title {
    font-size: 30px;
  }

  .subtitle {
    font-size: 18px;
  }
}
</style>
