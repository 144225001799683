
export default {
  install(app) {
    app.config.globalProperties.$isNotEmpty = function(value) {
      if (value == null) return false;

      if (typeof value === 'string') {
        return value.trim().length > 0;
      }

      if (typeof value === 'object') {
        if (Array.isArray(value)) {
          return value.length > 0;
        } else {
          return Object.keys(value).length > 0;
        }
      }

      return true;
    };
    app.config.globalProperties.$withLoading = async function (apiCall) {
      this.isLoading = true;
      try {
        const response = await apiCall();
        this.isLoading = false;
        return response;
      } catch (error) {
        this.isLoading = false;
        throw error;
      }
    };
  }
};
