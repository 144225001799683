import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";
import {PAPER_SUMMARY} from "@/graphql/queries/paperSummary/paperSummary";

export const getPaperSummaryContent =  (slug) => {


    const { result, loading, error } = useQuery(PAPER_SUMMARY
        ,
        {
            slug : slug
        },
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        }
    )

    const paperSummaryContent = computed(() => result.value?.paperSummaryContent ?? []);

    return {
        paperSummaryContent,
        loading,
        error
    }
}