<template>
  <div>
    <svgicon
      :data="EyeOffIcon"
      :width="width"
      :height="height"
      original
      :class="style"
    />
  </div>
</template>

<script>
import EyeOffIcon from "@/assets/svg/eye-off-icon.svg";

export default {

  name: "EyeOffIcon",
  data() {
    return {
      EyeOffIcon,
    };
  },
  props: {
    width: {
        type: Number,
        default: 15
    },
    height: {
      type: Number,
      default: 15
    },
    style:{
      type: String,
      default: ''
    }
  },
};
</script>
