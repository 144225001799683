<template>
  <div class=" absolute top-[1.5%] md:top-[5.5%] flex flex-col items-center w-full z-[999]">
    <LoadingSpinner :is-loading="isLoading" />
    <BackButton id="backButton"
      class="absolute top-[9.5%] left-[85%] md:absolute md:top-[13.15%] md:left-[66.3%] z-[1000]" />

    <StartAndLog />

    <GirafeImage />

    <div id="container" class="w-full flex flex-column content-center justify-center flex-wrap" dir="ltr">

      <Form class="w-full" @submit="onSubmit" action="">
        <PhoneNumberInput v-model="phoneNumber" :rules="isValidPhone" />

        <PasswordInput v-model="password"
          :eyeClass="'absolute w-[20px] h-[20px] left-[5%] md:left-[21%] cursor-pointer ml-2 opacity-70'" />

        <ConfirmPasswordInput v-model="password_confirmation" :password="password"
          :eyeClass="'absolute w-[20px] h-[20px] left-[5%] md:left-[21%] cursor-pointer ml-2 opacity-70'" />


        <div v-if="errors.length > 0" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-right pr-2 mt-1">
          <span v-for="(error, index) in errors" :key="index">
            {{ error }}
          </span>
        </div>

        <BlueButton type="submit" class="mt-[33px] mb-[15px]">انشاء الحساب</BlueButton>
      </form>

      <LoginLink />
      <RequiredOTPValidationMessage />

      <TermsAndConditions />
    </div>
  </div>

  <OutSideEgyptPopup />
</template>

<script>
import BackButton from '@/components/FormComponents/BackButton.vue';
import StartAndLog from '@/components/FormComponents/StartAndLog.vue';
import GirafeImage from '@/components/FormComponents/GirafeImage.vue';
import TermsAndConditions from '@/components/FormComponents/TermsAndConditions.vue';
import OutSideEgyptPopup from "@/components/SignUp/OutSideEgyptPopup.vue";
import LoginLink from "@/components/FormComponents/LoginLink.vue";
import BlueButton from "@/components/FormComponents/BlueButton.vue";
import PhoneNumberInput from "@/components/FormComponents/PhoneNumberInput.vue";
import PasswordInput from "@/components/FormComponents/PasswordInput.vue";
import ConfirmPasswordInput from "@/components/FormComponents/ConfirmPasswordInput.vue";
import { Form } from 'vee-validate';
// import { requestOTP } from '@/graphql/mutations/requestOTP/handlingCalls';
import { isInternationalPhoneOtherThanEgypt, isValidPhone } from '@/validations/isValidPhone';
import cookieEncryption from '@/helpers/cookieEncryption.js';
import routes from '@/router/routes.js';
import { otpCookie } from "@/helpers/OTPCookie";
import RequiredOTPValidationMessage from "@/components/FormComponents/RequiredOTPValidationMessage.vue";
import { phoneNumberArabicToEnglish } from "@/helpers/phoneNumberFunctions";
import { initializeRegistration } from '@/graphql/mutations/register/handlingCalls';
import { mapActions } from 'vuex';


export default {

  name: 'signUpInitial',
  components: {
    RequiredOTPValidationMessage,
    ConfirmPasswordInput,
    PasswordInput,
    PhoneNumberInput,
    BlueButton,
    LoginLink,
    OutSideEgyptPopup,
    BackButton,
    StartAndLog,
    GirafeImage,
    TermsAndConditions,
    Form,
  },
  data() {
    return {
      phoneNumber: '',
      password: '',
      password_confirmation: '',
      errors: '',
      localSentOTP: '',
      isLoading: false
    }
  },
  methods: {
    ...mapActions({
      registerUser: 'Auth/registerUser',
    }),
    isValidPhone,
    phoneNumberArabicToEnglish,
    async onSubmit() {
      this.errors = [];

      const phoneField = document.getElementById("phoneNumber");
      const backButtonField = document.getElementById("backButton");
      const startAndLogField = document.getElementById("startAndLog");
      const girafeField = document.getElementById("girafe");
      const containerField = document.getElementById("container");
      const popupField = document.getElementById("popup");
      const blurryField = document.getElementById("blurry");


      const showPopup = () => {


        backButtonField.classList.add('blurred');
        startAndLogField.classList.add('blurred');
        girafeField.classList.add('blurred');
        containerField.classList.add('blurred');
        popupField.classList.remove('popup');
        popupField.classList.add('popup-display');
        blurryField.classList.add('blurry');


      };



      // const handleOTPResponse = async (otpResponse) => {
      //
      //   if (otpResponse.success === false){
      //     this.localSentOTP = '';
      //     this.errors = otpResponse.displayValidationMessages;
      //   }else{
      //     this.localSentOTP = otpResponse.data.otp;
      //   }
      //
      //   otpCookie.setOTP(this.$cookies , phoneNumberArabicToEnglish(this.phoneNumber) , {
      //     'isValidSentOTP' : false,
      //     'sentOTP': this.localSentOTP
      //   });
      //   if (this.localSentOTP){
      //     routes.navigateTo(routes.REGISTER_OTP.key);
      //   }
      //
      // };


      if (isInternationalPhoneOtherThanEgypt(phoneField.value)) {
        showPopup();
        return;
      }

      const userData = {
        phone_number: phoneNumberArabicToEnglish(this.phoneNumber),
        password: this.password,
        password_confirmation: this.password_confirmation
      };

      cookieEncryption.setCookie(this.$cookies, "elkhetaRegistrationData", userData, 1);


      // const { isValidSentOTP = false } = otpCookie.getOTP(this.$cookies ,phoneNumberArabicToEnglish(this.phoneNumber) ) || {};
      //
      // if (!isValidSentOTP){
      //
      //   const otpResponse = await this.$withLoading(() => requestOTP(phoneNumberArabicToEnglish(this.phoneNumber)));
      //
      //   handleOTPResponse(otpResponse);
      // }else{

      otpCookie.setOTP(this.$cookies, phoneNumberArabicToEnglish(this.phoneNumber), {
        'isValidSentOTP': true,
        'sentOTP': '123456'
      });

      //
      // }


      let registerResponse = await this.$withLoading(() => initializeRegistration(userData));

      if (registerResponse.success === false) {
        this.errors = registerResponse.displayValidationMessages;
      } else {
        const token = registerResponse.data.token;
        await this.registerUser(token, this.$cookies);

        cookieEncryption.deleteCookie(this.$cookies, 'elkhetaRegistrationData')
        otpCookie.deleteAllOTP(this.$cookies);

        routes.navigateTo(routes.REGISTER_ACCOUNT_INFO.key); // Move to next page

      }

    },
    onLoad() {

      const { studentPhone = '', studentP = '' } = cookieEncryption.getCookie(this.$cookies, 'elkhetaRegistrationData') || {};

      this.phoneNumber = studentPhone;
      this.password = studentP;


    },
  },


  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.onLoad();
    next();
  },



}

</script>

<style scoped>
Form i {
  margin-left: -30px;
  cursor: pointer;
}

.blurred {
  @apply blur-[5px] transition duration-300 ease-in-out
}
</style>