import { useQuery } from '@vue/apollo-composable';
import {
    GET_EDUCATION_TYPES,
    GET_EDUCATION_TYPES_WITHOUT_CLASSES_SECTIONS
} from '@/graphql/queries/educationTypes/educationsTypes';
import {computed} from "vue";



export const getEducationTypes =  () => {
    const { result, loading, error } = useQuery(GET_EDUCATION_TYPES_WITHOUT_CLASSES_SECTIONS , null ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const educationTypes = computed(() => result.value?.educationTypes ?? []);

    return {
        educationTypes,
        loading,
        error
    }

}

export const getEducationTypesWithClasses =  () => {
    const { result, loading, error } = useQuery(GET_EDUCATION_TYPES , null ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const educationTypes = computed(() => result.value?.educationTypes ?? []);

    return {
        educationTypes,
        loading,
        error
    }

}


