<template>
  <div v-if="isVisible" class="overlay" :class="{ 'overlay-clickable': closeOnClickOutside }"
    @click="handleOverlayClick">
    <div class=" w-full">
      <div :class="contentClasses" :style="contentStyle" @click.stop>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlurComponent",
  props: {
    coordinates: {
      type: Boolean,
      default: false,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: false,
    },
    widthClass: {
      type: String,
      default: 'w-9/12'
    },
    mdWidthClass: {
      type: String,
      default: 'md:w-6/12'
    }
  },
  data() {
    return {
      isVisible: false,
      contentStyle: {},
    };
  },
  computed: {
    contentClasses() {
      return `${this.widthClass} ${this.mdWidthClass} mx-auto content`;
    }
  },
  methods: {
    showBlur(event) {
      this.isVisible = true;
      if (this.coordinates) {
        this.setPosition(event.clientX, event.clientY);
      }
    },
    hideBlur() {
      this.isVisible = false;
    },
    setPosition(x, y) {
      this.contentStyle = {
        position: "absolute",
        top: `${y}px`,
        left: `${x}px`,
      };
    },
    handleOverlayClick() {
      if (this.closeOnClickOutside) {
        this.hideBlur();
      }
    },
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 38, 0.3);
  /* Lighter background color and opacity */
  backdrop-filter: blur(5px);
  /* Lighter blur effect */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  /* Ensure it is on top of other content */
  cursor: not-allowed;
  /* Disable cursor */
}

.overlay-clickable {
  cursor: initial;
}

.content {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  /* Darker shadow */
  cursor: default;
  /* Enable cursor inside the content */
  pointer-events: auto;
  /* Allow interactions inside the content */
}
</style>
