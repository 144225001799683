<template>
  <div class="filter-section font-bold-400 flex justify-around" dir="rtl">
    <button :class="['scroll-button', 'left', { 'disabled': !canSelectPrevious }]" @click="selectPrevious">
      <svg class="mx-auto" width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.33393 15C1.59513 15 1.85634 14.9236 2.08734 14.7654C4.61232 13.0294 8.84846 9.77768 8.84846 7.49791C8.84846 5.21814 4.61232 1.96818 2.08733 0.233925C1.47786 -0.18187 0.649819 -0.0290561 0.234023 0.578645C-0.181772 1.18457 -0.0271816 2.01438 0.576966 2.43196C3.39158 4.36523 6.01429 6.75872 6.18665 7.4446C6.01429 8.2371 3.39158 10.6324 0.576968 12.5692C-0.0289565 12.9868 -0.18177 13.8166 0.234026 14.4225C0.493454 14.7992 0.909249 15 1.33393 15Z"
          fill="black" />
      </svg>
    </button>
    <div class="holder flex-1" ref="holder">
      <div class="filters-container" ref="filtersContainer">
        <button v-for="(tab, key) in visibleFilters" :key="key" class="filter-button"
          :class="{ selected: tab.id === selectedFilter }" @click="selectFilter(tab)">
          <div class="filter-content flex flex-row gap-1 items-center align-center justify-center">
            <div class=""
              :class="{ 'font-bold-700': tab.id === selectedFilter, 'font-bold-400': tab.id !== selectedFilter }">
              {{ tab.name }}
            </div>
            <div v-show="tab.icon" class="aspect-square w-[24px]">
              <img :src="tab.icon?.full_link" :alt="tab.icon?.name" />
            </div>
          </div>
        </button>
      </div>
    </div>
    <button :class="['scroll-button', 'right', { 'disabled': !canSelectNext }]" @click="selectNext">
      <svg class="mx-auto" width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.66607 1.16616e-07C7.40487 1.39451e-07 7.14366 0.0764066 6.91266 0.234552C4.38768 1.97059 0.151544 5.22232 0.151544 7.50209C0.151544 9.78186 4.38769 13.0318 6.91267 14.7661C7.52214 15.1819 8.35018 15.0291 8.76598 14.4214C9.18177 13.8154 9.02718 12.9856 8.42303 12.568C5.60842 10.6348 2.98571 8.24128 2.81335 7.5554C2.98571 6.7629 5.60842 4.36763 8.42303 2.4308C9.02896 2.01323 9.18177 1.18342 8.76598 0.577494C8.50655 0.200789 8.09075 7.94889e-08 7.66607 1.16616e-07Z"
          fill="black" />
      </svg>
    </button>
  </div>
</template>
<script>
import windowSize from '@/mixins/windowSize';


export default {
  mixins: [windowSize],
  name: "FilterSection",
  data() {
    return {
      selectedFilter: null,
      currentPage: 0,
      items: [],
    };
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.defaultSelectedItem();
    this.createItems();
  },
  computed: {
    visibleFilters() {
      if (this.isMobile) {
        return this.filters;
      }
      return this.items[this.currentPage] || [];
    },
    canSelectPrevious() {
      return this.currentPage > 0 && !this.isMobile;
    },
    canSelectNext() {
      return this.currentPage < this.items.length - 1 && !this.isMobile;
    },
  },
  methods: {
    defaultSelectedItem() {
      if (this.filters.length > 0) {
        this.selectedFilter = this.filters[0].id;
        this.$emit("selected-filter", this.filters[0]);
      }
    },
    selectFilter(tab) {
      this.selectedFilter = tab.id;
      this.$emit("selected-filter", tab);
    },
    selectPrevious() {
      if (this.canSelectPrevious) {
        this.currentPage--;
      }
    },
    selectNext() {
      if (this.canSelectNext) {
        this.currentPage++;
      }
    },
    createItems() {
      const holderWidth = this.getHolderWidth();
      let startIndex = 0;
      this.items = [];

      while (startIndex < this.filters.length) {
        const { slicedItems, nextStartIndex } = this.sliceItems(startIndex, holderWidth);
        this.items.push(slicedItems);
        startIndex = nextStartIndex;
      }
    },

    sliceItems(startIndex, holderWidth) {
      let accumulatedWidth = 0;
      let itemsCount = 0;
      let showOnNextItem = false;

      for (let i = startIndex; i < this.filters.length; i++) {
        const tab = this.filters[i];
        const itemWidth = this.getTextWidth(tab.name, this.getFont());
        const remainingSpace = holderWidth - accumulatedWidth;
        showOnNextItem = remainingSpace > 0 && (remainingSpace <= itemWidth * 0.5);
        const showLastItem = remainingSpace > 0;

        if (accumulatedWidth + itemWidth <= holderWidth) {
          itemsCount++;
          accumulatedWidth += itemWidth;
        } else {
          if (showLastItem) {
            itemsCount++;
          }
          break;
        }
      }

      const slicedItems = this.filters.slice(startIndex, startIndex + itemsCount);
      const nextStartIndex = showOnNextItem ? startIndex + itemsCount - 1 : startIndex + itemsCount;

      return { slicedItems, nextStartIndex };
    },

    getTextWidth(text, font) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const margin = 10;
      const padding = 10;
      const icon = 24;
      context.font = font;
      const magic = 4;
      const textWidth = context.measureText(text).width;
      const totalWidth = textWidth + icon + margin + padding + magic;

      canvas.remove();
      return totalWidth;
    },
    getFont() {
      return '400 16px Tajawal, sans-serif';
    },
    getHolderWidth() {
      return this.$refs.holder.clientWidth - 20; 
    },
  },
};
</script>

<style scoped>
.holder {
  width: calc(51.67vw - 12.08vw);
}

.filter-section {
  position: relative;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.filters-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 10px;
  overflow-x: hidden;
}

.filters-container::-webkit-scrollbar {
  display: none;
}

.scroll-button {
  width: 37px;
  height: 37px;
  font-size: 17px;
  border: none;
  background-color: #e4e6e7;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s;
  margin: 0 5px;
}

.scroll-button.left {
  margin-right: 10px;
}

.scroll-button.right {
  margin-left: 10px;
}

.scroll-button.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  padding: 7px 10px;
  text-align: center;
  border: none;
  background-color: #f1f1f9;
  cursor: pointer;
  border-radius: 17px;
  white-space: nowrap;
  transition: background-color 0.3s;
}

.filter-content {
  display: flex;
}

.filter-content span {
  margin-right: 5px;
  color: #343232;
}

.filter-button img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.filter-button.selected {
  background-color: #ffd700;
  font-weight: 700;
}

@media (max-width: 768px) {
  .holder {
    width: 100%;
  }

  .scroll-button {
    width: 34px;
    height: 34px;
    font-size: 17px;
  }

  .scroll-button {
    display: none;
  }

  .filters-container {
    overflow-x: scroll;
  }
}
</style>
