<template>
    <div class="w-[668px] mx-auto font-bold-700 text-lg">
      <div :class="[width,'flex justify-between items-center mx-auto']">
        <div class="flex">
          <ButtonCustom
            :width="previousButtonWidth"
            class="rounded-full bg-light-gray-custom text-gray-font items-center gap-3"
            @click="handleBackClicked"
          >
          <DirectionalBackIcon v-if="isValidPrevious" color="text-gray-font" :isBack="true" :dir="dir"/> 
            {{ previousText }}
          </ButtonCustom>
        </div>
        <ButtonCustom
          :width="nextButtonWidth"
          class="flex flex-row gap-3 items-center rounded-full"
          :class="isValidNext ? 'bg-blue-custom text-white' : 'bg-light-gray-custom text-gray-fon'"
          @click="handleNextClicked"
          :bgColor="'bg-blue-custom'"
        >
          {{ nextText }} 
          <DirectionalBackIcon v-if="isValidNext" color="white" :is-back="false" :dir="dir"/> 
        </ButtonCustom>
      </div>
    </div>
  </template>
  
  <script>
import DirectionalBackIcon from "@/components/Icons/DirectionalArrowIcon.vue";
import ButtonCustom from "@/components/General/ButtonCustom.vue";

  export default {
    components: {
      DirectionalBackIcon,
      ButtonCustom
    },
    name: 'NavigationButtons',
    props: {
      previousText: {
        type: String,
        default: 'Previous'
      },
      nextText: {
        type: String,
        default: 'Next'
      },
      isValidNext: {
        type: Boolean,
      },
      isValidPrevious: {
        type: Boolean,
      },
      dir: {
        type: String,
        default: "RTL",
      },
      width: {
        type: String,
        default: "w-full"
      },
      previousButtonWidth: {
        type: String,
        default: "w-[154px]"
      },
      nextButtonWidth: {
        type: String,
        default: "w-[277px]"
      },
    },
    methods: {
      handleBackClicked() {
        this.$emit('back-clicked');
      },
      handleNextClicked() {
        this.$emit('next-clicked');
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  