import { useMutation } from '@vue/apollo-composable';
import { handleResponse } from '@/helpers/graphqlResponseHandler';
import { decryptOTP } from '@/helpers/OTPEncryption.js';
import {REQUEST_OTP} from "@/graphql/mutations/requestOTP/requestOTP";


export const requestOTP = (async (phoneNumber) => {

    const { mutate } = useMutation(
        REQUEST_OTP, {
            errorPolicy: 'all',
            fetchPolicy: 'no-cache',
            variables: {
                phone_number: phoneNumber,
            },
        },
        );

    try {
        const response = await mutate();

        const transformedResponse = handleResponse(response , 'requestOTP');

        if (transformedResponse.success === false){
            return transformedResponse;
        }else{
            transformedResponse.data.otp = decryptOTP(transformedResponse.data.otp);
            return transformedResponse;
        }



    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }
})
