<template>
  <div class="grid grid-cols-12 justify-center align-center gap-2 md:gap-0">
    <div class="col-span-11 flex flex-col w-full sm:w-10/12 mx-auto my-4 border shadow-sm rounded-lg" :class="{
      'bg-green-custom text-white': isCorrectAnswer,
      'border-answer-error-no-selected': !selectedAnswer && isCorrectAnswer,
      'bg-white text-answer-error border-answer-error': isWrongAnswer,
      'bg-white': !isCorrectAnswer && !isWrongAnswer,

    }" @click="handleClick">
      <div class="flex flex-row gap-2 py-2 px-4 rounded-lg w-full items-center">
        <span v-if="answerLabel"
          class="bg-circle-gray rounded-full p-2 text-black font-bold w-7 h-7 flex items-center justify-center" :class="{
            'circle-answer-error': isWrongAnswer,
          }">
          {{ answerLabel }}
        </span>
        <span v-if="isTextAnswer || isNumericAnswer">{{ answerBody }}</span>
        <div v-if="isTextAndPhotoAnswer">
          <p v-if="answerBody">{{ answerBody }}</p>
          <img v-if="answerPhoto" :src="answerPhoto" alt="Answer Photo">
        </div>
      </div>
    </div>
    <div class="col-span-1">
       <RightAndWrongIcons :isRight="isCorrectAnswer" v-if="answer.id === selectedAnswer || isCorrectAnswer"/>
    </div>
  </div>
</template>

<script>
import RightAndWrongIcons from '@/components/Exercise/Answers/RightAndWrongIcons.vue';
export default {
  name: 'AnswerChoicesResult',
  components: { RightAndWrongIcons },
  props: {
    answer: {
      type: Object,
      required: true,
    },
    selectedAnswer: {
      type: [String, Number],
      required: false,
      default: null,
    },
    isCorrectAnswer: {
      type: Boolean,
    },
    isWrongAnswer: {
      type: Boolean,
    }
  },
  computed: {
    answerId() {
      return this.answer.id;
    },
    answerLabel() {
      return this.answer.label;
    },
    answerBody() {
      return this.answer.body;
    },
    answerPhoto() {
      return this.answer.photo;
    },
    isTextAnswer() {
      return this.answer.body && !this.answer.photo;
    },
    isNumericAnswer() {
      return !isNaN(this.answer.body);
    },
    isTextAndPhotoAnswer() {
      return this.answer.body && this.answer.photo;
    },
  },
  methods: {},
};
</script>

<style scoped>
.text-answer-error {
  color: #EC1212 !important;
}

.border-answer-error {
  border: 1px solid #EC1212 !important;
}

.border-answer-error-no-selected {
  border: 2px solid #EC1212 !important;
}

.circle-answer-error {
  background-color: #EC1212 !important;
  color: white !important;
}
</style>
