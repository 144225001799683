export const isValidEducationStage = (value, educationTypes, educationType) => {
    let educationStageInput = document.getElementById('educationStage');

    if (!(value && value.trim()) || value === '0' || value === 0) {
        educationStageInput?.classList.remove('goodBorder');

        return '*الرجاء اختيار المرحلة الدراسية.';
    }
    const selectedEducationType = educationTypes.find(type => type.id === educationType);

    if (selectedEducationType) {
        const educationStageExists = selectedEducationType.educationStages.some(stage => stage.id === value);

        if (!educationStageExists) {
            educationStageInput?.classList.remove('goodBorder');

            return '*المرحلة الدراسية المحددة غير صالحة.';
        }
    } else {
        educationStageInput?.classList.remove('goodBorder');

        return '*نوع التعليم المحدد غير صالح.';
    }

    if (educationStageInput){
        educationStageInput.classList.add('goodBorder');
    }
    return true;
};