<template>
  <div class="w-[284px] mx-auto mt-6">
    <div class="w-full flex justify-between">
        <span class="text-gray-custom text-[14px]">المبلغ</span>
        <span class="text-gray-custom text-[14px]">{{ total }} جنيه</span>
    </div>
    <div class="w-full flex justify-between text-gray-400 border-b-2 ">
        <span class="text-gray-custom text-[14px]">قيمة الخصم</span>
        <span class="text-gray-custom text-[14px]">{{ discount }} جنيه</span>
    </div>
    <div class="w-full flex justify-between text-gray-400 ">
        <span class="text-gray-custom text-[14px]">المبلغ النهائي</span>
        <span class="text-gray-custom text-[14px]">{{ afterDiscount }} جنيه</span>
    </div>
</div>
</template>
    
<script>
  export default {
    name: 'FawryPrice',
    props:{
      total: {
        type: [String , Number],
      },
      discount: {
        type: [String , Number],
      },
      afterDiscount: {
        type: [String , Number],
      }
    },
  }
</script>