<template>
  <div :class="fontClass">
    <LoadingSpinner :is-loading="isCoursesLoading" />
    <CourseInnerContent v-if="!coursesError" :courses="courses" :coursesError="coursesError" />
    <Page404Content v-else />
  </div>
</template>

<script>

import {
  getCourseContent
} from "@/graphql/queries/studyContent/handlingCalls";
import routeParamsMixin from "@/mixins/routeParams";
import { handleErrors } from "@/helpers/graphqlQueryErrorResponseHandler";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";
import CourseInnerContent from "@/components/Course/CourseInnerContent.vue";
import Page404Content from "@/components/Page404/Page404Content.vue";
import { getFontClass } from '@/helpers/getFontClassHelper';


export default {
  mixins: [routeParamsMixin],
  name: "StudyCourses",
  components: {
    Page404Content,
    LoadingSpinner,
    CourseInnerContent

  },
  data() {
    return {
      courses: [],
      activeCourses: [],
      coursesError: null,
      isCoursesLoading: true,
      direction: null,
    };
  },
  computed: {
    fontClass() {
      return getFontClass(this.direction);
    },
  },

  methods: {
    getFontClass,
    fetchCourses() {
      const {
        courseContent,
        loading,
        error
      } = getCourseContent(this.courseSlug, "no-cache");

      this.$watch(
        () => courseContent.value,
        (newVal) => {
          this.courses = newVal;
          this.direction = newVal?.course_direction;

        }, {
        immediate: true,
      }
      );

      this.$watch(
        () => error.value,
        (newVal) => {
          this.coursesError = handleErrors(newVal?.graphQLErrors || []);
        },
        { immediate: true }
      );

      this.$watch(
        () => loading.value,
        (newVal) => {
          this.isCoursesLoading = newVal;
        }, {
        immediate: true,
      }
      );
    },
    onLoad() {
      this.fetchCourses();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.getRouteParamsData(to.params));
    this.onLoad();
    next();
  },
};
</script>

<style scoped></style>
