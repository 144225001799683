<template>
  <div class=" min-h-screen flex flex-column pt-16   justify-center items-center ">
    <SignUp/>
  </div>
</template>

<script>

import SignUp from "../../components/SignUp/SignUpInitial.vue";

export default {
  name: "RegisterPage",
  components: {
    SignUp
  }, 
  methods: {

  },
}

</script>

<style scoped>

</style>