<template>
  <router-link :to="to">
    <a :class="computedClass" href="">{{ linkText }}</a>
  </router-link>
</template>

<script>
export default {
  name: 'CustomRouterLink',
  props: {
    to: {
      type: Object,
      required: true
    },
    linkText: {
      type: String,
      default: 'تغيير الرقم'
    },
    customClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    computedClass() {
      return this.customClass ? this.customClass : 'custom-router-link';
    }
  }
};
</script>

<style scoped>
.custom-router-link {
  @apply  top-[36.75%] md:top-[33.79%] left-[calc(50%-37.5px)] text-blue-custom underline;
}
</style>
