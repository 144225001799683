import {useMutation} from "@vue/apollo-composable";
import {handleResponse} from "@/helpers/graphqlResponseHandler";
import {UPDATE_USER_EDUCATION_SECTION} from "@/graphql/mutations/updateUserEducationSection/updateUserEducationSection";

export const updateUserEducationSection = (async (educationSectionId) => {

    const { mutate } = useMutation(UPDATE_USER_EDUCATION_SECTION, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            education_section_id: parseInt(educationSectionId),
        },
    });

    try {
        const response = await mutate();
        return handleResponse(response , 'updateUserEducationSection');

    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }

})