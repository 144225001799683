<template>
<svg v-if="!isWhite" @click="handleClick()" class="cursor-pointer" :width="width" :height="height" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M18.5 4.625C7.40308 4.625 3.46875 8.55933 3.46875 19.6562C3.46875 30.7532 7.40308 34.6875 18.5 34.6875C29.5985 34.6875 33.5312 30.7532 33.5312 19.6562C33.5312 8.55933 29.5985 4.625 18.5 4.625Z" fill="#808080" />
    <path d="M22.2006 24.5017C22.4966 24.5017 22.7926 24.3876 23.0176 24.1625C23.4694 23.7108 23.4694 22.9785 23.0176 22.5268L20.1286 19.6392L23.0007 16.7656C23.4524 16.3139 23.4524 15.5831 23.0007 15.1314C22.549 14.6797 21.8182 14.6797 21.3665 15.1314L18.4929 18.0051L15.6161 15.1283C15.1644 14.6766 14.4336 14.6766 13.9819 15.1283C13.5302 15.58 13.5302 16.3108 13.9819 16.7625L16.8587 19.6392L13.9819 22.5191C13.5302 22.9708 13.5302 23.7015 13.9819 24.1532C14.207 24.3799 14.5046 24.4924 14.799 24.4924C15.095 24.4924 15.391 24.3799 15.6161 24.1532L18.4944 21.275L21.3819 24.1625C21.6086 24.3876 21.903 24.5017 22.2006 24.5017Z" fill="black" />
</svg>
<svgicon v-else :data="WhiteX" :width="width" :height="height" class="cursor-pointer" original @click="handleClick()"/>
</template>

<script>
import WhiteX from "@/assets/svg/white-x.svg";
export default {
    name: "XBackIcon",
    data() {
        return {
            WhiteX,
        };
    },
    components: {},
    props: {
        backPage: {
            type: String,
            default: "",
        },
        width: {
            type: String,
        },
        height: {
            type: String,
        },
        isWhite: {
            type: Boolean,
            default: false,
        }
        
    },
    methods: {
        handleClick() {
            this.$emit("x-icon-clicked");
        },
    },
};
</script>
