<template>
    <div class="w-full lg:w-95 mx-auto bg-blue-custom text-white-custom lg:rounded-4xl lg:pb-16">
        <h5 class="font-bold-700 text-lg lg:text-40px header ">
            انضم ل 500 ألف طالب بيذاكر مع الخطة
        </h5>
        <VideoCarousel /> 
    </div>

</template>
<script>
import VideoCarousel from "@/components/Landing/VideoCarousel.vue";
export default {
    name: "FeedbackLanding",
    components: {
        VideoCarousel
    }
}
</script>
 
<style scoped>
    .header {
        padding: 20px 0;
    }
    @media screen and (min-width: 768px) {
        .header
        {
            padding: 38px 0 56px 0;
        }
    }
</style>