<template>
  <div class="  text-17px max-w-3xl lg:w-4/12 2xl:w-3/12 mx-auto">
    <div class="flex justify-center items-center mt-[15%] mb-[8%] lg:font-bold-600 text-lg">
      <h1 class="text-blue-custom text-xl">{{ name }}</h1>
    </div>

    <div v-if="$isNotEmpty(numberOfQuestions)" class="flex flex-col bg-white-custom rounded-xl mt-6 item-center">
      <div class="grid grid-cols-4 justify-between text-center py-3 rounded-xl gap-10 px-10">
        <div class="flex flex-row gap-2 items-center flex-grow col-span-3">
          <svgicon :data="Layer" :width="24" :height="24" original/>

          <div class="text-blue-custom">{{ numberOfQuestionsText }}</div>
        </div>
        <div class="items-center justify-center text-xl leading-7 text-zinc-800">
          {{ numberOfQuestions }}
        </div>
      </div>
      <hr>
      <div  v-if="$isNotEmpty(exerciseTime)" class="grid grid-cols-4 justify-between text-center w-full py-3 rounded-xl gap-10 px-10">
        <div class="flex flex-row gap-2 items-center flex-grow col-span-3">
          <ClockIcon :width="24" :height="24" :isBlue="true" />
          <div class="text-blue-custom">{{ durationInMinutesText }}</div>
        </div>
        <div class="flex items-center justify-center text-xl leading-7 text-zinc-800">
          {{ exerciseTime }}
        </div>
      </div>
      <hr>
      <div v-if="$isNotEmpty(difficultyLevel)" class="grid grid-cols-4 justify-between text-center w-full py-3 rounded-xl gap-10 px-10">
        <div class="flex flex-row gap-2 items-center flex-grow col-span-3">
          <svgicon :data="Pen" :width="24" :height="24" original/>

          <div class="text-blue-custom">{{ difficultyLevelText }}</div>
        </div>
        <div class="flex items-center justify-center text-base leading-7 text-zinc-800 ">
          {{ difficultyLevel }}
        </div>
      </div>
    </div>

    <div v-if="!isMobile"
         class="text-white-custom text-3xl-h5 bg-blue-custom py-3 rounded-xl mt-10 text-center start-button mx-auto cursor-pointer font-bold "
         @click="start">{{ buttonText }}
    </div>
  </div>
</template>

<script>
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import Layer from "@/assets/svg/blue/Layer.svg";
import Pen from "@/assets/svg/blue/pen.svg";

import windowSizeMixin from "@/mixins/windowSize";
import contentDataMixin from "@/mixins/contentData";

export default {
  mixins: [windowSizeMixin , contentDataMixin],

  name: "ExerciseInfo",
  components: {
    ClockIcon
  },
  props: {
    contentDataProp: {
      type: String,
      default: 'exercise'
    },
    contentErrorDataProp: {
      type: String,
      default: 'exerciseError'
    },
    exercise: {
      type: Object,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
      default: "Start Exercise"
    }
  },
  data() {
    return {
      Layer,
      Pen
    }
  },
  computed: {
    name(){
      return this.exercise?.name || '';
    },
    numberOfQuestions(){
      return this.exercise?.number_of_questions || 0;
    },
    exerciseTime(){
      return this.exercise?.exercise_time || 0;
    },
    difficultyLevel(){
      return this.exercise?.difficulty_level || '';
    }
  },
  methods: {
    start() {
      this.$emit('start');
    }
  }
};
</script>

<style scoped>
.start-button {
  max-width: 300px;
  height: 49px;
}
.text-17px{
  font-size: 17px;
}
</style>
