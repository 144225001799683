<template>
  <div class="flex justify-around items-center mx-auto flex-wrap min-h-screen mt-[66px] md:mt-0 w-[953px]">

    <LoadingSpinner :is-loading="studentOtherServicesLoading" />

    <div v-if="studentOtherServices && studentOtherServices.length > 0" @click="goBack()"
         class="absolute right-[7%] md:right-[36%] lg:right-[29%] top-[10.5%] md:top-[37px] md:w-[30px] md:h-[30px] w-[20px] h-[20px] cursor-pointer flex justify-center items-center bg-[#CCCCCC] bg-opacity-40 rounded-[10px] z-[2]">
      <svgicon :data="backButtonIcon" width="30" height="30" original />
    </div>
    <div class="w-full h-[61px] md:hidden"></div>
    <div v-if="studentOtherServices && studentOtherServices.length > 0"  class="flex justify-center items-center flex-wrap relative md:max-w-[calc(100vw-256px)] z-[1]" dir="rtl">
      <div v-for="studentService in studentOtherServices" :key="studentService.id" class="flex justify-around items-center flex-wrap mt-[10px] md:mt-16 mx-2 md:mx-0 md:mr-[33px]">
        <SubscriptionCard :service="studentService"/>
      </div>
    </div>
    <div v-if="!studentOtherServices || studentOtherServices.length === 0" class="flex justify-center items-center h-screen w-full" dir="rtl">
      لايوجد اشتراك بمواد فردية حاليا
    </div>

  </div>
</template>

<script>
import SubscriptionCard from "@/components/Subscriptions/SubscriptionCard.vue";
import backButtonIcon from '@svgicon/back-button.svg';
import routes from "@/router/routes";
import {studentOtherServices} from "@/graphql/queries/subscriptions/handlingCalls";


export default {
  name: "SubscriptionPage",
  components: {
    SubscriptionCard,
  },
  data() {
    return {
      studentOtherServices: [],
      studentOtherServicesLoading : true,
      backButtonIcon: backButtonIcon
    }
  },
  methods: {
    fetchStudentsOtherServices () {
      const {studentOtherServices: services,  loading} = studentOtherServices();

      this.$watch(
          () => services.value,
          (newVal) => {
            this.studentOtherServices = newVal;
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.studentOtherServicesLoading = newVal;
          },
          {immediate: true}
      );
    },
    goBack() {
      routes.navigateTo(routes.SUBSCRIPTIONS.key);
    },
    onLoad() {
      this.fetchStudentsOtherServices();

    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.onLoad();
    next();
  },
}
</script>