<template>
    <div class="w-full mb-[10px]">
      <div id="feature" v-html="formattedFeatures" class="features cursor-pointer"></div>
    </div>
</template>

<script>
import formatFeatures from '@/mixins/formatFeatures';

  export default {
    mixins: [formatFeatures],

    name: 'CardFeatures',
    props:{
      features: {
        type: String,
      }
    },
  }
</script>