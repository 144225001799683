import { STUDENT_SERVICES  } from '@/graphql/queries/subscriptions/studentServices';
import { STUDENT_OTHER_SERVICES  } from '@/graphql/queries/subscriptions/studentOtherServices';
import { STUDENT_ENROLLMENT  } from '@/graphql/queries/subscriptions/studentEnrollments';
import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";


export const studentServices =  () => {

    const { result, loading, error } = useQuery(STUDENT_SERVICES , null ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const studentServices = computed(() => result.value?.studentServices ?? []);

    return {
        studentServices,
        loading,
        error
    }
}


export const studentOtherServices =  () => {

    const { result, loading, error } = useQuery(STUDENT_OTHER_SERVICES , null ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const studentOtherServices = computed(() => result.value?.studentOtherServices ?? []);

    return {
        studentOtherServices,
        loading,
        error
    }

}


export const studentEnrollment =  () => {
    const { result, loading, error } = useQuery(STUDENT_ENROLLMENT , null ,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const studentCourseEnrollments = computed(() => result.value?.studentCourseEnrollments ?? []);

    return {
        studentCourseEnrollments,
        loading,
        error
    }

}