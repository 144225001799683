import { gql } from '@apollo/client/core';

export const EXERCISE_START = gql`
    mutation ExerciseStart(
        $slug: String!,
    ) {
        startExercise(input: {
            slug: $slug,
        }) {
            id
            remaining_minutes
            exercise {
                id
                name
                slug
                is_exam
                direction
            }
            questions {
                id
                body
                full_photo_link
                answers {
                    id
                    answer_type
                    is_correct
                    body
                    label_letter
                    full_photo_link
                }
                modelAnswer {
                    id
                    body
                    full_photo_link
                    url
                }
            }
        }
    }
`;
