<template>
  <div v-if="showValidationMessage" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-center pr-2 mt-1 pt-20">
    لقد تخطيت عدد مرات المحاولة
  </div>
</template>


<script>


export default {
  name: 'NumberOfTriesError',
  data() {
    return {
      showValidationMessage: false
    };
  },
  mounted() {

    if (this.$route.query.numberOfTriesError) {
      this.showValidationMessage = true;
      this.$router.replace({query: null});

    }


  }
};
</script>
