<template>
  <div v-if="showValidationMessage" class="text-[#C50000] w-[284px] md:w-[375px] m-auto text-center pr-2 mt-1">
    يجب تأكيد رقم الموبايل اولا
  </div>
</template>


<script>

import {clearRequiredOTPValidation} from "@/helpers/OTPCookie";

export default {
  name: 'RequiredOTPValidationMessage',
  data() {
    return {
      showValidationMessage: false
    };
  },
  mounted() {

    if (this.$route.query.requiredOTPValidation) {
      this.showValidationMessage = true;
      clearRequiredOTPValidation.clear(this.$route, this.$router);
    }




  }
};
</script>
