<template>
  <span  class="text-[#EC1212] mx-1">{{ errorCoupon }}</span>
</template>
    
<script>
  export default {
    name: 'FawryErrorCouponIcon',
    props:{
      errorCoupon:{
        type: String
      }
    },
  }
</script>