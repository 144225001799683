<template>
  <button :class="computedClass" @click="$emit('click' , $event)">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'BlueButton',
  props: {
    customClass: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  computed: {
    computedClass() {
      return this.customClass ? this.customClass : 'w-[284px] relative md:w-[375px] h-[54px] m-auto rounded-[10px] flex justify-center items-center bg-blue-custom text-white font-bold';
    }
  }
};
</script>
