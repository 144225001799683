import {gql} from "@apollo/client/core";

export const LESSON_CONTENT = gql`
    query GetLessonContent($slug: String!){
        lessonContent(input: {
            slug: $slug
        }) {
            name
            label
            is_enrolled
            is_completed
            direction
            lessonTabs {
                ALL {
                    name
                    slug
                    content_type
                    is_completed
                    duration_in_minutes #video
                    exercise_time #exercise
                    number_of_questions #exercise
                    is_exam #exercise
                    number_of_papers #paper Summaries
                    incomplete_prerequisites {
                        name
                        slug
                        type
                    }
                }
                videos{
                    name
                    slug
                    duration_in_minutes
                    is_completed
                    incomplete_prerequisites {
                        name
                        slug
                        type
                    }
                }
                exercises{
                    name
                    slug
                    exercise_time
                    is_exam
                    is_completed
                    number_of_questions
                    incomplete_prerequisites {
                        name
                        slug
                        type
                    }
                }
                paperSummaries{
                    name
                    slug
                    is_completed
                    number_of_papers
                }

            }

        }
    }
`;