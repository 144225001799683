<template>
  <div v-if="success" class="w-[284px] md:w-[375px] m-auto mt-4 flex justify-center items-center">
    <svgicon :data="successIcon" width="17" height="17" original/>
    <ValidCouponText :successCoupon="success"/>
  </div>
</template>

<script>
import ValidCouponText from './ValidCouponText.vue';
import successIcon from '@svgicon/success-green.svg';

export default {
  name: 'FawryValidCoupon',
  props: {
    success: {
      type: String
    }
  },
  data (){
    return {
      successIcon: successIcon
    }
  },
  components: {
    ValidCouponText
  }
}
</script>