<template>
  <div class="flex items-center gap-2">
    <svgicon v-if="type" :data="icon" width="20" height="20" original :fill="true" />
    <router-link v-if="isInternalLink" :to="url" class="text-blue-custom text-sm font">
      {{ text }}
    </router-link>
    <a v-else :href="url" target="_blank" class="text-blue-custom text-sm font">
      {{ text }}
    </a>
  </div>
</template>

<script>
import facebookIcon from "@/assets/svg/landing/social/facebook.svg";
import youtubeIcon from "@/assets/svg/landing/social/youtube.svg";
import tiktokIcon from "@/assets/svg/landing/social/tiktok.svg";
import instagramIcon from "@/assets/svg/landing/social/instagram.svg";

export default {
  name: "FooterLine",
  props: {
    url: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    type: {
      type: [String, null],
      required: true,
    },
  },
  computed: {
    icon() {
      switch (this.type?.toLowerCase()) {
        case "facebook":
          return facebookIcon;
        case "youtube":
          return youtubeIcon;
        case "tiktok":
          return tiktokIcon;
        case "instagram":
          return instagramIcon;
        default:
          return null;
      }
    },
    isInternalLink() {
      return this.url.startsWith("/");
    },
  },
};
</script>

<style scoped>
/* Add any additional styling here */
</style>