import {gql} from "@apollo/client/core";

export const DELETE_USER = gql`
    mutation DeleteUser($source: DeletedFrom!) {
        deleteUserAccount(input: {
            source: $source
        }) {
            message
            status
        }
    }
`;
