<template>
  <div>
    <loading :active="isLoading" :is-full-page="fullPage" :can-cancel="canCancel"
             :opacity="1"
             :color="color" :background-color="backgroundColor">
      <fulfilling-bouncing-circle-spinner :animation-duration="4000" :size="100" :color="color" />
    </loading>
  </div>
</template>

<script>
import { FulfillingBouncingCircleSpinner } from 'epic-spinners';
import Loading from 'vue-loading-overlay';

export default {
  components: {
    FulfillingBouncingCircleSpinner,
    Loading,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    canCancel: {
      type: Boolean,
      default: false,
    },
    fullPage: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '#007bff',
    },
    backgroundColor: {
      type: String,
      default: 'rgba(255, 255, 255, 1)',
    },
  },
};
</script>

<style scoped>
@import 'vue-loading-overlay/dist/css/index.css';
</style>