<template>
    <span class="text-gray-custom text-[14px] ml-[8px] md:ml-[43px]">#{{orderId}}</span>  
</template>
    
<script>
  export default {
    name: 'CurrentSubscribedCardOrderId',
    props:{
      orderId: {
        type: String,
      }
    },
  }
</script>