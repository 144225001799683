<template>
  <div class="popup-button-container" @click="handleContactUsClick">
    <LoadingSpinner :is-loading="isLoadingContactUs"/>
    <span class="popup-button">
      <span class="popup-button-icon">
        <img class="popup-button-img" :src="require('@images/whatsappIcon.png')" alt="whatsapp">
      </span>
      <span class="popup-button-text">تواصل معنا</span>
    </span>
  </div>
</template>

<script>

import contactUs from "@/mixins/contactUs";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";

export default {
  components: {LoadingSpinner},
  mixins: [contactUs],
  name: 'ContactUsButton',
  methods: {
    
  }
};
</script>

<style scoped>
.popup-button-container {
  @apply flex relative ms-0 w-[199px] h-[39px] md:h-auto;
}
.popup-button {
  @apply h-full md:h-[39px] w-full cursor-pointer hover:scale-105 md:w-[199px] text-xs md:text-[17px] flex items-center justify-center bg-[#25D366] text-[#fff] rounded-[33px];
}
.popup-button-icon {
  @apply rounded-full absolute right-[4px] md:right-[4px] bg-white w-[31px] h-[31px];
}
.popup-button-img {
  @apply cursor-pointer rounded-full md:m-auto;
}
.popup-button-text {
  @apply md:me-2 font-bold text-[15px] md:text-[17px] md:ms-0;
}
</style>
