<template>

<div class="relative w-full mb-6">
  <div class="flex items-center">
    <div class="line-container flex-grow border-t border-gray-300 relative">
      <div class="flex items-center justify-center rounded-full bg-gray-icon absolute -bottom-2 md:-bottom-3 left-8 md:left-27% w-4 h-4 md:w-6 md:h-6 gray text-10px md:text-17px font-bold-500">
        {{ index }}
      </div>
    </div>
  </div>
</div>

</template>

<script>
export default {
  name: "ExamLine",
  props: {
      index: {
        type: Number,
        required: true
      }
  }
}
</script>

<style scoped>
.gray {
    background-color: #D9D9D9;
    color: black
}
</style>