import {useQuery} from "@vue/apollo-composable";
import {computed} from "vue";
import {CONTACT_US} from "@/graphql/queries/contactUs/contactUs";

export const getContactUsURL =  () => {

    const { result, loading, error } = useQuery(CONTACT_US , null,
        {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',

        })

    const contactUsURL = computed(() => result.value?.contactUs ?? []);


    return {
        contactUsURL,
        loading,
        error
    }

};