<template>
    <Blur ref="deleteAccount" widthClass="w-[327px]" mdWidthClass="md:w-[590px]" :close-on-click-outside="true">
        <div class="blur-content">
            <div class="responsive-container">
                <div
                    class="image-container bg-gray-50 rounded-full border shadow-md h-14 w-14 flex items-center justify-center">
                    <FlagIcon :isRed="true" />
                </div>

                <div class="text-container flex flex-col gap-4 w-full text-center justify-center align-center">

                    <div v-if="errorMessage" class="error-message text-red-600 text-center mt-4">
                        {{ errorMessage }}
                    </div>

                    <div class="text-sm md:text-19px">هل تريد بالفعل حذف الحساب بشكل نهائى</div>
                    <input v-model="confirmationInput" class="border rounded-lg w-11/12" type="text"
                        placeholder="اكتب هنا كلمة “OK” لتاكيد حذف حسابك" />
                </div>

                <div
                    class="flex flex-row gap-5 justify-center items-center text-gray-custom px-4 mt-10 buttons-holder text-center align-center">
                    <ButtonCustom @click="deleteAccount" :disabled="!isConfirmed" :class="{
                        'bg-blue-custom text-white': isConfirmed,
                        'bg-gray-font text-white cursor-not-allowed': !isConfirmed,
                    }" className="rounded-full p-4 text-base font-bold" height="35px" width="164px">
                        حذف حسابى
                    </ButtonCustom>
                </div>

                <div class="red-color text-right mt-10 px-4 md:px-0 text-sm md:text-17px">
                    <p class="font-bold">تحذير</p>
                    <p>بحذف حسابك سوف تفقد الوصول بشكل نهائى لهذا الحساب</p>
                    <p>بحذف حسابك لن تستطيع التسجيل بنفس الرقم مره أخرى</p>
                </div>

            </div>
        </div>
    </Blur>

    <div class="min-w-[90%] md:w-[80%] md:max-w-[662px] md:min-w-[408px]">
        <hr class="border-black px-4 mt-10 mb-2 lg:my-10 w-full">
        <DeleteAccountBtn @click="handleDeleteAccountClick" class="flex w-[102px] h-[23px] mt-[20px]" />
    </div>
</template>

<script>
import Blur from "@/components/General/Blur.vue";
import DeleteAccountBtn from "@/components/Profile/DeleteAccountBtn.vue";
import FlagIcon from "@/components/Icons/FlagIcon.vue";
import ButtonCustom from "@/components/General/ButtonCustom.vue";
import { deleteUser } from "@/graphql/mutations/deleteUser/handlingCalls";

export default {
    name: "DeleteAccount",
    components: {
        Blur,
        DeleteAccountBtn,
        FlagIcon,
        ButtonCustom,
    },
    data() {
        return {
            confirmationInput: "",
            confirmWord: "ok",
            errorMessage: null,
        };
    },
    computed: {
        isConfirmed() {
            return this.confirmationInput.toLowerCase() === this.confirmWord;
        },
    },
    methods: {
        handleDeleteAccountClick() {
            this.$refs.deleteAccount.showBlur();
        },
        cancelDeleteAccount() {
            this.$refs.deleteAccount.hideBlur();
        },
        async deleteAccount() {
            this.errorMessage = null;
            const deleteAccountResponse = await this.$withLoading(() => deleteUser());
            console.log('deleteAccountResponse', deleteAccountResponse);

            if (deleteAccountResponse?.data?.status) {
                location.reload();
            } else {
                this.errorMessage = deleteAccountResponse.message || "An error occurred. Please try again."; // Set error message
            }
        },

    },
};
</script>

<style scoped>
.responsive-container {
    padding: 0px 0px 35px;
}

.red-color {
    color: var(--red, #EC1212);
}
</style>
