import {gql} from "@apollo/client/core";

export const ASK_TEACHERS = gql`
    query {
        askTeachers {
            label
            url
            is_student_enrolled
        }
    }
`;
