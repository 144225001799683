import { gql } from '@apollo/client/core';

export const RESET_PASSWORD = gql`
    mutation ResetPassword(
        $token: String!,
        $password: String!,
        $password_confirmation: String!,
    ) {
        resetPassword(input: {
            token: $token
            password: $password
            password_confirmation: $password_confirmation
        })
    }
`;
