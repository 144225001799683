<template>
  <span>
    <span v-if="productPrice" class="text-blue-custom text-[17px] font-medium cursor-pointer">{{productPrice}}</span>

    <span class="text-gray-custom " v-if="productDiscountText">&nbsp;&nbsp;{{productDiscountText }}</span>
  </span>
</template>
    
<script>
  export default {
    name: 'CardProductPrice',
    props:{
      productPrice: {
        type: String,
      },
      productDiscountText: {
        type: String,
      }
    },
  }
</script>