<template>
    <button class="btn font-bold">تجديد الاشتراك</button>  
</template>
    
<script>
    export default {
        name: 'RenewSubsButton',
}
</script>

<style scoped>
    .btn{
      background-color: #1C9DEB;
      color: white;
      font-size: 16px;
      width: 284px;
      height: 35px;
      border-radius: 20px;
      margin: 8px 0px 16px 0px;
    }
</style>