import { useMutation } from '@vue/apollo-composable';
import {handleResponse} from "@/helpers/graphqlResponseHandler";
import {EXERCISE_SUBMIT} from "@/graphql/mutations/submitExercise/submitExercise";

export const submitExercise = (async (exerciseId , answers) => {

    const { mutate } = useMutation(EXERCISE_SUBMIT, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            id: exerciseId,
            answers: answers

        },
    });


    try {
        const response = await mutate();

        return handleResponse(response , 'submitExercise');


    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }

})