<template>
  <div class="px-4 py-1 bg-white rounded-2xl flex flex-row gap-1 items-center justify-center text-center">
    <svgicon :data="clockIcon" :width="width" :height="height" original :fill="false"/>

    <span class="text-gradient">
      {{ displayTime }}
    </span>
  </div>
</template>

<script>
import clockIcon from '@/assets/svg/blue/clock-gradiant.svg';

export default {
  name: 'TimerBox',
  props: {
    remainingTime: {
      type: [String , Number],
      required: true
    },
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      clockIcon,
      timer: null,
      displayTime: ''
    };
  },
  watch: {
    remainingTime: {
      immediate: true,
      handler(newValue) {
        this.startTimer(newValue);
      }
    }
  },
  methods: {
    startTimer(time) {
      clearInterval(this.timer);

      if (!time)
        return;

      const [minutes, seconds] = time.split(':').map(Number);
      let remainingSeconds = minutes * 60 + seconds;

      this.displayTime = time;

      this.timer = setInterval(() => {
        remainingSeconds--;

        if (remainingSeconds <= 0) {
          clearInterval(this.timer);
          this.displayTime = '00:00';
          this.$emit('timeup');
        } else {
          const updatedMinutes = Math.floor(remainingSeconds / 60);
          const updatedSeconds = remainingSeconds % 60;
          this.displayTime = `${updatedMinutes.toString().padStart(2, '0')}:${updatedSeconds.toString().padStart(2, '0')}`;
        }
      }, 1000);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  }
};
</script>