<template>
<div class="flex flex-col items-start">
    <h6 class="text-13px lg:text-base font-bold-700">{{ item.title }}</h6>
    <div class="flex flex-col items-start">
        <span v-for="(link, linkIndex) in item.links" :key="linkIndex" class="py-[4px]">
            <FooterLink :text="link.text" :url="link.url" :type="link.type" />
        </span>
    </div>
</div>
</template>

<script>
import FooterLink from "@/components/Footer/FooterLink.vue";
export default {
    name: "FooterSection",
    components: {
        FooterLink,
    },
    props: {
        item: Object,
    },
};
</script>
