import {gql} from "@apollo/client/core";
import {navigationFragment} from '@/graphql/fragments/navigationFragments';

export const VIDEO_CONTENT = gql`
    query GetVideoContent($slug: String!){
        videoContent(input: {
            slug: $slug
        }) {
            id
            name
            slug
            url
            content
            duration_in_minutes
            direction
            is_completed
            navigation {
                ...LessonContentNavigation
            }
        }
    }
    ${navigationFragment}

`;