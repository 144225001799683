<template>
  <div class="check-mark-icons">
    <CheckedIcon  :whiteColor="!isFinished" v-if="isFinished" :width="width" :height="height"/>
    <svg
      v-else
      :width="width"
      :height="height"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 2.34375C5.00208 2.34375 2.34375 5.00208 2.34375 12.5C2.34375 19.9979 5.00208 22.6562 12.5 22.6562C19.9979 22.6562 22.6562 19.9979 22.6562 12.5C22.6562 5.00208 19.9979 2.34375 12.5 2.34375Z"
        fill="#EAEAF3"
      />
    </svg>
  </div>
</template>

<script>
import CheckedIcon from "@/components/Icons/CheckedIcon.vue";

export default {
  name: "CheckMarkIcon",
  components: {
    CheckedIcon,
  },
  props: {
    isFinished: {
      type: Boolean,
      required: false,
    },
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 30,
    },
  },
};
</script>

<style scoped>
.text-blue {
  color: #1c9deb;
}

.gray {
  color: #eaeaf3;
}
</style>
