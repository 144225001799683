<template>
  <div class=" min-h-screen flex flex-column justify-center items-center ">
    <LoadingSpinner :is-loading="isLoadingAskTeachers" />

    <AskTeacher :askTeachers="askTeachers"/>
  </div>
</template>

<script>
import AskTeacher from "@/components/AskTeacher/AskTeacher.vue";
import {getAskTeachers} from "@/graphql/queries/askTeachers/handlingCalls";
import LoadingSpinner from "@/components/General/LoadingSpinner.vue";

export default {
  name: "AskTeacherPage",
  components: {
    LoadingSpinner,
    AskTeacher
  },
  data() {
    return {
      askTeachers: {},
      isLoadingAskTeachers: false,
    }
  },
  methods: {
    fetchAskTeachers() {
      const {askTeachersContent, loading} = getAskTeachers();


      this.$watch(
          () => askTeachersContent.value,
          (newVal) => {
            this.askTeachers = newVal || {};
          },
          {immediate: true}
      );

      this.$watch(
          () => loading.value,
          (newVal) => {
            this.isLoadingAskTeachers = newVal;
          },
          {immediate: true}
      );
    },
    onLoad() {
      this.fetchAskTeachers();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onLoad();
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.onLoad();
    next();
  },
}

</script>
