export const isValidReligionSubject = (value, religionSubjects, religionSubject) => {
    let religionSubjectInput = document.getElementById('eduReligionSubject');

    if (!(value && value.trim()) || value === '0' || value === 0) {
        religionSubjectInput?.classList.remove('goodBorder');

        return true;
    }
    const selectedReligionSubject = religionSubjects.find(type => type.id === religionSubject);

    if (!selectedReligionSubject) {
        religionSubjectInput?.classList.remove('goodBorder');

        return '*مادة التربية الدينية المحددة غير صحيحة.';

    }

    if (religionSubjectInput){
        religionSubjectInput.classList.add('goodBorder');
    }

    return true;
};