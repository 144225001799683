<template>
  <div>
    <LoadingSpinner :is-loading="isLoading" />

    <span class="countdown-text" v-if="timeRemaining > 0">
      {{ formattedTime }}
    </span>
    <span class="countdown-text"  v-if="timeRemaining === 0">
      لم يصلني الكود <a class="resend-link" @click="resendOTP">إرسال مرة أخرى</a>
    </span>
  </div>
</template>

<script>
import {requestOTP} from "@/graphql/mutations/requestOTP/handlingCalls";
import {otpCookie} from "@/helpers/OTPCookie";

export default {
  name: 'CountdownResend',
  props: {
    countdownDuration: {
      type: Number,
      default: 600
    },
    phoneNumber: {
      type: String,
      required: true,
      default: ''
    },
    sentOTP: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      timeRemaining: this.countdownDuration,
      isLoading: false,
    };
  },

  computed: {
    formattedTime() {
      const minutes = Math.floor(this.timeRemaining / 60);
      const seconds = this.timeRemaining % 60;
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      if (this.timeRemaining > 0) {
        this.countdownInterval = setInterval(() => {
          if (this.timeRemaining > 0) {
            this.timeRemaining -= 1;
          } else {
            clearInterval(this.countdownInterval);
          }
        }, 1000);
      }
    },
    resendOTP() {
      this.$emit('resend');
      this.timeRemaining = this.countdownDuration;
      this.handleResendOTP();

      this.startCountdown();
    },
    async handleResendOTP(){
      const response = await this.$withLoading(() => requestOTP(this.phoneNumber));
      
      if (response.success === false){
        this.localSentOTP = '';
      }else{
        this.localSentOTP = response.data.otp;
      }

      otpCookie.setOTP(this.$cookies , this.phoneNumber , {
        'isValidSentOTP' : false,
        'sentOTP': this.localSentOTP
      });
    },
  },
  beforeUnmount() {
    clearInterval(this.countdownInterval);
  }
};
</script>

<style scoped>
.countdown-text {
  @apply text-black opacity-70 text-[14px];
}
.resend-link {
  @apply text-blue-custom font-medium underline cursor-pointer;
}
</style>
