import { gql } from '@apollo/client/core';

export const REMOVE_COUPON = gql`
    mutation RemoveCoupon {
        removeCoupon{
            product_id
            price
            total
            is_valid_to_add_coupon
            coupon {
                code
                amount
                type
            }
        }

    }
`;