<template>
  <div class="mt-[40px]">
    <h6 class="dynamic-align font-bold-700 flex items-start">{{ title }}</h6>
    <div class="flex gap-4 cursor-pointer text-blue-custom dynamic-align">
      <span v-for="(link, index) in item.links" :key="index" class="pt-[2%]">
        <router-link v-if="isInternalLink(link.url)" :to="link.url">{{ link.text }}</router-link>
        <a v-else :href="link.url" target="_blank">{{ link.text }}</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterLinksSection",
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isInternalLink(url) {
      return url.startsWith("/");
    },
  },
};
</script>

<style scoped>
</style>