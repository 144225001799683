<template>
  <div :dir="dir" class="flex flex-col justify-start bg-white mt-4 cursor-pointer w-full rounded-content-radius"
    @click="handleClick(lesson)">
    <div class="flex flex-row justify-between items-center w-full py-2 px-4 sm:px-4 rounded-content-radius"
      :class="{ 'blue-custom-border text-blue-custom': lesson.is_completed, 'gray-custom-border text-black-custom': !lesson.is_completed }">
      <div class="flex items-center px-2 gap-5" :class="{ 'text-blue': isEnrolled }">
        <img :src="currentIcon" class="mx-2" />
        <div class="dynamic-align">
          <span class="text-13px md:text-17px font-bold-500">
            {{ lesson.name }}
          </span>
          <div class="flex  text-gray-custom gap-2 sm:gap-4 mt-2 text-nowrap text-xs md:text-15px">
            <div class="flex items-center gap-2 align-center" v-if="isVideo && lesson?.duration_in_minutes">
              <ClockIcon :width="isMobile ? 15 : 20" :height="isMobile ? 15 : 20" />
              <span>{{ lesson.duration_in_minutes }} {{ minutesText }}</span>
            </div>
            <div class="flex items-center gap-2 align-center" v-if="isExercise && lesson?.exercise_time">
              <ClockIcon :width="isMobile ? 15 : 20" :height="isMobile ? 15 : 20" />
              <span>{{ lesson.exercise_time }} {{ minutesText }}</span>
            </div>
            <div class="flex items-center gap-2 align-center" v-if="isExercise && lesson?.number_of_questions">
              <svgicon :data="PenIcon" :width="isMobile ? 15 : 20" :height="isMobile ? 15 : 20" original />
              <span>{{ lesson.number_of_questions }} {{ questionsText }}</span>
            </div>
            <div class="flex items-center gap-2 align-center" v-if="isPaperSummary && lesson?.number_of_papers">
              <svgicon :data="PaperIcon" :width="isMobile ? 15 : 20" :height="isMobile ? 15 : 20" original />
              <span>{{ lesson.number_of_papers }} {{ papersText }}</span>
            </div>
          </div>
        </div>
      </div>
      <CheckMarkIcon :isFinished="lesson.is_completed" class="px-2" />
    </div>
  </div>

  <Blur ref="prerequisites">
    <Prerequisites :prerequisites="lesson.incomplete_prerequisites" :dir="dir" :title="'محتاج أولا تخلص:'"
      :closeBlur="closePrerequisites" />
  </Blur>

  <Blur ref="NotEnrolled">
    <div class="blur-content">
      <div class="relative responsive-container">
        <div class="image-container p-4 bg-gray-50 rounded-full border shadow-lg">
          <img loading="lazy" :src="Wallet" class="aspect-square w-auto" />
        </div>
        <NotEnrolledData :dir="dir" :goTo="goToSubscribe" title="عفوا انت غير مشترك" subtitle="معرفة تفاصيل الاشتراك" />
        <button @click="closeBlur"
          class="w-full block text-center mt-4 text-13px md:text-base font-bold-500 text-black-custom md:text-gray-custom">
          إغلاق
        </button>
      </div>
    </div>
  </Blur>
</template>

<script>
import Blur from "@/components/General/Blur.vue";
import CheckMarkIcon from "@/components/General/CheckMarkIcon.vue";
import ClockIcon from "@/components/Icons/ClockIcon.vue";
import PenIcon from "@/assets/svg/pen-icon.svg";
import PaperIcon from "@/assets/svg/Paper-icon.svg";
import BookIcon from "@/assets/images/icons/book.png";
import VideoIcon from "@/assets/images/icons/video.png";
import NotebookIcon from "@/assets/images/icons/exercise.png";
import Clock from "@/assets/images/icons/clock.png";
import Wallet from "@/assets/icons/wallet.svg";
import Prerequisites from "@/components/Lesson/Prerequisites.vue";
import NotEnrolledData from "@/components/Lesson/NotEnrolledData.vue";
import routes from "@/router/routes";
import routeParamsMixin from "@/mixins/routeParams";
import windowSizeMixin from "@/mixins/windowSize";

export default {
  mixins: [routeParamsMixin, windowSizeMixin],

  name: "CourseContent",
  components: {
    Blur,
    CheckMarkIcon,
    ClockIcon,
    Prerequisites,
    NotEnrolledData,
  },
  props: {
    lesson: {
      type: Object,
      required: true,
    },
    dir: {
      type: String,
      default: "LTR",
    },
    isEnrolled: {
      type: Boolean,
      default: false,
    },
    ContentType: {
      type: String,
    },
  },
  data() {
    return {
      PenIcon,
      PaperIcon,
      Wallet,
      icons: {
        Exercise: BookIcon,
        Video: VideoIcon,
        PaperSummary: NotebookIcon,
        Test: Clock,
      },
    };
  },
  methods: {
    handleClick(lesson) {
      if (!this.isEnrolled) {
        this.$refs.NotEnrolled.showBlur();
      } else if (lesson.incomplete_prerequisites?.length > 0) {
        this.$refs.prerequisites.showBlur();
      } else {
        this.navigateToLesson(lesson);
      }
    },
    navigateToLesson(lesson) {
      const navigateToContentType = {
        Video: (lessonSlug, videoSlug) =>
          routes.navigateToVideo(lessonSlug, videoSlug),
        Exercise: (lessonSlug, exerciseSlug) =>
          routes.navigateToExercise(lessonSlug, exerciseSlug),
        PaperSummary: (lessonSlug, paperSummarySlug) =>
          routes.navigateToPaperSummary(lessonSlug, paperSummarySlug),
        videos: (lessonSlug, videoSlug) =>
          routes.navigateToVideo(lessonSlug, videoSlug),
        exercises: (lessonSlug, exerciseSlug) =>
          routes.navigateToExercise(lessonSlug, exerciseSlug),
        paperSummaries: (lessonSlug, paperSummarySlug) =>
          routes.navigateToPaperSummary(lessonSlug, paperSummarySlug),
      };

      const contentType = lesson?.content_type || this.ContentType;
      const navigate = navigateToContentType[contentType];

      if (navigate) {
        navigate(this.lessonSlug, lesson?.slug);
      }
    },
    goToSubscribe() {
      this.$router.push({ name: "Subscription" });
    },
    closePrerequisites() {
      this.$refs.prerequisites.hideBlur();
    },
    closeBlur() {
      this.$refs.NotEnrolled.hideBlur();
    },
    getContentTypeIcon() {
      if (
        this.lesson.content_type === "Exercise" ||
        this.ContentType === "exercises"
      ) {
        return this.lesson.is_exam
          ? this.icons.Test || null
          : this.icons.Exercise || null;
      }
      return this.icons[this.lesson.content_type] || null;
    },
  },
  computed: {
    currentIcon() {
      if (this.ContentType === "ALL") {
        return this.getContentTypeIcon();
      } else {
        switch (this.ContentType) {
          case "videos":
            return this.icons.Video || null;
          case "paperSummaries":
            return this.icons.PaperSummary || null;
          case "exercises":
            return this.getContentTypeIcon();
          default:
            return null;
        }
      }
    },
    contentTypeComputed() {
      return this.lesson?.content_type || this.ContentType;
    },
    isVideo() {
      return ["Video", "videos"].includes(this.contentTypeComputed);
    },
    isExercise() {
      return ["Exercise", "exercises"].includes(this.contentTypeComputed);
    },
    isPaperSummary() {
      return ["PaperSummary", "paperSummaries"].includes(
        this.contentTypeComputed
      );
    },
    minutesText() {
      return this.dir === "RTL" ? "دقيقة" : "minutes";
    },
    questionsText() {
      return this.dir === "RTL" ? "سؤال" : "question";
    },
    papersText() {
      return this.dir === "RTL" ? "ورقة" : "papers";
    },
  },
};
</script>

<style scoped>
img {
  width: 40px;
  height: 40px;
}

.green {
  background-color: #10cb66;
}

.text-blue {
  color: #1c9deb;
}

.bg-blue {
  background-color: #1c9deb;
}

.gray {
  color: #eaeaf3;
}

.responsive-container {
  padding: 30px 0px;
}

@media screen and (max-width: 767px) {
  .responsive-container {
    padding: 10px 0px;
  }

  img {
    width: 35px;
    height: 35px;
  }
}
</style>
