import { gql } from '@apollo/client/core';

export const APPLY_COUPON = gql`
    mutation ApplyCoupon(
        $coupon_code: String!
    ) {
        applyCoupon(input: {
            coupon_code: $coupon_code
        }){
            product_id
            price
            total
            is_valid_to_add_coupon
            coupon {
                code
                amount
                type
            }
        }

    }
`;
