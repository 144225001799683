<template>
    <i
      :class="[
        'fa-solid',
        `fa-angle-${getIconDirection}`,
        `text-${color}`
      ]"
    ></i>
  </template>
  
  <script>
  export default {
    props: {
      color: {
        type: String,
        required: true,
      },
      dir: {
        type: String,
        required: true,
      },
      isBack: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      isRTL() {
        return this.dir === 'RTL';
      },
      getIconDirection() {
          return this.isBack ? 'left' : 'right';
      },
    },
  };
  </script>
  