<template>
    <div class="w-[284px] flex justify-between md:justify-between items-center mt-4 mb-2">
      <span class=" text-[16px] text-gray-custom cursor-pointer flex items-start ">المبلغ</span>
      <span class="text-gray-custom text-[16px]">{{ price }} جنيه</span>   
    </div>
</template>
    
<script>
  export default {
    name: 'FawryPrice',
    props:{
      price: {
        type: [String , Number],
      }
    },
  }
</script>