import {useMutation} from "@vue/apollo-composable";
import {handleResponse} from "@/helpers/graphqlResponseHandler";
import {MARK_VIDEO_AS_COMPLETED} from "@/graphql/mutations/markVideoAsCompleted/markVideoAsCompleted";

export const markVideoAsCompleted = (async (videoId) => {

    const { mutate } = useMutation(MARK_VIDEO_AS_COMPLETED, {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: {
            id: videoId
        },
    });

    try {
        const response = await mutate();

        return handleResponse(response , 'markVideoCompleted');



    } catch (error) {
        return {
            success: false,
            message: error.message || "لقد حدث خطأ ما يرجى المحاولة مرة اخرى.",
        };
    }

})