import { gql } from "@apollo/client/core";

const courseContentFragment = gql`
  fragment CourseContentFields on CourseContent {
    id
    active_term
    free_sample
    label
    name
    schedule_at
    schedule_at_english
    schedule_at_arabic
    slug
    visibility
    content_type
    is_completed
  }
`;

export const COURSE_CONTENT = gql`
  query GetCourseContent($slug: String!) {
    courseContent(input: { slug: $slug }) {
      name
      course_direction
      progress
      promotion_button{
        url
        name
      }
      teacher {
        id
        name
      }
      courseTabs {
        default_active_term
        ALL {
          ...CourseContentFields
        }
        FIRST_TERM {
          ...CourseContentFields
        }
        SECOND_TERM {
          ...CourseContentFields
        }
      }
    }
  }
  ${courseContentFragment}
`;
