<template>
<div class="text-container text-base md:font-bold">
{{ title }}
</div>
<button class="bg-blue hover:bg-blue text-white font-bold py-1 px-4 rounded-full mt-5" @click="goTo" >
    <div class="flex flex-row gap-2 items-center">
        <BackBtn :dir="dir" />
        <span class="text-sm md:text-base font-bold">
        {{ subtitle }}
       </span>
    </div>
</button>
</template>

<script>
import BackBtn from "@/components/Icons/BackBtn.vue";


export default {
    name: 'NotEnrolledData',
    props: {
        dir: {
            type: String,
            default: "LTR",
        },
        title: {
            type: String,
            default: "عفوا انت غير مشترك فى هذا المحتوى",
        },
        subtitle: {
            type: String,
            default: "معرفة تفصايل الاشتراك",
        },
        goTo: {
            type: Function
        }
    },
    components: {
        BackBtn
    }
}
</script>

<style scoped>
.text-container{
    margin:50px 0 10px;
}

@media (max-width: 768px) {
    .text-container{
        margin-top:50px;
    }
}
</style>