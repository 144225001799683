<template>
<div class="flex flex-row justify-between items-center align-top">
    <div class="flex flex-row items-center align-top" :class="gap">
        <XIcon :width="isMobile ? '24' : '37'" :height="isMobile ? '24' : '37'" :backPage="'lesson'" 
        @x-icon-clicked="handleXIconClicked" :isWhite="isWhite" />
        <h1 :class="textColorClass" class="text-12px md:text-17px ">
            {{ exerciseTitle }}
        </h1>
    </div>
    <div class="flex flex-row gap-2 items-center">
        <slot></slot>
    </div>
</div>
</template>

<script>
import XIcon from "@/components/Icons/XBackIcon.vue";
import windowSize from "@/mixins/windowSize";

export default {
    mixins: [windowSize],
    name: "ExerciseHeader",
    components: {
        XIcon,
    },
    props: {
        started: Boolean,
        textColor: {
            type: String,
            default: "blue-custom",
        },
        exerciseTitle: {
            type: String,
            default: "Exercise Title",
        },
        isWhite: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: "37px",
        },
        height: {
            type: String,
            default: "37px",
        },
        gap: {
            type: String,
            default: "gap-3",
        }
    },
    computed: {
        textColorClass() {
            return `text-${this.textColor}`;
        },
    },
    methods: {
        handleXIconClicked() {
            this.$emit("x-icon-clicked");
        },
    },
};
</script>
