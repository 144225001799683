import {gql} from '@apollo/client/core';

export const CHECKOUT = gql`
    mutation Checkout(
        $payment_method_id: Int!
        $user_phone_number: String
        $user_email: String
    ) {
        checkout(input: {
            payment_method_id: $payment_method_id
            user_phone_number: $user_phone_number
            user_email: $user_email
        }){
            redirect_url
            reference_number
            submitted_phone_number
            valid_until
        }

    }
`;

