import { gql } from '@apollo/client/core';


export const GET_EDUCATION_CLASSES = gql`
    query GetEducationClasses($education_stage_id: ID!) {
        educationClasses(input: {
            education_stage_id: $education_stage_id
        }) {
            id
            name
            educationSections {
                id
                name
            }
        }
    }
`;

