export const isValidPassword = (value) => {
    const passwordInput = document.getElementById('password');
    const trimmedValue = value && value.trim();

    const updateInputClass = (input, addClass, removeClass) => {
        if (input) {
            input.classList.remove(removeClass);
            input.classList.add(addClass);
        }
    };

    if (!trimmedValue) {
        updateInputClass(passwordInput, 'badBorder', 'goodBorder');
        return 'الرجاء إدخال الباسوورد*';
    }

    if (trimmedValue.length < 6) {
        updateInputClass(passwordInput, 'badBorder', 'goodBorder');
        return 'الباسوورد لازم تكون من 6 خانات على الأقل*';
    }

    updateInputClass(passwordInput, 'goodBorder', 'badBorder');
    return true;
};
